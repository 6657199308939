/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import Reports from "layouts/pages/users/reports";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Security from "layouts/pages/account/security";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import NewProject from "layouts/pages/projects/new-project";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import SweetAlerts from "layouts/pages/sweet-alerts";
import Notifications from "layouts/pages/notifications";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import Analytics from "layouts/applications/analytics";
import Overview from "layouts/ecommerce/overview";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductsList from "layouts/ecommerce/products/products-list";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import Referral from "layouts/ecommerce/referral";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import ResetBasic from "layouts/authentication/reset-password/basic";
import ResetCover from "layouts/authentication/reset-password/cover";
import ResetIllustration from "layouts/authentication/reset-password/illustration";
import LockBasic from "layouts/authentication/lock/basic";
import LockCover from "layouts/authentication/lock/cover";
import LockIllustration from "layouts/authentication/lock/illustration";
import VerificationBasic from "layouts/authentication/2-step-verification/basic";
import VerificationCover from "layouts/authentication/2-step-verification/cover";
import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import SettingsIcon from "examples/Icons/Settings";
import Basket from "examples/Icons/Basket";
import Document from "examples/Icons/Document";
import Home from "./app/pages/Home";
import KeywordTool from "./app/pages/KeywordTool";
import KeywordMarketshare from "./app/pages/KeywordMarketshare";
import KeywordResearchTool from "./layouts/research/keywords";
import Radar from "./examples/Icons/Radar";
import KeywordRadarTool from "./layouts/radar/keywords";
import ProductRadarTool from "./layouts/radar/products";
import SellerRadarTool from "./layouts/radar/sellers";
import ProductResearchTool from "./layouts/research/products";
import SellerResearchTool from "./layouts/research/sellers";
import search from "./assets/images/app/analytics.png";
import scan from "./assets/images/app/scanning.png";
import keywordResearch from "./assets/images/app/keyword-research.png";
import productResearch from "./assets/images/app/product-research.png";
import sellerResearch from "./assets/images/app/seller-research.png";

import ScraperWorkerStatus from "./layouts/admin/scraper-worker/scraper-worker-status";
import HomeDashboard from "./layouts/home";
import UserProfile from "./layouts/user/profile";
import SubscriptionPage from "./layouts/user/subscription";
import colors from "./assets/theme/base/colors";
import KeywordResearch from "./assets/Icons/KeywordResearch";
import ProductResearch from "./assets/Icons/ProductResearch";
import SellerResearch from "./assets/Icons/SellerResearch";
import Explorer from "./layouts/explorer";
import AnalyticsIcon from "./assets/Icons/Analytics";
import ExplorerIcon from "./assets/Icons/ExplorerIcon";
import HomeIcon from "./assets/Icons/HomeIcon";
import SalesDashboard from "./layouts/admin/sales";
import KeywordLikes from "./layouts/user/likes/keywords";
import ProductLikes from "./layouts/user/likes/products";
import SellerLikes from "./layouts/user/likes/sellers";
import HomeV2Dashboard from "./layouts/home2";

const routes = [

    {
        route: "/dashboard",
        type: "collapse",
        role: "ROLE_USER",
        name: "Dashboard",
        key: "dashboard",
        noCollapse: true,
        icon: <HomeIcon size="25px"/>,
        component: <HomeV2Dashboard />,
    },

    {
        route: "/explorer",
        type: "collapse",
        role: "ROLE_USER",
        name: "Explorer",
        key: "explorer",
        noCollapse: true,
        icon: <ExplorerIcon size="30px"/>,
        component: <Explorer />,
    },

    { type: "title", title: "Tools", key: "research-tools", role: "ROLE_USER" },

    {
        route: "/research-keywords",
        type: "collapse",
        role: "ROLE_USER",
        name: "Keyword Research",
        key: "research-keywords",
        noCollapse: true,
        icon: <KeywordResearch size="20px"/>,
        component: <KeywordResearchTool />,
    },

    {
        route: "/research-products",
        type: "collapse",
        role: "ROLE_USER",
        name: "Product Research",
        key: "research-products",
        noCollapse: true,
        icon: <ProductResearch size="30px"/>,
        component: <ProductResearchTool />,
    },

    {
        route: "/research-sellers",
        type: "collapse",
        role: "ROLE_USER",
        name: "Seller Research",
        key: "research-sellers",
        noCollapse: true,
        icon: <SellerResearch size="30px"/>,
        component: <SellerResearchTool />,
    },

    //
    // {
    //     role: "ROLE_USER",
    //     type: "collapse",
    //     name: "Research Tools",
    //     key: "research",
    //     icon:  <img src={search} alt={'icon'} style={{width: '20px'}}/>,
    //     collapse: [
    //         {
    //             name: "Keywords Research",
    //             key: "keywords",
    //             route: "/research/keywords",
    //             component: <KeywordResearchTool />,
    //             role: "ROLE_BASIC",
    //         },
    //         {
    //             name: "Products Research",
    //             key: "products",
    //             route: "/research/products",
    //             component: <ProductResearchTool />,
    //             role: "ROLE_BASIC",
    //         },
    //         {
    //             name: "Sellers Research",
    //             key: "sellers",
    //             route: "/research/sellers",
    //             component: <SellerResearchTool />,
    //             role: "ROLE_BASIC",
    //         },
    //     ]
    // },

    { type: "title", title: "Deep Dive", key: "analytics", role: "ROLE_USER" },
    {
        type: "collapse",
        name: "Analytics",
        key: "analytics",
        role: "ROLE_USER",
        icon: <AnalyticsIcon size={'20px'}/>,
        collapse: [
            {
                name: "Keyword Analytics View",
                key: "keywords",
                route: "/analytics/keywords",
                component: <KeywordRadarTool />,
                role: "ROLE_USER",
            },
            {
                name: "Product Analytics View",
                key: "products",
                route: "/analytics/products",
                component: <ProductRadarTool />,
                role: "ROLE_USER",
            },
            {
                name: "Seller Analytics View",
                key: "sellers",
                route: "/analytics/sellers",
                component: <SellerRadarTool />,
                role: "ROLE_USER",
            },
        ]
    },

    { type: "title", title: "User & Settings", key: "user-settings", role: "ROLE_USER" },
    {
        route: "/user-settings",
        type: "collapse",
        role: "ROLE_USER",
        name: "User Settings",
        key: "user-settings",
        noCollapse: true,
        icon: <Office size="12px"/>,
        component: <UserProfile />,
    },

    {
        type: "collapse",
        name: "Likes",
        key: "likes",
        icon: <Office size="12px"/>,
        role: "ROLE_USER",
        collapse: [
            {
                name: "Keywords",
                key: "keywords",
                route: "/likes/keywords",
                component: <KeywordLikes />,
                role: "ROLE_USER",
            },
            {
                name: "Products",
                key: "products",
                route: "/likes/products",
                component: <ProductLikes />,
                role: "ROLE_USER",
            },
            {
                name: "Sellers",
                key: "sellers",
                route: "/likes/sellers",
                component: <SellerLikes />,
                role: "ROLE_USER",
            },
        ]
    },

    // { type: "title", title: "Admin Area", key: "admin", role: "ROLE_ADMIN" },
    // {
    //     type: "collapse",
    //     name: "Admin Panel",
    //     key: "admin",
    //     role: "ROLE_ADMIN",
    //     icon: <Office size="12px"/>,
    //     collapse: [
    //         {
    //             name: "Scraper Workers",
    //             key: "scraper-workers",
    //             route: "/admin/scraper-workers",
    //             component: <ScraperWorkerStatus />,
    //             role: "ROLE_ADMIN",
    //         },
    //         {
    //             name: "Sales Dashboard",
    //             key: "sales-dashboard",
    //             route: "/admin/sales-dashboard",
    //             component: <SalesDashboard />,
    //             role: "ROLE_ADMIN",
    //         },
    //     ]
    // },


    { type: "title", title: "Your Treasure Box", key: "treasure-box" },
    {
        type: "collapse",
        name: "Saved Items",
        key: "saved-items",
        icon: <Office size="12px"/>,
        collapse: [
            {
                name: "Keywords",
                key: "keywords-research",
                route: "/pages/profile/profile-overview",
                component: <ProfileOverview />,
            },
            {
                name: "Products",
                key: "products-research",
                route: "/pages/profile/profile-overview",
                component: <ProfileOverview />,
            },
            {
                name: "Sellers",
                key: "sellers-research",
                route: "/pages/profile/profile-overview",
                component: <ProfileOverview />,
            },
            {
                name: "Categories",
                key: "categories-research",
                route: "/pages/profile/profile-overview",
                component: <ProfileOverview />,
            },
            {
                name: "Niches",
                key: "niches-research",
                route: "/pages/profile/profile-overview",
                component: <ProfileOverview />,
            },
        ]
    },




    { type: "title", title: "AI Tools", key: "ai" },
    {
        type: "collapse",
        name: "AI Tools",
        key: "ai",
        icon: <Radar size="24px"/>,
        collapse: [
            {
                name: "AI Listing Creator",
                key: "ai-listing-creator",
                route: "/ai/listing-creator",
                component: <ProfileOverview />,
            },
            {
                name: "AI Listing Optimizer",
                key: "ai-listing-optimizer",
                route: "/ai/listing-optimizer",
                component: <ProfileOverview />,
            },
        ]
    },

  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        collapse: [
          {
            name: "Profile Overview",
            key: "profile-overview",
            route: "/pages/profile/profile-overview",
            component: <ProfileOverview />,
          },
          {
            name: "Teams",
            key: "teams",
            route: "/pages/profile/teams",
            component: <Teams />,
          },
          {
            name: "All Projects",
            key: "all-projects",
            route: "/pages/profile/all-projects",
            component: <AllProjects />,
          },
        ],
      },
      {
        name: "Users",
        key: "users",
        collapse: [
          {
            name: "Reports",
            key: "reports",
            route: "/pages/users/reports",
            component: <Reports />,
          },
          {
            name: "New User",
            key: "new-user",
            route: "/pages/users/new-user",
            component: <NewUser />,
          },
        ],
      },
      {
        name: "Account",
        key: "account",
        collapse: [
          {
            name: "Settings",
            key: "settings",
            route: "/pages/account/settings",
            component: <Settings />,
          },
          {
            name: "Billing",
            key: "billing",
            route: "/pages/account/billing",
            component: <Billing />,
          },
          {
            name: "Invoice",
            key: "invoice",
            route: "/pages/account/invoice",
            component: <Invoice />,
          },
          {
            name: "Security",
            key: "security",
            route: "/pages/account/security",
            component: <Security />,
          },
        ],
      },
      {
        name: "Projects",
        key: "projects",
        collapse: [
          {
            name: "General",
            key: "general",
            route: "/pages/projects/general",
            component: <General />,
          },
          {
            name: "Timeline",
            key: "timeline",
            route: "/pages/projects/timeline",
            component: <Timeline />,
          },
          {
            name: "New Project",
            key: "new-project",
            route: "/pages/projects/new-project",
            component: <NewProject />,
          },
        ],
      },
      {
        name: "Pricing Page",
        key: "pricing-page",
        route: "/pages/pricing-page",
        component: <PricingPage />,
      },
      { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
      { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
      { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
      {
        name: "Sweet Alerts",
        key: "sweet-alerts",
        route: "/pages/sweet-alerts",
        component: <SweetAlerts />,
      },
      {
        name: "Notfications",
        key: "notifications",
        route: "/pages/notifications",
        component: <Notifications />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <SettingsIcon size="12px" />,
    collapse: [
      {
        name: "Wizard",
        key: "wizard",
        route: "/applications/wizard",
        component: <Wizard />,
      },
      {
        name: "Data Tables",
        key: "data-tables",
        route: "/applications/data-tables",
        component: <DataTables />,
      },
      {
        name: "Calendar",
        key: "calendar",
        route: "/applications/calendar",
        component: <Calendar />,
      },
      {
        name: "Analytics",
        key: "analytics",
        route: "/applications/analytics",
        component: <Analytics />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    icon: <Basket size="12px" />,
    collapse: [
      {
        name: "Overview",
        key: "overview",
        route: "/ecommerce/overview",
        component: <Overview />,
      },
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "New Product",
            key: "new-product",
            route: "/ecommerce/products/new-product",
            component: <NewProduct />,
          },
          {
            name: "Edit Product",
            key: "edit-product",
            route: "/ecommerce/products/edit-product",
            component: <EditProduct />,
          },
          {
            name: "Products List",
            key: "products-list",
            route: "/ecommerce/products/products-list",
            component: <ProductsList />,
          },
        ],
      },
      {
        name: "Orders",
        key: "orders",
        collapse: [
          {
            name: "Order List",
            key: "order-list",
            route: "/ecommerce/orders/order-list",
            component: <OrderList />,
          },
          {
            name: "Order Details",
            key: "order-details",
            route: "/ecommerce/orders/order-details",
            component: <OrderDetails />,
          },
        ],
      },
      {
        name: "Referral",
        key: "referral",
        route: "/ecommerce/referral",
        component: <Referral />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in/cover",
            component: <SignInCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in/illustration",
            component: <SignInIllustration />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-up/basic",
            component: <SignUpBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up/cover",
            component: <SignUpCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-up/illustration",
            component: <SignUpIllustration />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/reset-password/basic",
            component: <ResetBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/reset-password/illustration",
            component: <ResetIllustration />,
          },
        ],
      },
      {
        name: "Lock",
        key: "lock",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/lock/basic",
            component: <LockBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/lock/cover",
            component: <LockCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/lock/illustration",
            component: <LockIllustration />,
          },
        ],
      },
      {
        name: "2-Step Verification",
        key: "2-step-verification",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/verification/basic",
            component: <VerificationBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/verification/cover",
            component: <VerificationCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/verification/illustration",
            component: <VerificationIllustration />,
          },
        ],
      },
      {
        name: "Error",
        key: "error",
        collapse: [
          {
            name: "Error 404",
            key: "error-404",
            route: "/authentication/error/404",
            component: <Error404 />,
          },
          {
            name: "Error 500",
            key: "error-500",
            route: "/authentication/error/500",
            component: <Error500 />,
          },
        ],
      },
    ],
  },

    { type: "divider", key: "divider-1", role: "ROLE_USER" },

    // TODO:: ENABLE LATER
    // {
    //     route: "/subscription",
    //     type: "collapse",
    //     role: "ROLE_USER",
    //     name: "Buy Plan",
    //     key: "subscription",
    //     noCollapse: true,
    //     icon: <Office size="12px"/>,
    //     component: <SubscriptionPage />,
    // },


  { type: "divider", key: "divider-1" },
];

export default routes;
