import {useEffect, useState} from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftTagInput from "components/SoftTagInput";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import PropTypes from "prop-types";
import SoftButton from "../../../components/SoftButton";
import Icon from "@mui/material/Icon";
import waves from "../../../assets/images/shapes/waves-white.svg";


function SellerForm({formHandler, submitHandler, formData}) {
    const [joinedSinceFrom, setJoinedSinceFrom] = useState(null);
    const [joinedSinceTo, setJoinedSinceTo] = useState(null);

    const handleSelectChange = (event, name) => {
        event = {target: {name: name, value: event.value}};
        formHandler(event);
    }

    useEffect(() => {
        if (formData.joinedSinceFrom) {
            setJoinedSinceFrom({
                label: formData.joinedSinceFrom,
                value: formData.joinedSinceFrom
            });
        } else {
            setJoinedSinceFrom(null)
        }
    }, [formData.joinedSinceFrom]);

    useEffect(() => {
        if (formData.joinedSinceTo) {
            setJoinedSinceTo({
                label: formData.joinedSinceTo,
                value: formData.joinedSinceTo
            });
        } else {
            setJoinedSinceTo(null)
        }
    }, [formData.joinedSinceTo]);

    return (
        <Card id="basic-info"
              sx={{
                  backdropFilter: `saturate(200%) blur(30px)`,
                  backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                  boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                  position: "relative",
                  mt: -15,
                  mx: 3,
                  py: 2,
                  px: 2,
                  overflow: "visible"
              }}
        >

            <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
                <Grid item xs={10} lg={5}>
                    <SoftBox mb={1}>
                        <SoftTypography variant="h3" color="black" fontWeight="bold">
                            Sellers Research
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={2}>
                        <SoftTypography variant="body2" color="dark" fontWeight="regular">
                            Find sellers on Etsy that match your criteria
                        </SoftTypography>
                    </SoftBox>
                </Grid>
            </Grid>

            <SoftBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} flexDirection={'row'}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={3} >
                                <SoftTypography fontSize={'23px'} fontWeight={"bold"} color={'dark'}>Sales</SoftTypography>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Total Sales</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <FormField name="totalSalesFrom" placeholder={'Min'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.totalSalesFrom} />
                                            <FormField name="totalSalesTo" placeholder={'Max'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.totalSalesTo} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Monthly Sales</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <FormField name="monthlySalesFrom" placeholder={'Min'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.monthlySalesFrom} />
                                            <FormField name="monthlySalesTo" placeholder={'Max'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.monthlySalesTo} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid item xs={12} sm={1} >
                            </Grid>


                            <Grid item xs={12} sm={3} >
                                <SoftTypography fontSize={'23px'} fontWeight={"bold"} color={'dark'}>Reviews</SoftTypography>



                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Total Reviews</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <FormField name="totalReviewsFrom" placeholder={'Min'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.totalReviewsFrom} />
                                            <FormField name="totalReviewsTo" placeholder={'Max'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.totalReviewsTo} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Monthly Reviews</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <FormField name="monthlyReviewsFrom" placeholder={'Min'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.monthlyReviewsFrom} />
                                            <FormField name="monthlyReviewsTo" placeholder={'Max'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.monthlyReviewsTo} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Admirers</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <FormField name="admirersFrom" placeholder={'Min'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.admirersFrom} />
                                            <FormField name="admirersTo" placeholder={'Max'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.admirersTo} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>


                            </Grid>

                            <Grid item xs={12} sm={1} >
                            </Grid>

                            <Grid item xs={12} sm={3} >
                                <SoftTypography fontSize={'23px'} fontWeight={"bold"} color={'dark'}>Seller Details</SoftTypography>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Joined Since</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <SoftSelect
                                                value={joinedSinceFrom}
                                                onChange={(e) => handleSelectChange(e, 'joinedSinceFrom')}
                                                placeholder="Min"
                                                options={
                                                    [
                                                        { value: '', label: '-'}, // option to clear selection
                                                        { value: '2015', label: '2015' },
                                                        { value: '2016', label: '2016' },
                                                        { value: '2017', label: '2017' },
                                                        { value: '2018', label: '2018' },
                                                        { value: '2019', label: '2019' },
                                                        { value: '2020', label: '2020' },
                                                        { value: '2021', label: '2021' },
                                                        { value: '2022', label: '2022' },
                                                        { value: '2023', label: '2023' },
                                                    ]
                                                }
                                            />

                                            <SoftSelect
                                                value={joinedSinceTo}
                                                onChange={(e) => handleSelectChange(e, 'joinedSinceTo')}
                                                placeholder="Max"
                                                options={
                                                    [
                                                        { value: '', label: '-'}, // option to clear selection
                                                        { value: '2015', label: '2015' },
                                                        { value: '2016', label: '2016' },
                                                        { value: '2017', label: '2017' },
                                                        { value: '2018', label: '2018' },
                                                        { value: '2019', label: '2019' },
                                                        { value: '2020', label: '2020' },
                                                        { value: '2021', label: '2021' },
                                                        { value: '2022', label: '2022' },
                                                        { value: '2023', label: '2023' },
                                                    ]
                                                }
                                            />
                                            {/*<FormField name="joinedSinceFrom" placeholder={'Min'}*/}
                                            {/*           inputProps={{ type: "number" }}*/}
                                            {/*           onChange={formHandler}*/}
                                            {/*           value={formData.joinedSinceFrom} />*/}
                                            {/*<FormField name="joinedSinceTo" placeholder={'Max'}*/}
                                            {/*           inputProps={{ type: "number" }}*/}
                                            {/*           onChange={formHandler}*/}
                                            {/*           value={formData.joinedSinceTo} />*/}
                                        </SoftBox>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Location</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                            <FormField name="location" placeholder="Search by location..." onChange={formHandler} value={formData.location} />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Category</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <FormField name="category" placeholder="Search by category..." onChange={formHandler} value={formData.category} />
                                    </Grid>
                                </Grid>

                            </Grid>


                        </Grid>
                    </Grid>

                </Grid>

                <SoftBox mt={3}>
                    <SoftButton
                        variant="gradient"
                        color={'info'}
                        fullWidth
                        // size={'medium'}
                        onClick={submitHandler}
                    >
                        {'Submit'}&nbsp;
                        <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

// Typechecking props for the Header
SellerForm.propTypes = {
    formHandler: PropTypes.func.isRequired,
    submitHandler: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
};

export default SellerForm;
