import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import SoftBox from 'components/SoftBox';
import SoftAvatar from 'components/SoftAvatar';
import SoftTypography from 'components/SoftTypography';
import Grid from '@mui/material/Grid';
import PropTypes from "prop-types";
import MiniStatisticsCard from "../../examples/Cards/StatisticsCards/MiniStatisticsCard";

import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import DataTableHeadCell from "../radar/products/DataTable/DataTableHeadCell";
import Table from "@mui/material/Table";
import Divider from "@mui/material/Divider";
import ActionButtonsExplorer from "../../components/Etsellify/ActionButtonsExplorer";


const ProductCard = ({ product, liked }) => {
    const { light } = colors;
    const { size } = typography;
    const { borderWidth } = borders;

    return (
        <Card>
        <Grid container spacing={3}>
            <Grid item xs={4} lg={4}>
                <img src={product.product.imageUrl} alt="product-image" style={{'max-width': '300px', 'border-radius': '10px 10px 10px 10px'}} />
            </Grid>

            <Grid item xs={8} lg={8}>
                <SoftTypography mt={3}>
                    {product.product.title}
                </SoftTypography>


                <Table>
                    <SoftBox component="thead"
                             sx={{
                                 display: "table-header-group",
                                 top: 0,
                                 zIndex: 1,
                                 // backgroundColor: '#28c20f',
                             }}
                    >
                        <TableRow>
                            <DataTableHeadCell
                                sorted={false}
                                width={"100px"}
                            >
                                Price
                            </DataTableHeadCell>
                            <DataTableHeadCell
                                sorted={false}
                                width={"100px"}
                            >
                                Monthly Sales
                            </DataTableHeadCell>
                            <DataTableHeadCell
                                sorted={false}
                                width={"100px"}
                            >
                                Monthly Revenue
                            </DataTableHeadCell>
                        </TableRow>

                    </SoftBox>

                    <TableBody>

                        <TableRow>
                            <SoftBox
                                component="td"
                                fontSize={size.md}
                                px={3}
                                borderRight={`${borderWidth[1]} solid ${light.main}`}
                                style={{borderRight: `${borderWidth[1]} solid ${light.main}`}}
                            >
                                <SoftBox
                                    display="inline-block"
                                    width="max-content"
                                    color="black"
                                >
                                    $ {product.product.price}
                                </SoftBox>
                            </SoftBox>
                            <SoftBox
                                component="td"
                                fontSize={size.md}
                                px={3}
                                borderRight={`${borderWidth[1]} solid ${light.main}`}
                                style={{borderRight: `${borderWidth[1]} solid ${light.main}`}}
                            >
                                <SoftBox
                                    display="inline-block"
                                    width="max-content"
                                    color="black"
                                >
                                    {product.product.monthlySales}
                                </SoftBox>
                            </SoftBox>
                            <SoftBox
                                component="td"
                                fontSize={size.md}
                                px={3}
                                borderRight={`${borderWidth[1]} solid ${light.main}`}
                                style={{borderRight: `${borderWidth[1]} solid ${light.main}`}}
                            >
                                <SoftBox
                                    display="inline-block"
                                    width="max-content"
                                    color="black"
                                >
                                    $ {product.product.monthlyRevenue?.toLocaleString()}
                                </SoftBox>
                            </SoftBox>
                        </TableRow>

                    </TableBody>

                </Table>

                {/*    <SoftBox display={'flex'} flexDirection={'row'} mr={3}>*/}

                {/*        <Grid item lg={4}>*/}
                {/*            <MiniStatisticsCard*/}
                {/*                title={{ fontWeight: "medium", text: "Price" }}*/}
                {/*                count={'$ ' + product.product.price.toLocaleString()}*/}
                {/*                icon={{ color: "dark", component: "local_atm" }}*/}
                {/*                direction="left"*/}
                {/*            />*/}
                {/*        </Grid>*/}

                {/*        <Grid item lg={4}>*/}
                {/*            <MiniStatisticsCard*/}
                {/*                title={{ fontWeight: "medium", text: "Monthly Sales" }}*/}
                {/*                count={product.product.monthlySales?.toLocaleString()}*/}
                {/*                icon={{ color: "dark", component: "local_atm" }}*/}
                {/*                direction="left"*/}
                {/*            />*/}
                {/*        </Grid>*/}

                {/*        <Grid item lg={4}>*/}
                {/*            <MiniStatisticsCard*/}
                {/*                title={{ fontWeight: "medium", text: "Monthly Revenue" }}*/}
                {/*                count={'$ ' + (product.product.monthlyRevenue).toLocaleString()}*/}
                {/*                icon={{ color: "dark", component: "local_atm" }}*/}
                {/*                direction="left"*/}
                {/*            />*/}
                {/*        </Grid>*/}

                {/*</SoftBox>*/}


                <Divider/>

                <Table>

                    <SoftBox component="thead"
                             sx={{
                                 display: "table-header-group",
                                 top: 0,
                                 zIndex: 1,
                                 backgroundColor: '#fff',
                             }}
                    >
                            <TableRow>
                                <DataTableHeadCell
                                    sorted={false}
                                    width={"300px"}
                                >
                                    Keyword
                                </DataTableHeadCell>
                                {/*<DataTableHeadCell*/}
                                {/*    sorted={false}*/}
                                {/*    width={"200px"}*/}
                                {/*>*/}
                                {/*    Search Volume*/}
                                {/*</DataTableHeadCell>*/}
                                <DataTableHeadCell
                                    sorted={false}
                                    width={"200px"}
                                >
                                    Total Results
                                </DataTableHeadCell>
                            </TableRow>

                    </SoftBox>

                    <TableBody>

                    {product.keywords.map((keyword, index) => (
                        <TableRow key={index}>
                            <SoftBox
                                component="td"
                                fontSize={size.md}
                                px={3}
                                borderRight={`${borderWidth[1]} solid ${light.main}`}
                                style={{borderRight: `${borderWidth[1]} solid ${light.main}`}}
                            >
                                <SoftBox
                                    display="inline-block"
                                    width="max-content"
                                    color="black"
                                >
                                    {keyword.keyword}
                                </SoftBox>
                            </SoftBox>
                            <SoftBox
                                component="td"
                                fontSize={size.md}
                                px={3}
                                borderRight={`${borderWidth[1]} solid ${light.main}`}
                                style={{borderRight: `${borderWidth[1]} solid ${light.main}`}}
                            >
                                <SoftBox
                                    display="inline-block"
                                    width="max-content"
                                    color="black"
                                >
                                    {keyword.totalResults}
                                </SoftBox>
                            </SoftBox>
                        </TableRow>
                    ))}

                </TableBody>

                </Table>



            </Grid>
        </Grid>

            <ActionButtonsExplorer
                href={'/analytics/products?listingId=' + product.product.listingId}
                id={product.product.id}
                liked={liked}
                type={'product'}
                identifier={product.product.listingId}
            />
        </Card>

    );
};

// Typechecking props for the Header
ProductCard.propTypes = {
    product: PropTypes.object.isRequired,
    liked: PropTypes.bool,
};


export default ProductCard;
