/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

function ExplorerIcon({ color, size }) {
  return (
    <svg

        width={size}
        height={size}
        version="1.1"
        fill={colors[color] ? colors[color].main : colors.dark.main}
        fillRule="nonzero"


        id="fi_11230341" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" data-name="glipy copy 6"><g fill="#29abe2"><path d="m35.54938 11.30188a4.51293 4.51293 0 1 0 -7.09958.00006c-29.68287 5.18852-26.78656 47.77286 3.55003 48.69806 30.34379-.933 33.22747-43.50935 3.54955-48.69812zm-3.54985-5.29978a2.52 2.52 0 0 1 -.00007 5.03978 2.52 2.52 0 0 1 .00007-5.03978zm1 8.90963v3.99984a1.00013 1.00013 0 0 1 -1.99993-.00006v-3.99978a1.00008 1.00008 0 0 1 1.99989 0zm-17.42936 21.60914h-4.34986a1.00007 1.00007 0 0 1 .00006-1.99992h4.3498a1.00007 1.00007 0 0 1 0 1.99992zm17.42932 19.77926a1.00013 1.00013 0 0 1 -1.99993-.00006v-4.3398a1.00013 1.00013 0 0 1 1.99993.00006zm7.34969-12.42951a.99894.99894 0 0 1 -1.15993.18l-10.12961-5.14984a.96476.96476 0 0 1 -.44-.43l-5.14981-10.13959a1.00193 1.00193 0 0 1 1.34-1.33985l10.12958 5.14976a.9859.9859 0 0 1 .44.43992l5.14982 10.12961a1.0021 1.0021 0 0 1 -.18005 1.15999zm12.42957-7.34975h-4.34987a1.00007 1.00007 0 0 1 .00006-1.99992h4.34981a1.00007 1.00007 0 0 1 0 1.99992z"></path><path d="m26.64 30.161 3.61 7.11 7.109 3.61-3.61-7.11z"></path></g></svg>

  );
}

// Setting default values for the props of Office
ExplorerIcon.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the Office
ExplorerIcon.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ExplorerIcon;
