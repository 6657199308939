import {useEffect, useRef, useState} from "react";

import React from "react";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Container from "@mui/material/Container";
import {PuffLoader} from "react-spinners";
import { useNavigate, useLocation } from "react-router-dom";
import {axiosApi} from "../../../../services/api";
import SoftTypography from "../../../../components/SoftTypography";
import ActionButtons from "../../../../components/Etsellify/ActionButtons";
import queryString from "query-string";
import Table from "../table";
import Header from "../header";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;

function ProductLikes() {
    const tableRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const [metaResults, setMetaResults] = useState(null);
    const [likes, setLikes] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const [formData, setFormData] = useState({
        sorting: queryParams.sorting || "",
        sortEvent: queryParams.sortEvent || "",
        page: queryParams.page || 1,
    });

    async function apiCall(updatedFormData) {
        setLoading(true);

        const response = await axiosApi.get(
            apiUrl + '/api/likes/user/product-full?page=' + updatedFormData.page + '&sorting=' + updatedFormData.sorting + '&sortEvent=' + updatedFormData.sortEvent
        ).then((response) => {
            return response.data;
        });

        const rows = [];

        response.data.forEach((row) => {
            const dataRow = {
                title: row.title,
                imageUrl: row.imageUrl,
                monthlySales: row.monthlySales,
                monthlyRevenue: row.monthlyRevenue,
                monthlyReviews: row.monthlyReviews,
                sellerReviews: row.seller.reviews,
                price: row.price,
                listingId: row.listingId,
                actionItems: {
                    liked: true,
                    identifier: row.listingId,
                    type: 'product',
                    href: '/analytics/products?listingId=' + row.listingId,
                },
            };
            rows.push(dataRow)
        });

        const columns = [
            {Header: 'Image', accessor: 'imageUrl', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <img src={row.value} alt={row.value} width={120} />
                    </SoftBox>
                ) }
            },
            {Header: 'Title', accessor: 'title', table: true, Cell: (row) => { return (
                    <p style={{width: '250px'}}>
                        {row.value}
                    </p>
                ) }
            },
            {Header: 'Monthly Sales', width: '120px', accessor: 'monthlySales', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                            {row.value ? row.value.toLocaleString() : '-'}
                        </SoftTypography>
                    </SoftBox>
                ) }
            },
            {Header: 'Monthly Reviews', width: '120px', accessor: 'monthlyReviews', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                            {row.value ? row.value.toLocaleString() : '-'}
                        </SoftTypography>
                    </SoftBox>
                ) }
            },
            {Header: 'Monthly Revenue', width: '30px', accessor: 'monthlyRevenue', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                            {row.value ? '$' + row.value.toLocaleString() : '-'}
                        </SoftTypography>
                    </SoftBox>
                ) }
            },
            {Header: 'Price', accessor: 'price', width: '30px', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                            {row.value ? '$' + row.value.toLocaleString() : '-'}
                        </SoftTypography>
                    </SoftBox>
                ) }
            },

            {Header: 'Actions', accessor: 'actionItems', table: true, Cell: (row) => { return (
                    <ActionButtons
                        href={'/analytics/products?listingId=' + row.value.identifier}
                        type={'product'}
                        isLiked={row.value.liked}
                        identifier={row.value.identifier}
                        handleLikeChange={handleLikeChange}
                    />
                ) }
            },
        ];

        setMetaResults({
            totalResults: response.meta.totalResults
        })

        const result = {
            "rows": rows,
            "columns": columns
        };
        setResults(result);

        setLoading(false);
    }

    const handleLikeChange = (identifier) => {
    }

    useEffect(() => {
        apiCall(formData)
    }, []);

    const handleSorting = (sorting) => {
        if (formData.sorting === sorting.value) {
            return
        }

        const updatedFormData = {
            ...formData,
            sorting: sorting.value,
            sortEvent: sorting,
            page: 1,
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    }

    const handlePageChange = (newPage) => {

        newPage = newPage + 1;
        console.log(newPage);

        const updatedFormData = {
            ...formData,
            page: newPage
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    };

    return (
        <DashboardLayout>
            <Header title={'Liked Products'}/>
            <SoftBox py={3}>
                <Container>
                    {loading && (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <PuffLoader
                                color={'#17c1e8'}
                                loading={loading}
                                size={80}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />

                        </div>
                    )}

                    <div ref={tableRef}>
                        {!loading && results &&
                            <Table
                                title={'Liked Products'}
                                key={formData.page}
                                tableData={results}
                                meta={metaResults}
                                page={formData.page}
                                onPageChange={handlePageChange}
                                totalResults={metaResults.totalResults}
                            />}
                    </div>
                </Container>
            </SoftBox>
        </DashboardLayout>
    );
}

export default ProductLikes;
