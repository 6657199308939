/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

function HomeIcon({ color, size }) {
  return (
    <svg

        width={size}
        height={size}
        version="1.1"
        fill={colors[color] ? colors[color].main : colors.dark.main}
        fillRule="nonzero"


    id="fi_6839887"  viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m236.33 50.31c-2.22-26.54-22.33-47.49-47.79-49.26-15.32-1.05-36.35-1.55-65.16-.33s-49.91 3.51-65.38 5.85c-25.61 3.95-47.48 25.83-51.43 51.43-2.34 15.47-4.57 36.58-5.85 65.38s-.74 49.83.33 65.16c1.78 25.46 22.72 45.57 49.26 47.79 16 1.3 37.84 2.22 67.79 2.13s51.85-1.13 67.81-2.57a55.1 55.1 0 0 0 50-50c1.44-15.95 2.49-37.85 2.57-67.81s-.85-51.82-2.15-67.77z"></path><path d="m505.43 58c-3.95-25.6-25.83-47.48-51.43-51.43-15.47-2.34-36.58-4.57-65.38-5.85s-49.83-.74-65.16.33c-25.46 1.77-45.57 22.72-47.79 49.26-1.3 16-2.22 37.84-2.13 67.79s1.13 51.85 2.57 67.81a55.09 55.09 0 0 0 50 50c16 1.44 37.85 2.49 67.81 2.57s51.84-.83 67.79-2.13c26.53-2.22 47.48-22.34 49.26-47.79 1.07-15.33 1.55-36.35.33-65.16s-3.53-49.93-5.87-65.4z"></path><path d="m235.89 326.09a55.09 55.09 0 0 0 -50-50c-16-1.44-37.85-2.49-67.81-2.57s-51.84.83-67.79 2.13c-26.51 2.24-47.47 22.35-49.24 47.81-1.05 15.33-1.55 36.35-.33 65.16s3.51 49.91 5.85 65.38c3.95 25.6 25.83 47.48 51.43 51.43 15.44 2.34 36.55 4.6 65.35 5.85s49.83.74 65.16-.33c25.44-1.77 45.57-22.73 47.79-49.26 1.3-15.95 2.22-37.84 2.13-67.79s-1.1-51.9-2.54-67.81z"></path><path d="m511 323.46c-1.77-25.44-22.73-45.57-49.26-47.79-15.95-1.3-37.84-2.22-67.79-2.13s-51.86 1.13-67.81 2.57a55.09 55.09 0 0 0 -50 50c-1.44 16-2.49 37.85-2.57 67.81s.83 51.84 2.13 67.79c2.22 26.53 22.35 47.49 47.79 49.26 15.33 1.07 36.35 1.55 65.16.33s49.91-3.51 65.35-5.85c25.64-3.95 47.51-25.83 51.46-51.46 2.34-15.44 4.6-36.55 5.85-65.35s.69-49.85-.31-65.18z"></path></svg>

  );
}

// Setting default values for the props of Office
HomeIcon.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the Office
HomeIcon.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default HomeIcon;
