import Card from "@mui/material/Card";
import SoftBox from "../../../components/SoftBox";
import PropTypes from "prop-types";
import DataTable from "./DataTable";


function Table({tableData, onPageChange, page, totalResults, title}) {
    const handlePaginationClick = (pageNumber) => {
        console.log(pageNumber);
        onPageChange(pageNumber);
    };

    return (
        <SoftBox mb={3} sx={{marginTop: "30px"}}>
            <Card>
                <DataTable
                    table={tableData}
                    onPageChange={handlePaginationClick}
                    pageIndex={page - 1}
                    totalResults={totalResults}
                    title={title}
                />
            </Card>
        </SoftBox>
    );
}

// Typechecking props for the Header
Table.propTypes = {
    tableData: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    totalResults: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};

export default Table;