import React, { createContext, useState } from 'react';
import PropTypes from "prop-types";

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ token: null, tokenExpiration: null });

    const setAuthData = (data) => {
        setAuth(data);
    };

    return (
        <authContext.Provider value={{ auth, setAuthData }}>
            {children}
        </authContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.elementType.isRequired,
};

export default AuthProvider;