/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import BarReportsChartItem from "examples/Charts/BarCharts/ReportsBarChart/ReportsBarChartItem";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";
import MiniStatisticsCard from "../../../../examples/Cards/StatisticsCards/MiniStatisticsCard";


import GaugeComponent from 'react-gauge-component'

function ReportCompetitionChart({ competitionScore, totalResults }) {
    return (
        <Card sx={{ height: "100%" }}>
            <SoftBox padding="1rem">

                <SoftBox mb={3}>
                    <MiniStatisticsCard
                        title={{ text: 'Total Results' }}
                        count={totalResults}
                        icon={{ color: "info", component: "paid" }}
                    />
                </SoftBox>

                <SoftBox display="flex" justifyContent="center" alignItems="center" mb={1}>
                    <GaugeComponent
                        style={{ width: 300 }}
                        arc={{
                            nbSubArcs: 100,
                            colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
                            width: 0.3,
                            padding: 0.003
                        }}
                        labels={{
                            valueLabel: {
                                fontSize: 20,
                                style: {
                                    fontSize: "35px",
                                    fill: "#000000",
                                    textShadow: "none",
                                    color: "black"
                                }
                            },

                            markLabel: {
                                type: "outer",
                                marks: [
                                    { value: 0 },
                                    { value: 20 },
                                    { value: 40 },
                                    { value: 60 },
                                    { value: 80 },
                                    { value: 100 },
                                ],
                                style: {
                                    fontSize: "25px",
                                    fill: "#000000",
                                    textShadow: "none",
                                    color: "black"
                                },

                                markerConfig: {
                                    style: {
                                        fontSize: "12px",
                                        fill: "#000000",
                                        textShadow: "none",
                                        color: "black"
                                    },
                                },

                                valueConfig: {
                                    style: {
                                        fontSize: "12px",
                                        fill: "#000000",
                                        textShadow: "none",
                                        color: ""
                                    },
                                },
                            },
                        }}
                        value={competitionScore}
                        maxValue={100}
                    />
                </SoftBox>

                <SoftBox display="flex" justifyContent="center" alignItems="center" mb={1}>
                    <SoftTypography fontSize={16} variant="caption" fontWeight="medium" color="dark">
                        Competition:
                    </SoftTypography>
                    <SoftTypography  variant="caption" fontWeight="bold" color="text" sx={{marginLeft: '3px'}}>
                        {
                            competitionScore >= 80  ? (
                                <SoftTypography fontSize={16} variant="caption" fontWeight="bold" color="error">
                                    Very High
                                </SoftTypography>
                            ) : competitionScore >= 60 ? (
                                <SoftTypography fontSize={16} variant="caption" fontWeight="bold" color="warning">
                                    High
                                </SoftTypography>
                            ) : competitionScore >= 40 ? (
                                <SoftTypography fontSize={16} variant="caption" fontWeight="bold" color="info">
                                    Medium
                                </SoftTypography>
                            ) : competitionScore >= 20 ? (
                                <SoftTypography fontSize={16} variant="caption" fontWeight="bold" color="success">
                                    Low
                                </SoftTypography>
                            ) : (
                                <SoftTypography fontSize={16} variant="caption" fontWeight="bold" color="success">
                                    Very Low
                                </SoftTypography>
                            )
                        }
                    </SoftTypography>
                </SoftBox>

                {/*<SoftBox px={1}>*/}
                {/*    <SoftBox mb={2}>*/}
                {/*        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">*/}
                {/*            {title}*/}
                {/*        </SoftTypography>*/}
                {/*        <SoftTypography component="div" variant="button" color="text" fontWeight="regular">*/}
                {/*            {description}*/}
                {/*        </SoftTypography>*/}
                {/*    </SoftBox>*/}
                {/*    /!*<SoftBox py={1} px={0.5}>*!/*/}
                {/*    /!*    <Grid container spacing={2}>*!/*/}
                {/*    /!*        {renderItems}*!/*/}
                {/*    /!*    </Grid>*!/*/}
                {/*    /!*</SoftBox>*!/*/}
                {/*</SoftBox>*/}
            </SoftBox>
        </Card>
    );
}

// Setting default values for the props of ReportsBarChart
ReportCompetitionChart.defaultProps = {
    color: "dark",
    description: "",
    items: [],
};

// Typechecking props for the ReportChart
ReportCompetitionChart.propTypes = {
    competitionScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalResults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ReportCompetitionChart;
