import React from 'react';
import ReactApexChart from 'react-apexcharts';

// eslint-disable-next-line react/prop-types
const ApexTreeChart = ({seriesSectionData}) => {
    const seriesData = [
        {
            data: seriesSectionData
        },
    ];

    const options = {
        legend: {
            show: false,
        },
        chart: {
            height: 600,
            type: 'treemap',
        },
        title: {
            text: 'Top Keyword Map',
        },
        // hides hover tooltip
        tooltip: {
            enabled: false,
        },
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={seriesData} type="treemap" height={600} />
        </div>
    );
};

export default ApexTreeChart;
