import {useEffect, useRef, useState} from "react";

import React from "react";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Container from "@mui/material/Container";
import {PuffLoader} from "react-spinners";
import { useNavigate, useLocation } from "react-router-dom";
import {axiosApi} from "../../../../services/api";
import SoftTypography from "../../../../components/SoftTypography";
import ActionButtons from "../../../../components/Etsellify/ActionButtons";
import queryString from "query-string";
import Table from "../table";
import Header from "../header";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;

function SellerLikes() {
    const tableRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const [metaResults, setMetaResults] = useState(null);
    const [likes, setLikes] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const [formData, setFormData] = useState({
        sorting: queryParams.sorting || "",
        sortEvent: queryParams.sortEvent || "",
        page: queryParams.page || 1,
    });

    async function apiCall(updatedFormData) {
        setLoading(true);

        const response = await axiosApi.get(
            apiUrl + '/api/likes/user/seller-full?page=' + updatedFormData.page + '&sorting=' + updatedFormData.sorting + '&sortEvent=' + updatedFormData.sortEvent
        ).then((response) => {
            return response.data;
        });

        const rows = [];

        response.data.forEach((row) => {
            const dataRow = {
                name: {
                    name: row.name,
                    joinedSince: row.joinedSince,
                    location: row.location,
                },
                monthlySales: row.monthlySales,
                // monthlyRevenue: row.monthlyRevenue,
                monthlyReviews: row.monthlyReviews,
                totalSales: row.totalSales,
                totalReviews: row.allReviews,
                admirers: row.admirers,
                actions: {
                    name: row.name,
                    id: row.id,
                    liked: true,
                    identifier: row.name,
                    type: 'seller',
                    href: '/analytics/sellers?name=' + row.name,
                }
            };
            rows.push(dataRow)
        });

        const columns = [
            {Header: 'Seller',  accessor: 'name', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'20px'} fontWeight={'bold'} width={'250px'}>
                            {row.value['name']}
                        </SoftTypography>

                        <SoftTypography fontSize={'14px'}>
                            Location: <b>{row.value['location']}</b>
                        </SoftTypography>

                        <SoftTypography fontSize={'14px'}>
                            Joined Since: <b>{row.value['joinedSince']}</b>
                        </SoftTypography>
                    </SoftBox>
                ) }
            },
            {Header: 'Monthly Sales', width: '120px', accessor: 'monthlySales', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                            {row.value}
                        </SoftTypography>
                    </SoftBox>
                ) }
            },
            {Header: 'Monthly Reviews', width: '120px', accessor: 'monthlyReviews', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                            {row.value}
                        </SoftTypography>
                    </SoftBox>
                ) }
            },

            {Header: 'Total Sales', accessor: 'totalSales', width: '30px', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                            {row.value}
                        </SoftTypography>
                    </SoftBox>
                ) }
            },
            {Header: 'Total Reviews', accessor: 'totalReviews', width: '30px', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                            {row.value}
                        </SoftTypography>
                    </SoftBox>
                ) }
            },
            {Header: 'Admirers', accessor: 'admirers', width: '30px', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                            {row.value}
                        </SoftTypography>
                    </SoftBox>
                ) }
            },
            {Header: 'Actions', accessor: 'actions', table: true, Cell: (row) => { return (
                    <ActionButtons
                        isLiked={row.value.liked}
                        href={'/analytics/sellers?name=' + row.value.name}
                        type={'seller'}
                        identifier={row.value.name}
                    />
                )}
            },
        ];

        setMetaResults({
            totalResults: response.meta.totalResults
        })

        const result = {
            "rows": rows,
            "columns": columns
        };
        setResults(result);

        setLoading(false);
    }

    const handleLikeChange = (identifier) => {
    }

    useEffect(() => {
        apiCall(formData)
    }, []);

    const handleSorting = (sorting) => {
        if (formData.sorting === sorting.value) {
            return
        }

        const updatedFormData = {
            ...formData,
            sorting: sorting.value,
            sortEvent: sorting,
            page: 1,
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    }

    const handlePageChange = (newPage) => {

        newPage = newPage + 1;
        console.log(newPage);

        const updatedFormData = {
            ...formData,
            page: newPage
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    };

    return (
        <DashboardLayout>
            <Header title={'Liked Sellers'}/>
            <SoftBox py={3}>
                <Container>
                    {loading && (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <PuffLoader
                                color={'#17c1e8'}
                                loading={loading}
                                size={80}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />

                        </div>
                    )}

                    <div ref={tableRef}>
                        {!loading && results &&
                            <Table
                                title={'Liked Sellers'}
                                key={formData.page}
                                tableData={results}
                                meta={metaResults}
                                page={formData.page}
                                onPageChange={handlePageChange}
                                totalResults={metaResults.totalResults}
                            />}
                    </div>
                </Container>
            </SoftBox>
        </DashboardLayout>
    );
}

export default SellerLikes;
