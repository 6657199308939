import SoftBox from "components/SoftBox";

// Pricing page components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Header from "../research/Header";
import Grid from "@mui/material/Grid";
import SoftAvatar from "../../components/SoftAvatar";
import keywordResearch from "../../assets/images/app/keyword-research.png";
import productResearch from "../../assets/images/app/product-research.png";
import sellerResearch from "../../assets/images/app/seller-research.png";
import scan from "../../assets/images/app/scanning.png";
import compass from "../../assets/images/app/compass.png";


import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import Card from "@mui/material/Card";
import {useNavigate} from "react-router-dom";
import ExplorerIcon from "../../assets/Icons/ExplorerIcon";
import rocketShip from "../../assets/images/illustrations/rocket-white.png";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

function HomeV2Dashboard() {
    const navigate = useNavigate();

    return (
        <DashboardLayout>
            <Header/>

            <Card
                id="basic-info"
                sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -10,
                    mx: 3,
                    py: 8,
                    px: 2,
                    overflow: "visible"
                }}
            >

                <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
                    <Grid item xs={10} lg={5}>
                        <SoftBox mb={1}>
                            <SoftTypography variant="h2" color="black" fontWeight="bold">
                                Welcome!
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftTypography variant="body2" color="dark" fontWeight="regular">
                                Tools Suite for Etsy Sellers
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                </Grid>

                <Grid
                    container justifyContent="center" sx={{ textAlign: "center" }}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <SoftBox mt={2} style={{'width': '100%'}}>

                        <SoftBox
                            height="100%"
                            display="flex"
                            flexDirection="column"
                            justifyContent="flex-end"
                            alignItems="center"
                            borderRadius="xl"
                            shadow="xxl"
                            border={`5 dashed black`}
                            textAlign="center"
                            p={3}
                        >
                            <SoftBox mb={1}>
                                <SoftTypography variant="h3" fontWeight="medium">
                                    Explorer
                                </SoftTypography>
                                <SoftTypography variant="body2" color="text">
                                    Need inspiration? Start here. This explorer will help you to generate ideas for your next product.
                                </SoftTypography>
                            </SoftBox>

                            <SoftBox
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="xl"
                                shadow="xxl"
                                border={`5 dashed black`}
                                textAlign="center"
                                p={3}
                                style={{'cursor': 'pointer'}}
                                onClick={
                                    () => {navigate("/explorer")}
                                }
                            >
                                <SoftTypography variant="h5" p={3}>
                                    Products Explorer
                                </SoftTypography>
                                <SoftBox component="img" src={compass} alt="Explorer" width="100px" mb={3} />
                                <SoftTypography
                                    component="div"
                                    variant="button"
                                    color="white"
                                    textTransform="capitalize"
                                    fontWeight="medium"
                                    sx={({ palette: { secondary }, typography: { size } }) => ({
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: `${secondary.main} !important`,

                                        "& .material-icons-round": {
                                            ml: 0.5,
                                            fontSize: size.md,
                                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                        },

                                        "&:hover .material-icons-round": {
                                            transform: "translateX(5px)",
                                        },
                                    })}
                                >

                                    Explore <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>

                                </SoftTypography>
                            </SoftBox>

                        </SoftBox>
                    </SoftBox>
                </Grid>

                <Divider />

                <Grid
                    container justifyContent="center" sx={{ textAlign: "center" }}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <SoftBox mt={2} style={{'marginBottom': '100px', 'width': '100%'}}>

                        <SoftBox mb={1}>
                            <SoftTypography variant="h3" fontWeight="medium">
                                Research Tools
                            </SoftTypography>
                            <SoftTypography variant="body2" color="text">
                                <p>
                                    Find anything based on your criteria.
                                </p>
                                <p>
                                    Many filters and options are available to help you find the best products, keywords, and sellers.
                                </p>
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox
                            height="100%"
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="xl"
                            shadow="xxl"
                            border={`5 dashed black`}
                            textAlign="center"
                            p={3}
                        >

                            <SoftBox
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="xl"
                                shadow="xxl"
                                border={`5 dashed black`}
                                textAlign="center"
                                p={3}
                                style={{'cursor': 'pointer'}}
                                onClick={
                                    () => {navigate("/research-keywords")}
                                }
                            >
                                <SoftTypography variant="h5" p={3}>
                                    Keywords Research
                                </SoftTypography>
                                <SoftBox component="img" src={keywordResearch} alt="Explorer" width="100px" mb={3} />
                                <SoftTypography
                                    component="div"
                                    variant="button"
                                    color="white"
                                    textTransform="capitalize"
                                    fontWeight="medium"
                                    sx={({ palette: { secondary }, typography: { size } }) => ({
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: `${secondary.main} !important`,

                                        "& .material-icons-round": {
                                            ml: 0.5,
                                            fontSize: size.md,
                                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                        },

                                        "&:hover .material-icons-round": {
                                            transform: "translateX(5px)",
                                        },
                                    })}
                                >
                                    Research Keywords <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                </SoftTypography>
                            </SoftBox>

                            <SoftBox
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="xl"
                                shadow="xxl"
                                border={`5 dashed black`}
                                textAlign="center"
                                p={3}
                                style={{'cursor': 'pointer'}}
                                onClick={
                                    () => {navigate("/research-products")}
                                }
                            >
                                <SoftTypography variant="h5" p={3}>
                                    Products Research
                                </SoftTypography>
                                <SoftBox component="img" src={productResearch} alt="Explorer" width="100px" mb={3} />
                                <SoftTypography
                                    component="div"
                                    variant="button"
                                    color="white"
                                    textTransform="capitalize"
                                    fontWeight="medium"
                                    sx={({ palette: { secondary }, typography: { size } }) => ({
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: `${secondary.main} !important`,

                                        "& .material-icons-round": {
                                            ml: 0.5,
                                            fontSize: size.md,
                                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                        },

                                        "&:hover .material-icons-round": {
                                            transform: "translateX(5px)",
                                        },
                                    })}
                                >
                                    Research Products <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                </SoftTypography>
                            </SoftBox>


                            <SoftBox
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="xl"
                                shadow="xxl"
                                border={`5 dashed black`}
                                textAlign="center"
                                p={3}
                                style={{'cursor': 'pointer'}}
                                onClick={
                                    () => {navigate("/research-sellers")}
                                }
                            >
                                <SoftTypography variant="h5" p={3}>
                                    Sellers Research
                                </SoftTypography>
                                <SoftBox component="img" src={sellerResearch} alt="Explorer" width="100px" mb={3} />
                                <SoftTypography
                                    component="div"
                                    variant="button"
                                    color="white"
                                    textTransform="capitalize"
                                    fontWeight="medium"
                                    sx={({ palette: { secondary }, typography: { size } }) => ({
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: `${secondary.main} !important`,

                                        "& .material-icons-round": {
                                            ml: 0.5,
                                            fontSize: size.md,
                                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                        },

                                        "&:hover .material-icons-round": {
                                            transform: "translateX(5px)",
                                        },
                                    })}
                                >

                                    Research Sellers <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>

                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </Grid>

                <Divider/>

                <Grid
                    container justifyContent="center" sx={{ textAlign: "center" }}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <SoftBox mt={2} style={{'marginBottom': '100px', 'width': '100%'}}>

                        <SoftBox mb={1}>
                            <SoftTypography variant="h3" fontWeight="medium">
                                Analytics Tools
                            </SoftTypography>
                            <SoftTypography variant="body2" color="text">
                                <p>
                                    Reveal the data behind the products, keywords, and sellers.
                                </p>
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox
                            height="100%"
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="xl"
                            shadow="xxl"
                            border={`5 dashed black`}
                            textAlign="center"
                            p={3}
                        >

                            <SoftBox
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="xl"
                                shadow="xxl"
                                border={`5 dashed black`}
                                textAlign="center"
                                p={3}
                                style={{'cursor': 'pointer'}}
                                onClick={
                                    () => {navigate("/analytics/keywords")}
                                }
                            >
                                <SoftTypography variant="h5" p={3}>
                                    Keyword Analytics
                                </SoftTypography>
                                <SoftBox component="img" src={scan} alt="Explorer" width="100px" mb={3} />
                                <SoftTypography
                                    component="div"
                                    variant="button"
                                    color="white"
                                    textTransform="capitalize"
                                    fontWeight="medium"
                                    sx={({ palette: { secondary }, typography: { size } }) => ({
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: `${secondary.main} !important`,

                                        "& .material-icons-round": {
                                            ml: 0.5,
                                            fontSize: size.md,
                                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                        },

                                        "&:hover .material-icons-round": {
                                            transform: "translateX(5px)",
                                        },
                                    })}
                                >
                                    Analyze Keyword <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                </SoftTypography>
                            </SoftBox>

                            <SoftBox
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="xl"
                                shadow="xxl"
                                border={`5 dashed black`}
                                textAlign="center"
                                p={3}
                                style={{'cursor': 'pointer'}}
                                onClick={
                                    () => {navigate("/analytics/products")}
                                }
                            >
                                <SoftTypography variant="h5" p={3}>
                                    Product Analytics
                                </SoftTypography>
                                <SoftBox component="img" src={scan} alt="Explorer" width="100px" mb={3} />
                                <SoftTypography
                                    component="div"
                                    variant="button"
                                    color="white"
                                    textTransform="capitalize"
                                    fontWeight="medium"
                                    sx={({ palette: { secondary }, typography: { size } }) => ({
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: `${secondary.main} !important`,

                                        "& .material-icons-round": {
                                            ml: 0.5,
                                            fontSize: size.md,
                                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                        },

                                        "&:hover .material-icons-round": {
                                            transform: "translateX(5px)",
                                        },
                                    })}
                                >
                                    Analyze Product <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                </SoftTypography>
                            </SoftBox>

                            <SoftBox
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="xl"
                                shadow="xxl"
                                border={`5 dashed black`}
                                textAlign="center"
                                p={3}
                                style={{'cursor': 'pointer'}}
                                onClick={
                                    () => {navigate("/analytics/sellers")}
                                }
                            >
                                <SoftTypography variant="h5" p={3}>
                                    Seller Analytics
                                </SoftTypography>
                                <SoftBox component="img" src={scan} alt="Explorer" width="100px" mb={3} />
                                <SoftTypography
                                    component="div"
                                    variant="button"
                                    color="white"
                                    textTransform="capitalize"
                                    fontWeight="medium"
                                    sx={({ palette: { secondary }, typography: { size } }) => ({
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: `${secondary.main} !important`,

                                        "& .material-icons-round": {
                                            ml: 0.5,
                                            fontSize: size.md,
                                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                        },

                                        "&:hover .material-icons-round": {
                                            transform: "translateX(5px)",
                                        },
                                    })}
                                >

                                    Analyze Seller <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>

                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </Grid>





            </Card>


        </DashboardLayout>
    );
}

export default HomeV2Dashboard;
