// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import {useEffect, useMemo, useState} from "react";
import Grid from "@mui/material/Grid";
import ReportChart from "../../../../layouts/radar/keywords/Meta/ReportChart";
import ReportCompetitionChart from "../../../../layouts/radar/keywords/Meta/ReportCompetitionChart";
import ReportsBarChart from "../../../../examples/Charts/BarCharts/ReportsBarChart";
import ApexBarChart from "../../../../layouts/radar/keywords/Meta/ApexBarChart";
import waves from "../../../../assets/images/shapes/waves-white.svg";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";



function DefaultMetricsCard({ section, rows }) {
    return (
        <Card>
            <SoftBox p={2}>
                <Grid container>
                    <Grid item xs={10}>
                        <SoftBox mb={0.5} lineHeight={1}>
                            <SoftTypography
                                variant="button"
                                fontWeight="bold"
                                color="dark"
                                textTransform="capitalize"
                            >
                                {section}
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox lineHeight={1}>
                            {
                                rows.map((row, key) => {
                                    const rowKey = Object.keys(row)[0]; // Get the key of each object
                                    const value = row[rowKey]; // Get the corresponding value
                                    return (
                                        <>
                                            <SoftTypography
                                                key={rowKey}
                                                variant="body2"
                                                fontWeight="regular"
                                                color="text"
                                                textTransform="capitalize"
                                            >
                                                {rowKey}: <b>{value}</b>
                                            </SoftTypography>
                                            <hr/>
                                        </>

                                    );
                                }
                                )
                            }
                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox>
        </Card>
    );
}

DefaultMetricsCard.propTypes = {
    section: PropTypes.string.isRequired,
    rows: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function MetaTableView({metaResults, tabValue, sellersAndProducts, treeChartData, productResults, handleSetTabValue, productsForChart}) {

    const [salesBarChartData, setSalesBarChartData] = useState(null);
    const [pricesBarChartData, setPricesBarChartData] = useState(null);
    const [sellerReviewBarChartData, setSellerReviewBarChartData] = useState(null);
    const [productReviewsBarChartData, setProductReviewsBarChartData] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        // to fix percentages
        const salesBarChartDataDict = {
            items: [
                {
                    icon: { color: "primary", component: "library_books" },
                    label: "Total",
                    progress: { content: metaResults.fullKeyword.monthlySales.total, percentage: 100 },
                },
                {
                    icon: { color: "info", component: "touch_app" },
                    label: "Organic",
                    progress: { content: metaResults.fullKeyword.monthlySales.organic, percentage: metaResults.fullKeyword.monthlySales.organic / metaResults.fullKeyword.monthlySales.total * 100 },
                },
                {
                    icon: { color: "warning", component: "payment" },
                    label: "Sponsored",
                    progress: { content: metaResults.fullKeyword.monthlySales.sponsored, percentage: metaResults.fullKeyword.monthlySales.sponsored / metaResults.fullKeyword.monthlySales.total * 100 },
                },
            ],
        };

        setSalesBarChartData(salesBarChartDataDict)

        let prices = [];
        let titles = [];
        let minPrice = null;
        let maxPrice = null;
        productResults['rows'].forEach((row) =>  {
            prices.push(row.price);
            let shortTitle = row.title.title.substring(0, 30);
            titles.push(shortTitle + '...');

            if (minPrice === null || row.price < minPrice) {
                minPrice = row.price;
            }

            if (maxPrice === null || row.price > maxPrice) {
                maxPrice = row.price;
            }
        });

        let avgPrice = prices.reduce((a, b) => a + b, 0) / prices.length;
        avgPrice = avgPrice.toFixed(2);


        const pricesBarChartDataDict = {
            chart: {
                labels: titles,
                datasets: { label: "Price $", data: prices },
            },
            items: [
                {
                    icon: { color: "primary", component: "library_books" },
                    label: "Min",
                    progress: { content: minPrice, percentage: minPrice / maxPrice * 100 },
                },
                {
                    icon: { color: "info", component: "touch_app" },
                    label: "Max",
                    progress: { content: maxPrice, percentage: 100  },
                },
                {
                    icon: { color: "warning", component: "payment" },
                    label: "Avg",
                    progress: { content: avgPrice, percentage: avgPrice / maxPrice * 100 },
                },
            ],
        };


        const sellerNamesWithSales = [];
        Object.values(sellersAndProducts).forEach((seller) => {
            sellerNamesWithSales.push(seller.seller);
        });

        setPricesBarChartData(pricesBarChartDataDict)

        let sellerReviews = [];
        let names = [];
        let minSellerReview = null;
        let maxSellerReview = null;
        metaResults.fullKeyword.sellers.forEach((row) =>  {

            if (!sellerNamesWithSales.includes(row.name)) {
                return;
            }

            sellerReviews.push(row.reviews);
            let shortTitle = row.name;
            names.push(shortTitle);

            if (minSellerReview === null || row.reviews < minSellerReview) {
                minSellerReview = row.reviews;
            }

            if (maxSellerReview === null || row.reviews > maxSellerReview) {
                maxSellerReview = row.reviews;
            }
        });

        let avgSellerReview = sellerReviews.reduce((a, b) => a + b, 0) / sellerReviews.length;
        avgSellerReview = avgSellerReview.toFixed(2);


        const sellerReviewBarChartDataDict = {
            chart: {
                labels: names,
                datasets: { label: "Seller Reviews ", data: sellerReviews },
            },
            items: [
                {
                    icon: { color: "primary", component: "library_books" },
                    label: "Min",
                    progress: { content: minSellerReview, percentage: minSellerReview / maxSellerReview * 100 },
                },
                {
                    icon: { color: "info", component: "touch_app" },
                    label: "Max",
                    progress: { content: maxSellerReview, percentage: 100  },
                },
                {
                    icon: { color: "warning", component: "payment" },
                    label: "Avg",
                    progress: { content: avgSellerReview, percentage: avgSellerReview / maxSellerReview * 100 },
                },
            ],
        };

        setSellerReviewBarChartData(sellerReviewBarChartDataDict);



        const monthlyProductReviews = [];
        let minProductReview = null;
        let maxProductReview = null;
        productResults['rows'].forEach((row) =>  {
            monthlyProductReviews.push(row.reviews);

            if (minProductReview === null || row.reviews < minProductReview) {
                minProductReview = row.reviews;
            }

            if (maxProductReview === null || row.reviews > maxProductReview) {
                maxProductReview = row.reviews;
            }

        });

        let avgProductReview = monthlyProductReviews.reduce((a, b) => a + b, 0) / monthlyProductReviews.length;


        const productReviewsBarChartDataDict = {
            chart: {
                labels: names,
                datasets: { label: "Monthly Product Reviews ", data: monthlyProductReviews },
            },
            items: [
                {
                    icon: { color: "primary", component: "library_books" },
                    label: "Min",
                    progress: { content: minProductReview, percentage: minProductReview / maxProductReview * 100 },
                },
                {
                    icon: { color: "info", component: "touch_app" },
                    label: "Max",
                    progress: { content: maxProductReview, percentage: 100  },
                },
                {
                    icon: { color: "warning", component: "payment" },
                    label: "Avg",
                    progress: { content: avgProductReview.toFixed(1), percentage: avgProductReview / maxProductReview * 100 },
                },
            ],
        };

        setProductReviewsBarChartData(productReviewsBarChartDataDict);

        setLoading(false);

    }, [metaResults]);



    return (
        <Card sx={{ height: "100%" }}>

            <SoftBox>
                <SoftTypography  style={{ textTransform: 'capitalize' }} variant="h3" fontWeight="bold" textAlign={'center'}>
                    {metaResults.keyword}
                </SoftTypography>
            </SoftBox>

            <SoftBox
            >
                <SoftBox
                    component="img"
                    src={waves}
                    alt="waves"
                    width="105rem"
                    position="absolute"
                    top={0}
                />
                <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
                    <Grid item xs={12} lg={12}>
                        <Grid container item xs={12} sm={10} md={8} lg={7} sx={{ mx: "auto" }}>
                            <SoftBox width="100%" mt={6} mb={6}>
                                <AppBar position="static">
                                    <Tabs value={tabValue} onChange={handleSetTabValue}>
                                        <Tab
                                            id="keywords"
                                            label={
                                                <SoftBox py={0.5} px={5}>
                                                    <SoftTypography variant="h6" fontWeight="bold">
                                                        Keywords
                                                    </SoftTypography>
                                                </SoftBox>
                                            }
                                        />
                                        <Tab
                                            id="products"
                                            label={
                                                <SoftBox py={0.5} px={5}>
                                                    <SoftTypography variant="h6" fontWeight="bold">
                                                        Products
                                                    </SoftTypography>
                                                </SoftBox>
                                            }
                                        />
                                        <Tab
                                            id="sellers"
                                            label={
                                                <SoftBox py={0.5} px={5}>
                                                    <SoftTypography variant="h6" fontWeight="bold">
                                                        Sellers
                                                    </SoftTypography>
                                                </SoftBox>
                                            }
                                        />
                                    </Tabs>
                                </AppBar>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </Grid>
            </SoftBox>

            <SoftBox mb={3}>
                <Grid container spacing={3}>

                    {tabValue === 0 && !loading && (
                        <>
                            <Grid item xs={12} lg={6}>
                                <ReportChart
                                    title="Monthly Sales"
                                    items={salesBarChartData['items']}
                                    topLabel={"Monthly Revenue"}
                                    topValue={'$' + metaResults.productsRevenue.toLocaleString()}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <ReportCompetitionChart
                                    competitionScore={metaResults.competitionScore}
                                    totalResults={metaResults.totalResults.toLocaleString()}
                                />
                            </Grid>
                        </>
                    )}

                    {tabValue === 1 && !loading && (
                        <>
                            <Grid item xs={12} lg={6}>
                                <ReportsBarChart
                                    title="Product Prices"
                                    chart={pricesBarChartData["chart"]}
                                    items={pricesBarChartData["items"]}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <ReportsBarChart
                                    title="Monthly Product Reviews"
                                    chart={productReviewsBarChartData["chart"]}
                                    items={productReviewsBarChartData["items"]}
                                />
                            </Grid>

                            <Grid item xs={12} lg={12}>
                                <SoftTypography ml={5} variant="h5" fontWeight="bold">
                                    Monthly Product Revenue
                                </SoftTypography>
                                <ApexBarChart seriesSectionData={productsForChart.slice(0, 15)} attribute={'monthlyRevenue'} thousands={true}/>
                            </Grid>
                        </>
                    )}

                    {tabValue === 2 && !loading && (
                        <>
                            <Grid item xs={12} lg={12}>
                                <ReportsBarChart
                                    title="Seller Reviews"
                                    chart={sellerReviewBarChartData["chart"]}
                                    items={sellerReviewBarChartData["items"]}
                                />
                            </Grid>

                            <Grid item xs={12} lg={12}>
                                <SoftTypography ml={5} variant="h5" fontWeight="bold">
                                    Monthly Revenue By Seller
                                </SoftTypography>
                                <ApexBarChart thousands={true} attribute={'monthlyRevenue'} dataLabels={false} seriesSectionData={sellersAndProducts.slice(0, 10)}/>
                            </Grid>
                        </>
                    )}
                </Grid>
            </SoftBox>

        </Card>
    );
}

// Typechecking props for the DataTableBodyCell
MetaTableView.propTypes = {
    metaResults: PropTypes.object.isRequired,
    tabValue: PropTypes.number,
    sellersAndProducts: PropTypes.array,
    productsForChart: PropTypes.array,
    treeChartData: PropTypes.array,
    productResults: PropTypes.object,
    handleSetTabValue: PropTypes.func,
};


export default MetaTableView;
