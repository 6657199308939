import {useEffect, useRef, useState} from "react";

import React from "react";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Container from "@mui/material/Container";
import KeywordFormExpert from "../../../app/pages/KeywordTool/keywordFormExpert";
import SoftTypography from "../../../components/SoftTypography";
import Table from "./table";
import {PuffLoader} from "react-spinners";
import SoftProgress from "../../../components/SoftProgress";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../Header";
import ActionButtons from "../../../components/Etsellify/ActionButtons";
import {axiosApi} from "../../../services/api";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;


function KeywordResearchTool() {
    const tableRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const [formData, setFormData] = useState({
        keyword: queryParams.keyword || "",
        keywords: queryParams.keywords || "",
        category: queryParams.category || "",
        keywordSalesFrom: queryParams.keywordSalesFrom || "",
        keywordSalesTo: queryParams.keywordSalesTo || "",
        monthlySalesFrom: queryParams.monthlySalesFrom || "",
        monthlySalesTo: queryParams.monthlySalesTo || "",
        totalResultsFrom: queryParams.totalResultsFrom || "",
        totalResultsTo: queryParams.totalResultsTo || "",
        sellerReviewsFrom: queryParams.sellerReviewsFrom || "",
        sellerReviewsTo: queryParams.sellerReviewsTo || "",
        productPriceFrom: queryParams.productPriceFrom || "",
        productPriceTo: queryParams.productPriceTo || "",
        competition: queryParams.competition || "",
        sorting: queryParams.sorting || "",
        sortEvent: queryParams.sortEvent || "",
        page: queryParams.page || 1,
    });
    const [metaResults, setMetaResults] = useState();

    useEffect(() => {
        const queryParams = queryString.parse(location.search);

        setFormData({
            keyword: queryParams.keyword || "",
            keywords: queryParams.keywords || "",
            category: queryParams.category || "",
            keywordSalesFrom: queryParams.keywordSalesFrom || "",
            keywordSalesTo: queryParams.keywordSalesTo || "",
            monthlySalesFrom: queryParams.monthlySalesFrom || "",
            monthlySalesTo: queryParams.monthlySalesTo || "",
            totalResultsFrom: queryParams.totalResultsFrom || "",
            totalResultsTo: queryParams.totalResultsTo || "",
            sellerReviewsFrom: queryParams.sellerReviewsFrom || "",
            sellerReviewsTo: queryParams.sellerReviewsTo || "",
            productPriceFrom: queryParams.productPriceFrom || "",
            productPriceTo: queryParams.productPriceTo || "",
            competition: queryParams.competition || "",
            sorting: queryParams.sorting || "",
            sortEvent: queryParams.sortEvent || "",
            page: queryParams.page || 1,
        });
    }, [location.search]);

    useEffect(() => {
        // scroll to bottom on results change
        const queryParams = queryString.parse(location.search);

        // if any query param is set, then load ajax results
        if (queryParams.keyword || queryParams.keywords || queryParams.category || queryParams.keywordSalesFrom || queryParams.keywordSalesTo || queryParams.monthlySalesFrom || queryParams.monthlySalesTo || queryParams.totalResultsFrom || queryParams.totalResultsTo || queryParams.sellerReviewsFrom || queryParams.sellerReviewsTo || queryParams.productPriceFrom || queryParams.productPriceTo || queryParams.competition || queryParams.sorting || queryParams.sortEvent || queryParams.page) {
            const stateFromUrl = {
                keyword: queryParams.keyword || "",
                keywords: queryParams.keywords || "",
                category: queryParams.category || "",
                keywordSalesFrom: queryParams.keywordSalesFrom || "",
                keywordSalesTo: queryParams.keywordSalesTo || "",
                monthlySalesFrom: queryParams.monthlySalesFrom || "",
                monthlySalesTo: queryParams.monthlySalesTo || "",
                totalResultsFrom: queryParams.totalResultsFrom || "",
                totalResultsTo: queryParams.totalResultsTo || "",
                sellerReviewsFrom: queryParams.sellerReviewsFrom || "",
                sellerReviewsTo: queryParams.sellerReviewsTo || "",
                productPriceFrom: queryParams.productPriceFrom || "",
                productPriceTo: queryParams.productPriceTo || "",
                competition: queryParams.competition || "",
                sorting: queryParams.sorting || "",
                sortEvent: queryParams.sortEvent || "",
                page: queryParams.page || 1,
            };

            setFormData(stateFromUrl)

            apiCall(stateFromUrl);
        }
    }, []);

    async function apiCall(updatedFormData) {
        setLoading(true);

        const likes = await axiosApi.get(apiUrl + '/api/likes/user/keyword').then((response) => {
            let keyword_likes = []
            response.data.forEach((row) => {
                keyword_likes.push(row.keyword.keyword)
            });
            return keyword_likes;
        }).catch((error) => {
            console.log(error)
            return [];
        });

        // wait until likes are loaded

        return axiosApi.post(apiUrl + '/api/keywords/search', updatedFormData).then((response) => {
            const rows = [];

            response.data.data.forEach((row) => {
                const dataRow = {
                    keyword: {
                        'keyword': row.keyword,
                        'counts': {
                            'star_seller': row.starSellerCount,
                            'products_with_sales_count': row.productsWithSalesCount,
                            'related_keywords': row.relatedKeywordsCount
                        },
                        'competition': row.competitionScore,
                    },
                    monthlySales: row.totalSales,
                    totalResults: row.totalResults,
                    searchVolume: row.searchVolume,
                    sellerReviews: {'min': row.sellerReviewsMin, 'max': row.sellerReviewsMax, 'avg': row.sellerReviewsAvg},
                    productPrices: {'min': row.productPricesMin, 'max': row.productPricesMax, 'avg': row.productPricesAvg},
                    actionItems: {
                        keyword: row.keyword,
                        id: row._id.$oid,
                        liked: likes.includes(row.keyword),
                    }
                };
                rows.push(dataRow)
            });

            const columns = [
                {Header: 'Keyword', accessor: 'keyword', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'20px'} fontWeight={'bold'} width={'250px'}>
                                {row.value['keyword']}
                            </SoftTypography>

                            <SoftBox component="div" w="100%" py={1} mb={0.5}>
                                <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                    <SoftTypography variant="caption" fontWeight="medium" color="secondary">
                                        Competition:
                                    </SoftTypography>
                                    <SoftTypography variant="caption" fontWeight="bold" color="text" sx={{marginLeft: '3px'}}>
                                        {
                                            row.value.competition > 80  ? (
                                                <SoftTypography variant="caption" fontWeight="bold" color="error">
                                                    Very High
                                                </SoftTypography>
                                            ) : row.value.competition > 60 ? (
                                                <SoftTypography variant="caption" fontWeight="bold" color="warning">
                                                    High
                                                </SoftTypography>
                                            ) : row.value.competition > 40 ? (
                                                <SoftTypography variant="caption" fontWeight="bold" color="info">
                                                    Medium
                                                </SoftTypography>
                                            ) : row.value.competition > 20 ? (
                                                <SoftTypography variant="caption" fontWeight="bold" color="success">
                                                    Low
                                                </SoftTypography>
                                            ) : (
                                                <SoftTypography variant="caption" fontWeight="bold" color="success">
                                                    Very Low
                                                </SoftTypography>
                                            )
                                        }
                                    </SoftTypography>
                                </SoftBox>
                                {row.value.competition > 80  ? (
                                    <SoftProgress variant="gradient" color="error" value={row.value.competition} />
                                ) : row.value.competition > 60 ? (
                                    <SoftProgress variant="gradient" color="warning" value={row.value.competition} />
                                ) : row.value.competition > 40 ? (
                                    <SoftProgress variant="gradient" color="info" value={row.value.competition} />
                                ) : row.value.competition > 20 ? (
                                    <SoftProgress variant="gradient" color="success" value={row.value.competition} />
                                ) : (
                                    <SoftProgress variant="gradient" color="success" value={row.value.competition} />
                                )}
                            </SoftBox>
                        </SoftBox>
                    ) }
                },

                {Header: 'Monthly Sales', accessor: 'monthlySales', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value && row.value.toLocaleString()}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Total Results', accessor: 'totalResults', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value && row.value.toLocaleString()}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },

                {Header: 'Google Search Volume', accessor: 'searchVolume', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value && row.value.toLocaleString()}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },

                {Header: 'Seller Reviews', accessor: 'sellerReviews', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'}>
                                Min: <b>{row.value && row.value.min && row.value.min.toLocaleString()}</b>
                            </SoftTypography>
                            <SoftTypography fontSize={'16px'}>
                                Max: <b>{row.value && row.value.max && row.value.max.toLocaleString()}</b>
                            </SoftTypography>
                            <SoftTypography fontSize={'16px'}>
                                Avg: <b>{row.value && row.value.avg && Math.round(row.value.avg).toLocaleString()}</b>
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Product Prices', accessor: 'productPrices', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'}>
                                Min: $ <b>{row.value && row.value.min}</b>
                            </SoftTypography>
                            <SoftTypography fontSize={'16px'}>
                                Max: $ <b>{row.value && row.value.max}</b>
                            </SoftTypography>
                            <SoftTypography fontSize={'16px'}>
                                Avg: $ <b>{row.value && row.value.avg && row.value.avg.toFixed(2)}</b>
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },

                {Header: 'Actions', accessor: 'actionItems', table: true, Cell: (row) => { return (
                        <ActionButtons
                            href={'/analytics/keywords?keyword=' + row.value.keyword}
                            type={'keyword'}
                            isLiked={row.value.liked}
                            identifier={row.value.keyword}
                            handleLikeChange={handleLikeChange}
                        />
                ) }
                },
            ];

            setMetaResults({totalResults: response.data.meta.totalResults});

            const result = {
                "rows": rows,
                "columns": columns
            };
            setResults(result);

            setLoading(false);


        }).catch((error) => {
            alert(error)
            setLoading(false)
        });
    }

    const handleLikeChange = (identifier) => {
    }

    const handleSubmit = (event) => {
        const page = 1;
        const sorting = '';
        const sortEvent = '';
        const updatedFormData = { ...formData, page, sorting, sortEvent};
        setFormData({...formData, page, sorting, sortEvent});

        const stringifiedParams = queryString.stringify(updatedFormData);
        const newLocation = `${location.pathname}?${stringifiedParams}`;

        navigate(newLocation);

        apiCall(updatedFormData);
    };

    useEffect(() => {
        // scroll to bottom on results change
        if (results) {
            window.scrollTo(0,document.body.scrollHeight + 1000);
        }
    }, [results]);

    const handleFormData = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handlePageChange = (newPage) => {
        newPage = newPage + 1;
        const updatedFormData = {
            ...formData,
            page: newPage
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    };

    const handleSorting = (sorting) => {
        if (formData.sorting === sorting.value) {
            return
        }

        const updatedFormData = {
            ...formData,
            sorting: sorting.value,
            sortEvent: sorting,
            page: 1,
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    }

  return (
    <DashboardLayout>
    <Header/>
      <SoftBox py={3}>
          <Container>
              <KeywordFormExpert formHandler={handleFormData} submitHandler={handleSubmit} formData={formData} />
              {loading && (
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                      <PuffLoader
                          color={'#17c1e8'}
                          loading={loading}
                          size={80}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                      />

                  </div>
              )}
              <div ref={tableRef}>
              {!loading && results &&
                  <Table
                    key={formData.page}
                    tableData={results}
                    meta={metaResults}
                    page={formData.page}
                    onPageChange={handlePageChange}
                    sortHandler={handleSorting}
                    formData={formData}
                  />}
              </div>
          </Container>
      </SoftBox>
    </DashboardLayout>
  );
}

export default KeywordResearchTool;
