import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";
import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
        <GoogleOAuthProvider clientId="954376738840-75rma649o89sh33ispi8irvmkdoj9nqq.apps.googleusercontent.com">
            <App />
        </GoogleOAuthProvider>
    </SoftUIControllerProvider>
  </BrowserRouter>
);
