import PropTypes from "prop-types";
import SoftButton from "../../SoftButton";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {axiosApi} from "../../../services/api";
import {useState} from "react";
import AnalyticsIcon from "../../../assets/Icons/Analytics";

const apiUrl = process.env.REACT_APP_API_URL;

function ActionButtonsExplorer({href, id, liked, type, identifier }) {
    const [likedState, setLikedState] = useState(null);

    useState(() => {
        setLikedState(liked);
    }, []);

    const handleLike = () => {
        axiosApi.post(apiUrl + '/api/likes/toggle', {
            identifier: identifier,
            type: type,
        }).then((response) => {
            setLikedState(!likedState);
        })
    }

    return (
        <div>
            <SoftButton
                fullWidth={true}
                id={'like-button' + id}
                size={'large'}
                style={{padding: 0, margin: 0}}
                onClick={handleLike}
                onMouseEnter={() => {
                    const current_button = document.getElementById('like-button' + id);
                    current_button.style.transition = 'background-color 0.3s ease-out';
                    current_button.style.backgroundColor = 'rgba(23,193,232,0.08)';
                }}
                onMouseLeave={() => {
                    const current_button = document.getElementById('like-button' + id);
                    current_button.style.transition = 'background-color 0.3s ease-out';
                    current_button.style.backgroundColor = '#ffffff';
                }}
            >
                {likedState ? <AiFillHeart size={25} color={'#17c1e8'}/> : <AiOutlineHeart size={25} color={'#17c1e8'}/>}
            </SoftButton>

            <SoftButton
                fullWidth={true}
                id={'scan-button' + id}
                size={'large'}
                style={{padding: 0, margin: 0}}
                href={href}

                onMouseEnter={() => {
                    const current_button = document.getElementById('scan-button' + id);
                    current_button.style.transition = 'background-color 0.3s ease-out';
                    current_button.style.backgroundColor = 'rgba(23,193,232,0.08)';
                }}
                onMouseLeave={() => {
                    const current_button = document.getElementById('scan-button' + id);
                    current_button.style.transition = 'background-color 0.3s ease-out';
                    current_button.style.backgroundColor = '#ffffff';
                }}
            >
                <AnalyticsIcon size="40px"/>
            </SoftButton>
        </div>
    )
}

// Setting default values for the props of ActionButton
ActionButtonsExplorer.defaultProps = {
    liked: false,
};

// Typechecking props of the ActionButton
ActionButtonsExplorer.propTypes = {
    href: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    liked: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
};

export default ActionButtonsExplorer;
