/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useMemo, useEffect, useState, useRef} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
import {PuffLoader} from "react-spinners";
import ActionButtons from "../../../../components/Etsellify/ActionButtons";

function DataTable({
                       onPageChange,
                       entriesPerPage,
                       canSearch,
                       showTotalEntries,
                       table,
                       pagination,
                       isSorted,
                       noEndBorder,
                       likedItems
                   }) {
    const [fixed, setFixed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [likedItemsState, setLikedItemsState] = useState(likedItems);

    const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
    const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);

    const tableInstance = useTable(
        { columns, data, initialState: { pageIndex: 0 } },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = tableInstance;


    const goToNextPage = () => {
        setIsLoading(true);
        // window.scrollY(0);
        // scroll y to up about 500 px
        // window.scrollTo(0, 0);

        nextPage();
        setIsLoading(false);
    }

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 20), [defaultValue]);

    // Set the entries per page value based on the select value
    const setEntriesPerPage = ({ value }) => setPageSize(value);

    // Render the paginations
    const renderPagination = pageOptions.map((option) => (
        <SoftPagination
            item
            key={option}
            onClick={() => gotoPage(Number(option))}
            active={pageIndex === option}
        >
            {option + 1}
        </SoftPagination>
    ));

    // Handler for the input to set the pagination index
    const handleInputPagination = ({ target: { value } }) =>
        value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

    // Customized page options starting from 1
    const customizedPageOptions = pageOptions.map((option) => option + 1);

    // Setting value for the pagination input
    const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

    // Search input value state
    const [search, setSearch] = useState(globalFilter);

    // Search input state handle
    const onSearchChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 100);

    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }

        return sortedValue;
    };

    // Setting the entries starting point
    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

    // Setting the entries ending point
    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }

    const tableRef = useRef(null);

    useEffect(() => {
        if (tableRef && tableRef.current) {
            const element = tableRef.current;
            const { top, left, width, height } = element.getBoundingClientRect();
        }
    }, []);

    const handleLikeChange = (identifier) => {
        console.log("TRIGGERRED CHANGE")
        if (likedItems.includes(identifier)) {
            const index = likedItems.indexOf(identifier);
            if (index > -1) {
                likedItems.splice(index, 1);
            }
        } else {
            likedItems.push(identifier);
        }

        setLikedItemsState(likedItems);
    }

    return (
        <>
            {isLoading && (
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <PuffLoader
                        color={'#f9d423'}
                        loading={isLoading}
                        size={80}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            )}
            {!isLoading && (
                <TableContainer sx={{boxShadow: "none"}}
                                style={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
                                ref={tableRef}
                >
                    <Table {...getTableProps()} >
                        <SoftBox component="thead"
                                 sx={{
                                     display: "table-header-group",
                                     top: 0,
                                     zIndex: 1,
                                     backgroundColor: '#fff',
                                     position: "sticky",
                                 }}
                        >
                            {headerGroups.map((headerGroup, key) => (
                                <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, key) => {
                                            if (column.table) {
                                                return (
                                                    <DataTableHeadCell
                                                        key={key}
                                                        {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                                                        width={column.width ? column.width : "auto"}
                                                        align={column.align ? column.align : "left"}
                                                        sorted={setSortedValue(column)}
                                                    >
                                                        {column.render("Header")}
                                                    </DataTableHeadCell>
                                                )
                                            }
                                        }
                                    )}
                                </TableRow>
                            ))}
                        </SoftBox>

                        <TableBody {...getTableBodyProps()}>
                            {page.map((row, key) => {
                                prepareRow(row);

                                return (
                                    <TableRow key={key} {...row.getRowProps()}>
                                        {row.cells.map((cell, key) => {
                                            if (cell.column.actions) {
                                                return (
                                                    <DataTableBodyCell
                                                        sx={{display: 'flex', flexDirection: 'row'}}
                                                        key={key}
                                                        cellKey={key}
                                                        noBorder={noEndBorder && rows.length - 1 === key}
                                                        align={cell.column.align ? cell.column.align : "left"}
                                                        {...cell.getCellProps()}
                                                    >
                                                        <ActionButtons
                                                            href={row.values.actionItems.href}
                                                            type={row.values.actionItems.type}
                                                            identifier={row.values.actionItems.identifier}
                                                            isLiked={likedItemsState.includes(row.values.actionItems.identifier)}
                                                            handleLikeChange={handleLikeChange}
                                                        />
                                                    </DataTableBodyCell>
                                                )
                                            } else if (cell.column.table) {
                                                return (
                                                    <DataTableBodyCell
                                                        sx={{display: 'flex', flexDirection: 'row'}}
                                                        key={key}
                                                        cellKey={key}
                                                        noBorder={noEndBorder && rows.length - 1 === key}
                                                        align={cell.column.align ? cell.column.align : "left"}
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render("Cell")}
                                                    </DataTableBodyCell>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <SoftBox
                        display="flex"
                        flexDirection={{ xs: "column", sm: "row" }}
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
                    >
                        {showTotalEntries && (
                            <SoftBox mb={{ xs: 3, sm: 0 }}>
                                <SoftTypography variant="button" color="secondary" fontWeight="regular">
                                    Showing {entriesStart} to {entriesEnd} of {rows.length} entries
                                </SoftTypography>
                            </SoftBox>
                        )}
                        {pageOptions.length > 1 && (
                            <SoftPagination
                                variant={pagination.variant ? pagination.variant : "gradient"}
                                color={pagination.color ? pagination.color : "info"}
                            >
                                {canPreviousPage && (
                                    <SoftPagination item onClick={() => previousPage()}>
                                        <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                                    </SoftPagination>
                                )}
                                {renderPagination.length > 6 ? (
                                    <SoftBox width="5rem" mx={1}>
                                        <SoftInput
                                            inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                                            value={customizedPageOptions[pageIndex]}
                                            onChange={(handleInputPagination, handleInputPaginationValue)}
                                        />
                                    </SoftBox>
                                ) : (
                                    renderPagination
                                )}
                                {canNextPage && (
                                    <SoftPagination item onClick={() => goToNextPage()}>
                                        <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                                    </SoftPagination>
                                )}
                            </SoftPagination>
                        )}
                    </SoftBox>
                </TableContainer>
            )}
        </>
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 200, entries: [100, 200] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: true,
    noEndBorder: false,
    likedItems: [],
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
    onPageChange: PropTypes.func,
    likedItems: PropTypes.array,
};

export default DataTable;
