import {useCallback, useEffect, useState} from "react";
import Container from "@mui/material/Container";
import SoftBox from "components/SoftBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Header from "../research/Header";
import Grid from "@mui/material/Grid";
import ProductCard from "./productCard";
import {axiosApi} from "../../services/api";
import {PuffLoader} from "react-spinners";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;

function Explorer() {
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [likedProducts, setLikedProducts] = useState([]);

    const loadProducts = useCallback(() => {
        if (loading) return;

        setLoading(true);
        axiosApi.get(apiUrl + `/api/products/explorer`)
            .then((response) => {
                setProducts(prevProducts => [...prevProducts, ...response.data]);
                setPage(prevPage => prevPage + 1);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [page, loading]);

    const handleScroll = useCallback(() => {
        if (loading) return;

        if (
            window.innerHeight + document.documentElement.scrollTop + 50 >= document.documentElement.offsetHeight
        ) {
            loadProducts();
        }
    }, [loading, loadProducts]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        setLoading(true);
        // fetch data from API
        axiosApi.get(apiUrl + '/api/products/explorer').then((response) => {
            setProducts(response.data);

            let likedProducts = []
            const productLikesResponse = axiosApi.get(apiUrl + "/api/likes/user/product").then(productResponse => {
                likedProducts.push(productResponse.data.product?.listingId);
            });
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        });

    }, []);

    return (
        <DashboardLayout>
            <Header/>

            <Container>

                <SoftBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }} sx={{ marginTop: '20px' }}>
                    <Grid container spacing={3}>
                        {products.map((product, index) => (
                            <Grid item xs={12} key={index}>
                                <ProductCard
                                    product={product}
                                    liked={likedProducts.includes(product.product?.listingId)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </SoftBox>

                {loading && (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <PuffLoader
                            color={'#17c1e8'}
                            loading={loading}
                            size={100}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                )}

            </Container>
        </DashboardLayout>
    );
}

export default Explorer;
