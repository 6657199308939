import {useEffect, useRef, useState} from "react";

import React from "react";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Container from "@mui/material/Container";
import SoftTypography from "../../../components/SoftTypography";
import Table from "./table";
import {PuffLoader} from "react-spinners";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import SellerForm from "./sellerForm";
import Header from "../Header";
import ActionButtons from "../../../components/Etsellify/ActionButtons";
import {axiosApi} from "../../../services/api";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;


function SellerResearchTool() {
    const tableRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const [formData, setFormData] = useState({
        category: queryParams.category || "",
        monthlySalesFrom: queryParams.monthlySalesFrom || "",
        monthlySalesTo: queryParams.monthlySalesTo || "",
        monthlyReviewsFrom: queryParams.monthlyReviewsFrom || "",
        monthlyReviewsTo: queryParams.monthlyReviewsTo || "",
        monthlyRevenueFrom: queryParams.monthlyRevenueFrom || "",
        monthlyRevenueTo: queryParams.monthlyRevenueTo || "",
        admirersFrom: queryParams.admirersFrom || "",
        admirersTo: queryParams.admirersTo || "",
        totalSalesFrom: queryParams.totalSalesFrom || "",
        totalSalesTo: queryParams.totalSalesTo || "",
        totalReviewsFrom: queryParams.totalReviewsFrom || "",
        totalReviewsTo: queryParams.totalReviewsTo || "",
        location: queryParams.location || "",
        joinedSinceFrom: queryParams.joinedSinceFrom || "",
        joinedSinceTo: queryParams.joinedSinceTo || "",
        sorting: queryParams.sorting || "",
        sortEvent: queryParams.sortEvent || "",
        page: queryParams.page || 1,
    });
    const [metaResults, setMetaResults] = useState();

    useEffect(() => {
        const queryParams = queryString.parse(location.search);

        setFormData({
            category: queryParams.category || "",
            monthlySalesFrom: queryParams.monthlySalesFrom || "",
            monthlySalesTo: queryParams.monthlySalesTo || "",
            monthlyReviewsFrom: queryParams.monthlyReviewsFrom || "",
            monthlyReviewsTo: queryParams.monthlyReviewsTo || "",
            monthlyRevenueFrom: queryParams.monthlyRevenueFrom || "",
            monthlyRevenueTo: queryParams.monthlyRevenueTo || "",
            admirersFrom: queryParams.admirersFrom || "",
            admirersTo: queryParams.admirersTo || "",
            totalSalesFrom: queryParams.totalSalesFrom || "",
            totalSalesTo: queryParams.totalSalesTo || "",
            totalReviewsFrom: queryParams.totalReviewsFrom || "",
            totalReviewsTo: queryParams.totalReviewsTo || "",
            location: queryParams.location || "",
            joinedSinceFrom: queryParams.joinedSinceFrom || "",
            joinedSinceTo: queryParams.joinedSinceTo || "",
            sorting: queryParams.sorting || "",
            sortEvent: queryParams.sortEvent || "",
            page: queryParams.page || 1,
        });
    }, [location.search]);

    useEffect(() => {
        // scroll to bottom on results change
        const queryParams = queryString.parse(location.search);

        // if any query param is set, then load ajax results
        if (queryParams.sorting || queryParams.sortEvent || queryParams.page || queryParams.category || queryParams.monthlySalesFrom || queryParams.monthlySalesTo || queryParams.monthlyReviewsFrom || queryParams.monthlyReviewsTo || queryParams.monthlyRevenueFrom || queryParams.monthlyRevenueTo || queryParams.admirersFrom || queryParams.admirersTo || queryParams.totalSalesFrom || queryParams.totalSalesTo || queryParams.totalReviewsFrom || queryParams.totalReviewsTo || queryParams.location || queryParams.joinedSinceFrom || queryParams.joinedSinceTo) {
            const stateFromUrl = {
                category: queryParams.category || "",
                monthlySalesFrom: queryParams.monthlySalesFrom || "",
                monthlySalesTo: queryParams.monthlySalesTo || "",
                monthlyReviewsFrom: queryParams.monthlyReviewsFrom || "",
                monthlyReviewsTo: queryParams.monthlyReviewsTo || "",
                monthlyRevenueFrom: queryParams.monthlyRevenueFrom || "",
                monthlyRevenueTo: queryParams.monthlyRevenueTo || "",
                admirersFrom: queryParams.admirersFrom || "",
                admirersTo: queryParams.admirersTo || "",
                totalSalesFrom: queryParams.totalSalesFrom || "",
                totalSalesTo: queryParams.totalSalesTo || "",
                totalReviewsFrom: queryParams.totalReviewsFrom || "",
                totalReviewsTo: queryParams.totalReviewsTo || "",
                location: queryParams.location || "",
                joinedSinceFrom: queryParams.joinedSinceFrom || "",
                joinedSinceTo: queryParams.joinedSinceTo || "",
                sorting: queryParams.sorting || "",
                sortEvent: queryParams.sortEvent || "",
                page: queryParams.page || 1,
            };

            setFormData(stateFromUrl)

            apiCall(stateFromUrl);
        }
    }, []);


    async function apiCall(updatedFormData) {
        setLoading(true);

        const likes = await axiosApi.get(apiUrl + '/api/likes/user/seller').then((response) => {
            let sellerLikes = []
            response.data.forEach((row) => {
                sellerLikes.push(row.seller.name)
            });
            return sellerLikes;
        }).catch((error) => {
            console.log(error)
            return [];
        });


        return axiosApi.post(apiUrl + '/api/sellers/search', updatedFormData).then((response) => {
            const rows = [];

            response.data.data.forEach((row) => {
                const dataRow = {
                    name: {
                        name: row.name,
                        joinedSince: row.joinedSince,
                        location: row.location,
                    },
                    monthlySales: row.monthlySales,
                    // monthlyRevenue: row.monthlyRevenue,
                    monthlyReviews: row.monthlyReviews,
                    totalSales: row.totalSales,
                    totalReviews: row.allReviews,
                    admirers: row.admirers,
                    actions: {
                        name: row.name,
                        id: row.id,
                        liked: likes.includes(row.name),
                    }
                };
                rows.push(dataRow)
            });

            const columns = [
                {Header: 'Seller',  accessor: 'name', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'20px'} fontWeight={'bold'} width={'250px'}>
                                {row.value['name']}
                            </SoftTypography>

                            <SoftTypography fontSize={'14px'}>
                                Location: <b>{row.value['location']}</b>
                            </SoftTypography>

                            <SoftTypography fontSize={'14px'}>
                                Joined Since: <b>{row.value['joinedSince']}</b>
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Monthly Sales', width: '120px', accessor: 'monthlySales', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Monthly Reviews', width: '120px', accessor: 'monthlyReviews', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },

                {Header: 'Total Sales', accessor: 'totalSales', width: '30px', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Total Reviews', accessor: 'totalReviews', width: '30px', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Admirers', accessor: 'admirers', width: '30px', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Actions', accessor: 'actions', table: true, Cell: (row) => { return (
                        <ActionButtons
                            isLiked={row.value.liked}
                            href={'/analytics/sellers?name=' + row.value.name}
                            type={'seller'}
                            identifier={row.value.name}
                        />
                    )}
                },
            ];

            setMetaResults({totalResults: response.data.meta.totalResults});

            const result = {
                "rows": rows,
                "columns": columns
            };
            setResults(result);

        }).catch((error) => {
            alert(error)
        }).finally(() => {
            setLoading(false)
        });
    }

    const handleSubmit = (event) => {
        const page = 1;
        const sorting = '';
        const sortEvent = '';
        const updatedFormData = { ...formData, page, sorting, sortEvent};
        setFormData({...formData, page, sorting, sortEvent});

        const stringifiedParams = queryString.stringify(formData);
        const newLocation = `${location.pathname}?${stringifiedParams}`;

        navigate(newLocation);

        apiCall(updatedFormData);
    };

    useEffect(() => {
        // scroll to bottom on results change
        if (results) {
            window.scrollTo(0,document.body.scrollHeight + 1000);
        }
    }, [results]);

    const handleFormData = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handlePageChange = (newPage) => {
        newPage = newPage + 1;
        const updatedFormData = {
            ...formData,
            page: newPage
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    };

    const handleSorting = (sorting) => {
        if (formData.sorting === sorting.value) {
            return
        }

        const updatedFormData = {
            ...formData,
            sorting: sorting.value,
            sortEvent: sorting,
            page: 1,
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    }

  return (
    <DashboardLayout>
      <Header/>
      <SoftBox py={3}>
          <Container>
              <SellerForm formHandler={handleFormData} submitHandler={handleSubmit} formData={formData} />
              {loading && (
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                      <PuffLoader
                          color={'#17c1e8'}
                          loading={loading}
                          size={60}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                      />
                  </div>
              )}
              <div ref={tableRef}>
              {!loading && results &&
                  <Table
                    key={formData.page}
                    tableData={results}
                    meta={metaResults}
                    page={formData.page}
                    onPageChange={handlePageChange}
                    sortHandler={handleSorting}
                    formData={formData}
                  />
              }
              </div>
          </Container>
      </SoftBox>
    </DashboardLayout>
  );
}

export default SellerResearchTool;
