import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import {useEffect, useMemo, useState} from "react";
import Grid from "@mui/material/Grid";
import MiniStatisticsCard from "../../../examples/Cards/StatisticsCards/MiniStatisticsCard";
import OutlinedCounterCard from "../../../examples/Cards/CounterCards/OutlinedCounterCard";
import DefaultLineChart from "../../../examples/Charts/LineCharts/DefaultLineChart";
import CategoryList from "./CategoryList";
import ApexTreeChart from "./ApexTreeChart";


function MetaTableView({metaResults}) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, [metaResults]);


    return (
        <Card sx={{ height: "100%" }}>

            <SoftBox mb={3}>
                <Grid container spacing={3}>

                    {!loading && (
                        <>
                            <Grid item xs={12} lg={12}>
                                <SoftTypography style={{ textTransform: 'capitalize' }} color={'dark'} variant="h4" fontWeight="bold" textAlign={'center'}>
                                    {metaResults.name}
                                </SoftTypography>

                                <SoftTypography style={{ textTransform: 'capitalize' }} color={'secondary'} variant="h6" textAlign={'center'}>
                                    {metaResults.location}
                                </SoftTypography>
                                <SoftTypography style={{ textTransform: 'capitalize' }} color={'secondary'} variant="h6" textAlign={'center'}>
                                    Joined Since: {metaResults.joined}
                                </SoftTypography>

                                <SoftTypography style={{ textTransform: 'capitalize' }} color={'dark'} variant="h6" textAlign={'center'}>
                                    {metaResults.headline}
                                </SoftTypography>
                            </Grid>

                            <Grid item xs={12} lg={12} flexDirection={'row'}>
                                <SoftBox display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                    <Grid item xs={6} lg={6}>
                                        <OutlinedCounterCard prefix={'$'} count={metaResults.totalRevenue} title="Total Revenue" />
                                    </Grid>

                                    <Grid item xs={6} lg={6}>
                                        <OutlinedCounterCard count={metaResults.totalSales} title="Total Sales" />
                                    </Grid>

                                    <Grid item xs={6} lg={6}>
                                        <OutlinedCounterCard count={metaResults.totalReviews} title="Total Reviews" />
                                    </Grid>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} lg={12} flexDirection={'row'}>
                                <SoftBox display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>

                                    <Grid item xs={3} md={3} lg={4}>
                                        <MiniStatisticsCard
                                            title={{ fontWeight: "medium", text: "Monthly Revenue" }}
                                            count={'$ ' + metaResults.monthlyRevenue.toLocaleString()}
                                            icon={{ color: "dark", component: "local_atm" }}
                                            direction="left"
                                        />
                                    </Grid>

                                    <Grid item xs={3} md={3} lg={4}>
                                        <MiniStatisticsCard
                                            title={{ fontWeight: "medium", text: "Monthly Sales" }}
                                            count={metaResults.monthlySales.toLocaleString()}
                                            // percentage={{ color: "success", text: "+55%" }}
                                            icon={{ color: "dark", component: "local_atm" }}
                                            direction="left"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <MiniStatisticsCard
                                            title={{ fontWeight: "medium", text: "Monthly Reviews" }}
                                            count={metaResults.monthlyReviews.toLocaleString()}
                                            icon={{ color: "dark", component: "local_atm" }}
                                            direction="left"
                                        />
                                    </Grid>

                                </SoftBox>
                            </Grid>

                            {metaResults.monthly_linechart && (
                                <Grid item xs={12} sm={12} lg={12}>
                                    <DefaultLineChart
                                        title="Monthly Sales History"
                                        description={
                                            <SoftBox display="flex" justifyContent="space-between">
                                            </SoftBox>
                                        }
                                        chart={metaResults.monthly_linechart}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={12} lg={12}>
                                <ApexTreeChart seriesSectionData={metaResults.keywordTree} />
                            </Grid>

                            {/*{metaResults.categoriesListData && (*/}
                            {/*    <Grid item xs={12} lg={12}>*/}
                            {/*        <SoftBox mb={3}>*/}
                            {/*            <CategoryList title="categories" categories={metaResults.categoriesListData} />*/}
                            {/*        </SoftBox>*/}
                            {/*    </Grid>*/}
                            {/*)}*/}

                            {/*{metaResults.keywordListData && (*/}
                            {/*    <Grid item xs={12} lg={12}>*/}
                            {/*        <SoftBox mb={3}>*/}
                            {/*            <CategoryList title="Popular Seller Keywords" categories={metaResults.keywordListData} />*/}
                            {/*        </SoftBox>*/}
                            {/*    </Grid>*/}
                            {/*)}*/}

                            {/*<Grid item xs={12} lg={6} flexDirection={'row'}>*/}
                            {/*    <SoftBox mb={3}>*/}
                            {/*        <Card>*/}
                            {/*            <SoftBox p={2}>*/}
                            {/*                <SoftBox display="flex" alignItems="center">*/}
                            {/*                    <Icon*/}
                            {/*                        sx={{*/}
                            {/*                            backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>*/}
                            {/*                                gradients["dark"]*/}
                            {/*                                    ? linearGradient(gradients["dark"].main, gradients["dark"].state)*/}
                            {/*                                    : linearGradient(gradients.dark.main, gradients.dark.state),*/}
                            {/*                            WebkitBackgroundClip: "text",*/}
                            {/*                            WebkitTextFillColor: "transparent",*/}
                            {/*                        }}*/}
                            {/*                    >*/}
                            {/*                        account_circle*/}
                            {/*                    </Icon>*/}

                            {/*                    <SoftBox ml={1} lineHeight={0}>*/}
                            {/*                        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">*/}
                            {/*                            Ranking Keywords*/}
                            {/*                        </SoftTypography>*/}
                            {/*                    </SoftBox>*/}
                            {/*                </SoftBox>*/}

                            {/*                <SoftBox>*/}
                            {/*                    <SoftTypography component="span" variant="body2" fontWeight="bold" color="text">*/}
                            {/*                        Total Keyword Count:&nbsp;*/}
                            {/*                    </SoftTypography>*/}
                            {/*                    <SoftTypography component="span" variant="body2" color="text">*/}
                            {/*                        {metaResults.totalKeywordCount}*/}
                            {/*                    </SoftTypography>*/}
                            {/*                    <br/>*/}

                            {/*                    <SoftTypography component="span" variant="body2" fontWeight="bold" color="text">*/}
                            {/*                        Organic Keyword Count:&nbsp;*/}
                            {/*                    </SoftTypography>*/}
                            {/*                    <SoftTypography component="span" variant="body2" color="text">*/}
                            {/*                        {metaResults.organicKeywordCount}*/}
                            {/*                    </SoftTypography>*/}
                            {/*                    <br/>*/}

                            {/*                    <SoftTypography component="span" variant="body2" fontWeight="bold" color="text">*/}
                            {/*                        Sponsored Keyword Count:&nbsp;*/}
                            {/*                    </SoftTypography>*/}
                            {/*                    <SoftTypography component="span" variant="body2" color="text">*/}
                            {/*                        {metaResults.sponsoredKeywordCount}*/}
                            {/*                    </SoftTypography>*/}
                            {/*                </SoftBox>*/}
                            {/*                <Divider />*/}

                            {/*            </SoftBox>*/}
                            {/*        </Card>*/}
                            {/*    </SoftBox>*/}
                            {/*</Grid>*/}
                        </>
                    )}

                </Grid>
            </SoftBox>

        </Card>
    );
}

// Typechecking props for the DataTableBodyCell
MetaTableView.propTypes = {
    metaResults: PropTypes.object.isRequired
};


export default MetaTableView;
