import {useEffect, useRef, useState} from "react";

import React from "react";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Container from "@mui/material/Container";
import SoftTypography from "../../../components/SoftTypography";
import Table from "./table";
import {PuffLoader} from "react-spinners";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import ProductForm from "./productForm";
import Header from "../Header";
import ActionButtons from "../../../components/Etsellify/ActionButtons";
import {axiosApi} from "../../../services/api";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;


function ProductResearchTool() {
    const tableRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const [formData, setFormData] = useState({
        title: queryParams.title || "",
        category: queryParams.category || "",
        monthlySalesFrom: queryParams.monthlySalesFrom || "",
        monthlySalesTo: queryParams.monthlySalesTo || "",
        monthlyReviewsFrom: queryParams.monthlyReviewsFrom || "",
        monthlyReviewsTo: queryParams.monthlyReviewsTo || "",
        monthlyRevenueFrom: queryParams.monthlyRevenueFrom || "",
        monthlyRevenueTo: queryParams.monthlyRevenueTo || "",
        sellerReviewsFrom: queryParams.sellerReviewsFrom || "",
        sellerReviewsTo: queryParams.sellerReviewsTo || "",
        priceFrom: queryParams.priceFrom || "",
        priceTo: queryParams.priceTo || "",
        sorting: queryParams.sorting || "",
        sortEvent: queryParams.sortEvent || "",
        page: queryParams.page || 1,
        // keywordAvgCompetitionScore
        // keywordCount
    });
    const [metaResults, setMetaResults] = useState();

    useEffect(() => {
        const queryParams = queryString.parse(location.search);

        setFormData({
            title: queryParams.title || "",
            category: queryParams.category || "",
            monthlySalesFrom: queryParams.monthlySalesFrom || "",
            monthlySalesTo: queryParams.monthlySalesTo || "",
            monthlyRevenueFrom: queryParams.monthlyRevenueFrom || "",
            monthlyRevenueTo: queryParams.monthlyRevenueTo || "",
            monthlyReviewsFrom: queryParams.monthlyReviewsFrom || "",
            monthlyReviewsTo: queryParams.monthlyReviewsTo || "",
            sellerReviewsFrom: queryParams.sellerReviewsFrom || "",
            sellerReviewsTo: queryParams.sellerReviewsTo || "",
            priceFrom: queryParams.priceFrom || "",
            priceTo: queryParams.priceTo || "",
            sorting: queryParams.sorting || "",
            sortEvent: queryParams.sortEvent || "",
            page: queryParams.page || 1,
            // keywordAvgCompetitionScore
            // keywordCount
        });
    }, [location.search]);

    useEffect(() => {
        // scroll to bottom on results change
        const queryParams = queryString.parse(location.search);

        // if any query param is set, then load ajax results
        if (queryParams.title || queryParams.category || queryParams.monthlySalesFrom || queryParams.monthlySalesTo || queryParams.monthlyRevenueFrom || queryParams.monthlyRevenueTo || queryParams.monthlyReviewsFrom || queryParams.monthlyReviewsTo || queryParams.sellerReviewsFrom || queryParams.sellerReviewsTo || queryParams.priceFrom || queryParams.priceTo || queryParams.sorting || queryParams.sortEvent || queryParams.page) {
            const stateFromUrl = {
                title: queryParams.title || "",
                category: queryParams.category || "",
                monthlySalesFrom: queryParams.monthlySalesFrom || "",
                monthlySalesTo: queryParams.monthlySalesTo || "",
                monthlyRevenueFrom: queryParams.monthlyRevenueFrom || "",
                monthlyRevenueTo: queryParams.monthlyRevenueTo || "",
                monthlyReviewsFrom: queryParams.monthlyReviewsFrom || "",
                monthlyReviewsTo: queryParams.monthlyReviewsTo || "",
                sellerReviewsFrom: queryParams.sellerReviewsFrom || "",
                sellerReviewsTo: queryParams.sellerReviewsTo || "",
                priceFrom: queryParams.priceFrom || "",
                priceTo: queryParams.priceTo || "",
                sorting: queryParams.sorting || "",
                sortEvent: queryParams.sortEvent || "",
                page: queryParams.page || 1,
                // keywordAvgCompetitionScore
                // keywordCount
            };

            setFormData(stateFromUrl)

            apiCall(stateFromUrl);
        }
    }, []);


    async function apiCall(updatedFormData) {
        setLoading(true);

        const likes = await axiosApi.get(apiUrl + '/api/likes/user/product').then((response) => {
            let productLikes = []
            response.data.forEach((row) => {
                productLikes.push(row.product.listingId)
            });
            return productLikes;
        }).catch((error) => {
            console.log(error)
            return [];
        });

        return axiosApi.post(apiUrl + '/api/products/search', updatedFormData).then((response) => {
            const rows = [];

            response.data.data.forEach((row) => {
                const dataRow = {
                    title: row.title,
                    imageUrl: row.imageUrl,
                    monthlySales: row.monthlySales,
                    monthlyRevenue: row.monthlyRevenue,
                    monthlyReviews: row.monthlyReviews,
                    sellerReviews: row.seller.reviews,
                    price: row.price,
                    listingId: row.listingId,
                    actionItems: {
                        listingId: row.listingId,
                        id: row.id,
                        liked: likes.includes(row.listingId),
                    }
                };
                rows.push(dataRow)
            });

            const columns = [
                {Header: 'Image', accessor: 'imageUrl', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <img src={row.value} alt={row.value} width={120} />
                        </SoftBox>
                    ) }
                },
                {Header: 'Title', accessor: 'title', table: true, Cell: (row) => { return (
                        <p style={{width: '250px'}}>
                            {row.value}
                        </p>
                    ) }
                },
                {Header: 'Monthly Sales', width: '120px', accessor: 'monthlySales', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value ? row.value.toLocaleString() : '-'}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Monthly Reviews', width: '120px', accessor: 'monthlyReviews', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value ? row.value.toLocaleString() : '-'}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Monthly Revenue', width: '30px', accessor: 'monthlyRevenue', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value ? '$' + row.value.toLocaleString() : '-'}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },
                {Header: 'Price', accessor: 'price', width: '30px', table: true, Cell: (row) => { return (
                        <SoftBox my={1}>
                            <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                                {row.value ? '$' + row.value.toLocaleString() : '-'}
                            </SoftTypography>
                        </SoftBox>
                    ) }
                },

                {Header: 'Actions', accessor: 'actionItems', table: true, Cell: (row) => { return (
                        <ActionButtons
                            href={'/analytics/products?listingId=' + row.value.listingId}
                            isLiked={row.value.liked}
                            type={'product'}
                            identifier={row.value.listingId}
                        />
                    )}
                },
            ];

            setMetaResults({totalResults: response.data.meta.totalResults});

            const result = {
                "rows": rows,
                "columns": columns
            };
            setResults(result);

            setLoading(false);


        }).catch((error) => {
            alert(error)
            setLoading(false)
        });
    }

    const handleSubmit = (event) => {
        const page = 1;
        const sorting = '';
        const sortEvent = '';
        const updatedFormData = { ...formData, page, sorting, sortEvent};
        setFormData({...formData, page, sorting, sortEvent});

        const stringifiedParams = queryString.stringify(updatedFormData);
        const newLocation = `${location.pathname}?${stringifiedParams}`;

        navigate(newLocation);

        apiCall(updatedFormData);
    };

    useEffect(() => {
        // scroll to bottom on results change
        if (results) {
            window.scrollTo(0,document.body.scrollHeight + 1000);
        }
    }, [results]);

    const handleFormData = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handlePageChange = (newPage) => {
        newPage = newPage + 1;
        const updatedFormData = {
            ...formData,
            page: newPage
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    };

    const handleSorting = (sorting) => {
        if (formData.sorting === sorting.value) {
            return
        }

        const updatedFormData = {
            ...formData,
            sorting: sorting.value,
            sortEvent: sorting,
            page: 1,
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    }

  return (
    <DashboardLayout>
        <Header/>
      <SoftBox py={3}>
          <Container>
              <ProductForm formHandler={handleFormData} submitHandler={handleSubmit} formData={formData} />
              {loading && (
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                      <PuffLoader
                          color={'#17c1e8'}
                          loading={loading}
                          size={60}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                      />

                  </div>
              )}
              <div ref={tableRef}>
              {!loading && results &&
                  <Table
                    key={formData.page}
                    tableData={results}
                    meta={metaResults}
                    page={formData.page}
                    onPageChange={handlePageChange}
                    sortHandler={handleSorting}
                    formData={formData}
                  />}
              </div>
          </Container>
      </SoftBox>
    </DashboardLayout>
  );
}

export default ProductResearchTool;
