import {Link, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Icon from "@mui/material/Icon";


function EventCard({title, action, seller }) {

    const color = 'dark';
    const navigate = useNavigate();

    return (
        <Card>
            <SoftBox p={2}>
                <SoftBox display="flex" alignItems="center">
                    {/*<SoftAvatar src={hs} alt={title} size="lg" variant="rounded" />*/}

                    <Icon
                        sx={{
                            backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                                gradients[color]
                                    ? linearGradient(gradients[color].main, gradients[color].state)
                                    : linearGradient(gradients.dark.main, gradients.dark.state),
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        account_circle
                    </Icon>

                    <SoftBox ml={1} lineHeight={0}>
                        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                            {title}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>

                {seller ? (
                    <>
                    <SoftBox>
                        <SoftTypography component="span" variant="body2" fontWeight="bold" color="text">
                            Name:&nbsp;
                        </SoftTypography>
                        <SoftTypography component="span" variant="body2" color="text">
                            {seller.name}
                        </SoftTypography>
                    </SoftBox>

                    <SoftBox>
                        <SoftTypography component="span" variant="body2" fontWeight="bold" color="text">
                            Total Reviews:&nbsp;
                        </SoftTypography>
                        <SoftTypography component="span" variant="body2" color="text">
                            {seller.reviews}
                        </SoftTypography>
                    </SoftBox>
                    </>
                ) : null}
                <Divider />
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    {action.type === "internal" ? (
                        <SoftButton
                            component={Link}
                            // to={{
                            //     pathname: action.route,
                            //     state: { name: seller.name }
                            // }}
                            to={{
                                pathname: action.route.split('?')[0],
                                search: action.route.split('?')[1],
                                state: { name: seller.name }
                            }}
                            variant="gradient"
                            color={action.color}
                            size="small"
                        >
                            {action.label}
                        </SoftButton>
                    ) : (
                        <>
                        </>
                        // <SoftButton
                        //     component="a"
                        //     href={action.route}
                        //     variant="gradient"
                        //     color={action.color}
                        //     size="small"
                        // >
                        //     {action.label}
                        // </SoftButton>
                    )}
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

// Setting default values for the props of EventCard
EventCard.defaultProps = {
    id: "",
    dateTime: "",
    members: [],
};

// Typechecking props for the EventCard
EventCard.propTypes = {
    title: PropTypes.string,
    dateTime: PropTypes.string,
    action: PropTypes.shape({
        type: PropTypes.oneOf(["enternal", "internal"]).isRequired,
        route: PropTypes.string.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]).isRequired,
        label: PropTypes.string.isRequired,
    }),
    seller: PropTypes.object
};

export default EventCard;
