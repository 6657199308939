/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function WorkerCard({ color, state, icon, title, description, onChange, metadata, logs, logDate, queue, last_batch_task_statuses }) {
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox
        p={3}
        height="100%"
        bgColor={state ? color : "white"}
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
          <SoftBox
              display="flex"
              flexDirection="row"
          >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          mr={1}
          lineHeight={1}
        >
          <SoftTypography variant="body2" color={state ? "white" : "text"} mr={1}>
            {state ? "On" : "Off"}
          </SoftTypography>
          <SoftBox mr={1}>
            <Switch checked={state} onChange={onChange} />
          </SoftBox>
        </SoftBox>

        <SoftBox mt={1} mr={1} lineHeight={1}>
          <SoftTypography
            variant="body2"
            color={state ? "white" : "text"}
            textTransform="capitalize"
            fontWeight="medium"
          >
            {title}
          </SoftTypography>

            <SoftTypography variant="caption" color={state ? "white" : "text"}>
                {queue}
            </SoftTypography>


          {description ? (
            <SoftTypography variant="caption" color={state ? "white" : "text"}>
              {description}
            </SoftTypography>
          ) : null}

            <SoftBox display="flex" flexDirection="row" width={'200px'}>
                {metadata ? (
                    <SoftTypography variant="caption" color={state ? "white" : "text"}>
                        {Object.keys(metadata).map((key) => (
                            <div key={key}>
                                <span><strong>{key}: </strong></span>
                                <span>{metadata[key]}</span>
                            </div>
                        ))}

                    </SoftTypography>
                ) : null}
            </SoftBox>

        </SoftBox>

          <SoftBox mt={1} lineHeight={1}>

                <SoftTypography variant="caption" color={state ? "white" : "text"}>
                    {logDate && (
                        new Date(logDate).toDateString() + ' ' + new Date(logDate).toLocaleTimeString()
                    )}
                </SoftTypography>


                <SoftBox height="200px" overflow="scroll" bgColor={"rgba(248,249,250,0.16)"} borderRadius={'10px 10px 10px 10px'}>
                    <SoftTypography variant="caption" color={state ? "white" : "text"} sx={{ "whiteSpace": "pre-wrap"}}>
                        {logs.split('\n').reverse().join('\n')}
                    </SoftTypography>
                </SoftBox>


          </SoftBox>
          </SoftBox>


          <SoftBox
              p={3}
              height="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
          >
              {last_batch_task_statuses.map((status, idx) => (
                  //DRAW A CIRCLE WITH COLOR BASED ON STATUS
                  // On hover also show message


                    // <SoftBox
                    //     key={idx}
                    //     width="20px"
                    //     height="20px"
                    //     borderRadius="10%"
                    //     bgColor={status.status === 'success' ? 'success' : status.status === 'error' ? 'error' : 'warning'}
                    //     mr={1}
                    // />

                  <SoftBox
                      key={idx}
                      width="20px"
                      height="20px"
                      borderRadius="10%"
                      bgColor={status.status === 'success' ? 'success' : status.status === 'error' ? 'error' : 'warning'}
                      mr={1}
                      sx={{
                          position: 'relative',
                          cursor: 'pointer',
                          '&::after': {
                              content: `"${status.message}"`,
                              position: 'absolute',
                              bottom: '100%',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              backgroundColor: 'black',
                              color: 'white',
                              padding: '0.5rem',
                              borderRadius: '0.25rem',
                              whiteSpace: 'nowrap',
                              opacity: '0',
                              transition: 'opacity 0.3s ease-in-out',
                              pointerEvents: 'none',
                          },
                          '&:hover::after': {
                              opacity: '1',
                          },
                      }}
                  />


              ))}

          </SoftBox>

      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of WorkerCard
WorkerCard.defaultProps = {
  color: "info",
  state: false,
  description: "",
};

// Typechecking props for the WorkerCard
WorkerCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  state: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  metadata: PropTypes.object,
  logs: PropTypes.string,
  logDate: PropTypes.string,
  queue: PropTypes.string,
  last_batch_task_statuses: PropTypes.array,
};

export default WorkerCard;
