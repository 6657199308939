import PropTypes from "prop-types";
import SoftButton from "../../SoftButton";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {axiosApi} from "../../../services/api";
import {useEffect, useState} from "react";
import AnalyticsIcon from "../../../assets/Icons/Analytics";

const apiUrl = process.env.REACT_APP_API_URL;

function ActionButtons({href, type, identifier, isLiked, handleLikeChange }) {
    const [likedState, setLikedState] = useState(false);

    const handleLikedItems = () => {
        handleLikeChange(identifier);
        setLikedState(!likedState);
    };

    useEffect(() => {
        setLikedState(isLiked);
    }, [isLiked]);

    const handleLike = () => {
        axiosApi.post(apiUrl + '/api/likes/toggle', {
            identifier: identifier,
            type: type,
        }).then((response) => {
            handleLikedItems();
        })
    }

    // generate unique id
    const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    return (
        <div>
            <SoftButton
                id={'like-button-' + id}
                size={'large'}
                style={{padding: 0, margin: 0}}
                onClick={handleLike}
                onMouseEnter={(event) => {
                    const currentTr = event.target.closest('tr');
                    if (currentTr) {
                        // Apply styles to the current row
                        currentTr.style.transition = 'background-color 0.3s ease-out';
                        currentTr.style.backgroundColor = 'rgba(23,193,232,0.08)';

                        // If you also want to apply styles to a specific button within this row
                        const currentButton = currentTr.querySelector(`#like-button-${id}`);
                        if (currentButton) {
                            currentButton.style.transition = 'background-color 0.3s ease-out';
                            currentButton.style.backgroundColor = 'rgba(23,193,232,0.08)';
                        }
                    }

                }}
                onMouseLeave={(event) => {
                    const currentTr = event.target.closest('tr');
                    if (currentTr) {
                        currentTr.style.transition = 'background-color 0.3s ease-out';
                        currentTr.style.backgroundColor = '#ffffff';

                        const currentButton = currentTr.querySelector(`#like-button-${id}`);
                        if (currentButton) {
                            currentButton.style.transition = 'background-color 0.3s ease-out';
                            currentButton.style.backgroundColor = '#ffffff';
                        }
                    }
                }}
            >
                {likedState ? <AiFillHeart size={20} color={'#17c1e8'}/> : <AiOutlineHeart size={20} color={'#17c1e8'}/>}
            </SoftButton>

            <SoftButton
                id={'scan-button' + id}
                size={'large'}
                style={{padding: 0, margin: 0, marginLeft: '10px'}}
                href={href}

                onMouseEnter={(event) => {
                    const currentTr = event.target.closest('tr');
                    if (currentTr) {
                        // Apply styles to the current row
                        currentTr.style.transition = 'background-color 0.3s ease-out';
                        currentTr.style.backgroundColor = 'rgba(23,193,232,0.08)';

                        // If you also want to apply styles to a specific button within this row
                        const currentButton = currentTr.querySelector(`#scan-button${id}`);
                        if (currentButton) {
                            currentButton.style.transition = 'background-color 0.3s ease-out';
                            currentButton.style.backgroundColor = 'rgba(23,193,232,0.08)';
                        }
                    }
                }}
                onMouseLeave={(event) => {
                    const currentTr = event.target.closest('tr');
                    if (currentTr) {
                        currentTr.style.transition = 'background-color 0.3s ease-out';
                        currentTr.style.backgroundColor = '#ffffff';

                        const currentButton = currentTr.querySelector(`#scan-button${id}`);
                        if (currentButton) {
                            currentButton.style.transition = 'background-color 0.3s ease-out';
                            currentButton.style.backgroundColor = '#ffffff';
                        }
                    }
                }}
            >
                <AnalyticsIcon size={'20px'}/>
            </SoftButton>
        </div>
    )
}

ActionButtons.defaultProps = {
    isLiked: false,
    handleLikeChange: () => {

    },
}

// Typechecking props of the ActionButton
ActionButtons.propTypes = {
    href: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    isLiked: PropTypes.bool,
    handleLikeChange: PropTypes.func,
};

export default ActionButtons;
