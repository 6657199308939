import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Header from "./components/header";
import {axiosApi} from "../../../services/api";
import {useEffect, useState} from "react";
import SoftProgress from "../../../components/SoftProgress";
import SoftButton from "../../../components/SoftButton";
import SoftBadge from "../../../components/SoftBadge";
import Invoice from "../../pages/account/billing/components/Invoice";
import Icon from "@mui/material/Icon";
import SoftSnackbar from "../../../components/SoftSnackbar";
import {PuffLoader} from "react-spinners";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;


function UserProfile() {
    const [user, setUser] = useState({});
    const [userFeatures, setUserFeatures] = useState([]);
    const [daysLeft, setDaysLeft] = useState(null);
    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const closeSuccessSB = () => setSuccessSB(false);
    const closeErrorSB = () => setErrorSB(false);

    const features = {
        'free': [
            { label: "Keyword Research", includes: false },
            { label: "Product Research", includes: false },
            { label: "Seller Research", includes: false },
            { label: "Keyword Scanner", includes: false },
            { label: "Product Scanner", includes: false },
            { label: "Seller Scanner", includes: false },
            { label: "Chrome Extension", includes: false },
        ],
        'basic': [
            { label: "Keyword Research", includes: true },
            { label: "Product Research", includes: true },
            { label: "Seller Research", includes: true },
            { label: "Keyword Scanner", includes: false },
            { label: "Product Scanner", includes: false },
            { label: "Seller Scanner", includes: false },
            { label: "Chrome Extension", includes: true },
        ],
        'platinum': [
            { label: "Keyword Research", includes: true },
            { label: "Product Research", includes: true },
            { label: "Seller Research", includes: true },
            { label: "Keyword Scanner", includes: true },
            { label: "Product Scanner", includes: true },
            { label: "Seller Scanner", includes: true },
            { label: "Chrome Extension", includes: true },
        ],
    }

    useEffect(() => {
        axiosApi.get(apiUrl + '/api/users/me').then(response => {
            setUser(response.data);

            const planData = response.data.planData;
            let plan_code = 'free'

            if (planData) {
                plan_code = planData.name.toLowerCase();

                const now = new Date();
                const timestamp_diff = planData.period_end_ts - now.getTime() / 1000
                const countDaysLeft = Math.floor(timestamp_diff / (60 * 60 * 24));
                if (countDaysLeft > 0) {
                    setDaysLeft(countDaysLeft);
                }
            }

            setUserFeatures(features[plan_code]);


        }).finally(() => {
            setLoading(false);
        });

        axiosApi.get(apiUrl + '/api/users/me/invoices').then(response => {
            setInvoices(response.data);
        });
    }, []);

    const renderSuccessSB = (
        <SoftSnackbar
            color="info"
            icon="check"
            title="Cancelled Subscription"
            content="Successfully cancelled your subscription."
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="error"
            title="Error while cancelling subscription"
            content={'Ups! Something went wrong. Please contact support for help'}
            open={errorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
            bgWhite
        />
    );

    async function handleCheckout() {
        await axiosApi.post(apiUrl + '/api/payments/stripe/create-checkout-session', {'price_id': 'price_1O0qcCLu4Jlfu3IkmiVuLUmO'}).then(response => {
            window.location.href = response.data.url;
        })
    }

    async function handleCancel() {
        await axiosApi.post(apiUrl + '/api/payments/stripe/cancel-subscription').then(response => {
            setUser(response.data.user);
            setSuccessSB(true);
        }).catch(error => {
            console.log(error);
            setErrorMessage(error.response.data);
            setErrorSB(true);
        })
    }

    return (
        <DashboardLayout>
            {!loading && (
                <>

                    <Header name={user.name} email={user.email}/>
                    <SoftBox mt={5} mb={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={7} xl={7}>
                                {renderSuccessSB}
                                {renderErrorSB}

                                {!user.planData && (
                                    <Card>
                                        <SoftBox pt={2} px={2}>
                                            <SoftTypography variant="h4" fontWeight="medium">
                                                Free
                                                <SoftBadge
                                                    px={2}
                                                    style={{'marginLeft': '10px'}}
                                                    variant="contained"
                                                    color={'info'}
                                                    size="sm"
                                                    badgeContent={'Free'}
                                                    circular
                                                    container
                                                />
                                            </SoftTypography>
                                        </SoftBox>

                                        {userFeatures && userFeatures.map((feature, index) => (
                                            <SoftBox pt={2} px={2} key={feature.label + index} display="flex" alignItems="center">
                                                <SoftBox
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width="1.5rem"
                                                    height="1.5rem"
                                                    borderRadius="50%"
                                                    shaodw="md"
                                                    bgColor={feature.includes ? "success" : "secondary"}
                                                    variant="gradient"
                                                    mr={2}
                                                >
                                                    <SoftTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
                                                        <Icon sx={{ fontWeight: "bold" }}>{feature.includes ? 'done': 'remove'}</Icon>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftTypography variant="body2" color="text">
                                                    {feature.label}
                                                </SoftTypography>
                                            </SoftBox>
                                        ))}

                                        {/*TODO:: ENABLE LATER*/}
                                        {/*<SoftBox pb={2} px={2} display="flex" flexDirection={{ xs: "column", sm: "row" }} mt="auto">*/}
                                        {/*    <SoftBox width={{ xs: "100%", sm: "60%" }} lineHeight={1} pt={2}>*/}
                                        {/*        <SoftTypography variant="button" color="text" fontWeight="regular"  >*/}
                                        {/*            <strong>Order</strong> plan to enable features!*/}
                                        {/*        </SoftTypography>*/}
                                        {/*    </SoftBox>*/}
                                        {/*    <SoftBox width={{ xs: "100%", sm: "60%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }}>*/}
                                        {/*        <SoftButton variant="gradient" color="dark" onClick={handleCheckout}>*/}
                                        {/*            Get Plan Now*/}
                                        {/*        </SoftButton>*/}
                                        {/*    </SoftBox>*/}
                                        {/*</SoftBox>*/}
                                    </Card>
                                )}

                                {user.planData && (
                                    <Card>
                                        <SoftBox pt={2} px={2}>
                                            <SoftTypography variant="h4" fontWeight="medium">
                                                {user.planData?.name}

                                                <SoftBadge
                                                    px={2}
                                                    style={{'marginLeft': '10px'}}
                                                    variant="contained"
                                                    color={user.activePayments ? 'success' : 'error'}
                                                    size="sm"
                                                    badgeContent={user.activePayments ? 'Active' : 'Cancelled'}
                                                    circular
                                                    container
                                                />
                                            </SoftTypography>

                                        </SoftBox>

                                        {userFeatures && userFeatures.map((feature, index) => (
                                            <SoftBox pt={2} px={2}  key={feature.label + index} display="flex" alignItems="center">
                                                <SoftBox
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width="1.5rem"
                                                    height="1.5rem"
                                                    borderRadius="50%"
                                                    shaodw="md"
                                                    bgColor={feature.includes ? "success" : "secondary"}
                                                    variant="gradient"
                                                    mr={2}
                                                >
                                                    <SoftTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
                                                        <Icon sx={{ fontWeight: "bold" }}>{feature.includes ? 'done': 'remove'}</Icon>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftTypography variant="body2" color="text">
                                                    {feature.label}
                                                </SoftTypography>
                                            </SoftBox>
                                        ))}

                                        <SoftBox pt={3} pb={2} px={2}>
                                            <SoftBox my={1}>
                                                <SoftTypography variant="h4">
                                                    <SoftTypography display="inline" component="small" variant="h4">
                                                        $
                                                    </SoftTypography>
                                                    {user.planData.price / 100}
                                                </SoftTypography>

                                            </SoftBox>
                                        </SoftBox>

                                        {daysLeft && (
                                            <SoftBox pt={2} px={2}>
                                                <SoftBox
                                                    component="ul"
                                                    display="flex"
                                                    flexDirection="column"
                                                    p={0}
                                                    m={0}
                                                    sx={{ listStyle: "none" }}
                                                >
                                                    <SoftTypography display="inline" component="small" variant="h6" color="secondary">
                                                        {user.planData && (
                                                            <span> {new Date(user.planData.period_end_ts * 1000).toLocaleDateString()}</span>
                                                        )}
                                                    </SoftTypography>

                                                    <SoftBox component="li" w="100%" py={1} mb={0.5}>
                                                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                                            <SoftTypography
                                                                variant="button"
                                                                fontWeight="medium"
                                                                color="text"
                                                                textTransform="capitalize"
                                                            >
                                                                {user.activePayments ? 'Usage Renews In' : 'Usage Left'}
                                                            </SoftTypography>
                                                            <SoftTypography
                                                                variant="button"
                                                                fontWeight="medium"
                                                                color="text"
                                                                textTransform="capitalize"
                                                            >
                                                                {daysLeft} days
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftProgress
                                                            variant="gradient"
                                                            value={100 - (daysLeft / 30 * 100)}
                                                        />
                                                    </SoftBox>
                                                </SoftBox>
                                            </SoftBox>
                                        )}


                                        <SoftBox pb={2} px={2} display="flex" flexDirection={{ xs: "column", sm: "row" }} mt="auto">
                                            <SoftBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
                                                {user.planData?.name === 'Basic' && (
                                                    <SoftTypography variant="button" color="text" fontWeight="regular">
                                                        <strong>Upgrade</strong> your plan for more features!
                                                    </SoftTypography>
                                                )}
                                            </SoftBox>
                                            <SoftBox width={{ xs: "100%", sm: "60%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }}>
                                                {user.activePayments && (
                                                    <SoftButton variant="outlined" color="error" onClick={handleCancel}>
                                                        Cancel Plan
                                                    </SoftButton>
                                                )}
                                                {user.planData?.name === 'Basic' && (
                                                    <SoftButton
                                                        variant="gradient"
                                                        color="dark"
                                                        onClick={
                                                            () => {
                                                                // redirect to subscription page
                                                                window.location.href = '/subscription';
                                                            }
                                                        }>
                                                        Upgrade Plan
                                                    </SoftButton>
                                                )}
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                )}

                            </Grid>



                            <Grid item xs={12} xl={5}>
                                <Card sx={{ height: "100%" }}>
                                    <SoftBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                                        <SoftTypography variant="h6" fontWeight="medium">
                                            Invoices
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox p={2}>
                                        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                                            {invoices.map((invoice, index) => (
                                                <Invoice
                                                    key={index}
                                                    date={new Date(invoice.invoicePaymentSucceeded.status_transitions.paid_at * 1000).toLocaleDateString()}
                                                    id={'#'+invoice.invoicePaymentSucceeded.number}
                                                    price={'$'+invoice.invoicePaymentSucceeded.amount_paid / 100}
                                                    pdf_url={invoice.invoicePaymentSucceeded.invoice_pdf}
                                                />
                                            ))}
                                        </SoftBox>
                                    </SoftBox>
                                </Card>
                            </Grid>
                        </Grid>

                    </SoftBox>
                </>
            )}
            {loading && (
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <PuffLoader
                        color={'#17c1e8'}
                        loading={loading}
                        // cssOverride={override}
                        size={60}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />

                </div>
            )}
        </DashboardLayout>
    );
}

export default UserProfile;
