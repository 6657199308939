import {useMemo, useEffect} from "react";

import PropTypes from "prop-types";
import {useTable, usePagination, useGlobalFilter} from "react-table";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftPagination from "components/SoftPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import SoftSelect from "../../../../components/SoftSelect";

function DataTable({
        pageIndex,
        onPageChange,
        totalResults,
        sortHandler,
        formData,
        entriesPerPage,
        showTotalEntries,
        table,
        pagination,
        noEndBorder,
    }) {

    const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 20;
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);

    const tableInstance = useTable(
        {columns, data},
        useGlobalFilter,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        state: {pageSize},
    } = tableInstance;

    const gotoPage = (page) => {
        onPageChange(page);
    }

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 20), [defaultValue]);

    // Setting the entries ending point
    let entriesEnd;

    // Setting the entries starting point
    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

    entriesEnd = Math.min(entriesStart + pageSize - 1, totalResults);


    const pageOptions = useMemo(() => {
        const options = [];

        for (let i = 0; i < Math.ceil(totalResults / pageSize); i += 1) {
            options.push(i);
        }

        return options;
    }, [totalResults, pageSize]);

    let canNextPage = pageIndex < pageOptions.length - 1;
    if (page.length <= 19) {
        canNextPage = false;
    }

    const canPreviousPage = pageIndex > 0;

    //render pagination just for closest 5 pages
    const renderPagination = pageOptions.map((option) => {
        // if (option === 0 || option === pageOptions.length - 1 || (option >= pageIndex - 2 && option <= pageIndex + 2)) {
        // if (option === 0 || (option >= pageIndex - 1 && option <= pageIndex + 1)) {
        if (option === 0 || ((page.length > 19) && (option >= pageIndex - 1 && option <= pageIndex + 1))) {
            return (
                <SoftPagination
                    item
                    key={option}
                    onClick={() => gotoPage(Number(option))}
                    active={pageIndex === option}
                >
                    {option + 1}
                </SoftPagination>
            )
        }
    });

    const handleNextPage = () => {
        pageIndex = pageIndex + 1;
        onPageChange(pageIndex);
    }

    const handlePreviousPage = () => {
        pageIndex = pageIndex - 1;
        onPageChange(pageIndex);
    }

    const handleSelectChange = (sorting) => {
        sortHandler(sorting);
    }

    return (
        <TableContainer sx={{boxShadow: "none"}}
                        style={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
        >

            {entriesPerPage ? (
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>

                    <SoftBox width="18rem" ml="auto" style={{zIndex: 5}}>
                        <SoftTypography  color="textSecondary">
                            Sellers Table
                        </SoftTypography>
                    </SoftBox>
                        <SoftBox width="18rem" ml="auto" style={{zIndex: 5}}>
                            <SoftSelect
                                        style={{zIndex: 5}}
                                        onChange={(e) => handleSelectChange(e)}
                                        defaultValue={formData.sortEvent}
                                        placeholder="Sort By"
                                        options={
                                            [
                                                { value: '', label: 'Default'}, // option to clear selection
                                                { value: '+monthlySales', label: 'Monthly Sales Asc' },
                                                { value: '-monthlySales', label: 'Monthly Sales Desc' },
                                                { value: '+monthlyRevenue', label: 'Monthly Revenue Asc' },
                                                { value: '-monthlyRevenue', label: 'Monthly Revenue Desc' },
                                                { value: '+price', label: 'Price Asc' },
                                                { value: '+price', label: 'Price Desc' },
                                                { value: '+title', label: 'Title Asc' },
                                                { value: '-title', label: 'Title Desc' },
                                            ]
                                        }
                            />
                        </SoftBox>

                </SoftBox>
            ) : null}
            <Table {...getTableProps()}>
                <SoftBox component="thead"
                         style={{ position: "sticky", top: 0, zIndex: 1, background: "white" }}
                >
                    {headerGroups.map((headerGroup, key) => (
                        <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, key) => (
                                <DataTableHeadCell
                                    key={key}
                                    {...
                                        column.getHeaderProps()
                                    }
                                    width={column.width ? column.width : "auto"}
                                    align={column.align ? column.align : "left"}
                                >
                                    {column.render("Header")}
                                </DataTableHeadCell>
                            ))}
                        </TableRow>
                    ))}
                </SoftBox>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow key={key} {...row.getRowProps()}>
                                {row.cells.map((cell, key) => (
                                    <DataTableBodyCell
                                        key={key}
                                        noBorder={noEndBorder && totalResults - 1 === key}
                                        align={cell.column.align ? cell.column.align : "left"}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <SoftBox
                display="flex"
                flexDirection={{xs: "column", sm: "row"}}
                justifyContent="space-between"
                alignItems={{xs: "flex-start", sm: "center"}}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            >
                {showTotalEntries && (
                    <SoftBox mb={{xs: 3, sm: 0}}>
                        <SoftTypography variant="button" color="secondary" fontWeight="regular">
                            {/*Showing {entriesStart} to {entriesEnd} of {totalResults}+ entries*/}
                        </SoftTypography>
                    </SoftBox>
                )}
                {pageOptions.length > 1 && (
                    <SoftPagination
                        variant={pagination.variant ? pagination.variant : "gradient"}
                        color={pagination.color ? pagination.color : "info"}
                    >
                        {canPreviousPage && (
                            <SoftPagination item onClick={() => handlePreviousPage()}>
                                <Icon sx={{fontWeight: "bold"}}>chevron_left</Icon>
                            </SoftPagination>
                        )}
                        {renderPagination}

                        {canNextPage && page.length > 19 && (
                            <SoftPagination item onClick={() => handleNextPage()}>
                                <Icon sx={{fontWeight: "bold"}}>chevron_right</Icon>
                            </SoftPagination>
                        )}
                    </SoftPagination>
                )}
            </SoftBox>
        </TableContainer>
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: {defaultValue: 20, entries: [20]},
    canSearch: false,
    showTotalEntries: true,
    pagination: {variant: "gradient", color: "info"},
    noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    sortHandler: PropTypes.func.isRequired,
    pageIndex: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    totalResults: PropTypes.number.isRequired,
    formData: PropTypes.object.isRequired,
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    noEndBorder: PropTypes.bool,
};

export default DataTable;
