import Card from "@mui/material/Card";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import PropTypes from "prop-types";
import DataTable from "./DataTable";


function Table({tableData, meta, onPageChange, page, sortHandler, formData}) {
    const handlePaginationClick = (pageNumber) => {
        onPageChange(pageNumber);
    };

    const handleSorting = (sort) => {
        sortHandler(sort);
    }

    return (
        <SoftBox mb={3} sx={{marginTop: "30px"}}>
            <Card>
                {/*<SoftBox p={3} lineHeight={1}>*/}
                    {/*<SoftTypography variant="h5" fontWeight="medium">*/}
                    {/*    Keyword Landscape*/}
                    {/*</SoftTypography>*/}

                    {/*<MetaTableView metaResults={metaResults} />*/}
                {/*</SoftBox>*/}
                <DataTable
                    table={tableData}
                    totalResults={meta.totalResults}
                    onPageChange={handlePaginationClick}
                    pageIndex={page - 1}
                    sortHandler={handleSorting}
                    formData={formData}
                />
            </Card>
        </SoftBox>
    );
}

// Typechecking props for the Header
Table.propTypes = {
    tableData: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    meta: PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired,
    sortHandler: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
};

export default Table;