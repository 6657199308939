import { useState } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "layouts/pages/account/components/FormField";
import PropTypes from "prop-types";
import SoftButton from "../../../components/SoftButton";
import Icon from "@mui/material/Icon";
import AnalyticsIcon from "../../../assets/Icons/Analytics";


function KeywordFormExpert({formHandler, submitHandler, formData}) {
    const handleSelectChange = (event) => {
        event = {target: {name: "competition", value: event.value}};
        formHandler(event);
    }
    return (
        <Card
            id="basic-info"
            sx={{
                backdropFilter: `saturate(200%) blur(30px)`,
                backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                position: "relative",
                mt: -15,
                mx: 3,
                py: 2,
                px: 2,
                overflow: "visible"
            }}
        >

            <AnalyticsIcon size={'50px'} customStyle={{position: 'absolute',
                top: '30px',
                left: '40px',
                width: '40px'}}/>


            <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
                <Grid item xs={10} lg={5}>
                    <SoftBox mb={1}>
                        <SoftTypography variant="h3" color="black" fontWeight="bold">
                            Product Analytics
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={2}>
                        <SoftTypography variant="body2" color="dark" fontWeight="regular">
                            Discover Product Metrics & KPIs
                        </SoftTypography>
                    </SoftBox>
                </Grid>
            </Grid>


            <SoftBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>

                    <Grid item xs={12} flexDirection={'row'}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={12} >
                                <SoftTypography fontSize={'23px'} fontWeight={"bold"} color={'dark'}>Listing ID</SoftTypography>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <FormField name="listingId" placeholder="Enter Listing ID" onChange={formHandler} value={formData.listingId} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <SoftBox mt={3}>
                    <SoftButton
                        variant="gradient"
                        color={'info'}
                        fullWidth
                        // size={'medium'}
                        onClick={submitHandler}
                        type="submit"
                    >
                        {'Submit'}&nbsp;
                        <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

// Typechecking props for the Header
KeywordFormExpert.propTypes = {
    formHandler: PropTypes.func.isRequired,
    submitHandler: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
};

export default KeywordFormExpert;
