import {useEffect, useRef, useState} from "react";

import React from "react";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Container from "@mui/material/Container";
import {PuffLoader} from "react-spinners";
import { useNavigate, useLocation } from "react-router-dom";
import {axiosApi} from "../../../../services/api";
import SoftTypography from "../../../../components/SoftTypography";
import SoftProgress from "../../../../components/SoftProgress";
import ActionButtons from "../../../../components/Etsellify/ActionButtons";
import queryString from "query-string";
import Table from "../table";
import Header from "../header";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;

function KeywordLikes() {
    const tableRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const [metaResults, setMetaResults] = useState(null);
    const [likes, setLikes] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const [formData, setFormData] = useState({
        sorting: queryParams.sorting || "",
        sortEvent: queryParams.sortEvent || "",
        page: queryParams.page || 1,
    });

    async function apiCall(updatedFormData) {
        setLoading(true);

        const response = await axiosApi.get(
            apiUrl + '/api/likes/user/keyword-full?page=' + updatedFormData.page + '&sorting=' + updatedFormData.sorting + '&sortEvent=' + updatedFormData.sortEvent
        ).then((response) => {
            return response.data;
        });

        const rows = [];

        response.data.forEach((row) => {
            const dataRow = {
                keyword: {
                    'keyword': row.keyword,
                    'competition': row.competitionScore,
                },
                monthlySales: row.totalSales,
                totalResults: row.totalResults,
                sellerReviews: {'min': row.sellerReviewsMin, 'max': row.sellerReviewsMax, 'avg': row.sellerReviewsAvg},
                productPrices: {'min': row.productPricesMin, 'max': row.productPricesMax, 'avg': row.productPricesAvg},
                actionItems: {
                    liked: true,
                    identifier: row.keyword,
                    type: 'keyword',
                    href: '/analytics/keywords?keyword=' + row.keyword,
                },
            };
            rows.push(dataRow)
        });

        const columns = [
            {Header: 'Keyword', accessor: 'keyword', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'20px'} fontWeight={'bold'} width={'250px'}>
                            {row.value['keyword']}
                        </SoftTypography>

                        <SoftBox component="div" w="100%" py={1} mb={0.5}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <SoftTypography variant="caption" fontWeight="medium" color="secondary">
                                    Competition:
                                </SoftTypography>
                                <SoftTypography variant="caption" fontWeight="bold" color="text" sx={{marginLeft: '3px'}}>
                                    {
                                        row.value.competition > 80  ? (
                                            <SoftTypography variant="caption" fontWeight="bold" color="error">
                                                Very High
                                            </SoftTypography>
                                        ) : row.value.competition > 60 ? (
                                            <SoftTypography variant="caption" fontWeight="bold" color="warning">
                                                High
                                            </SoftTypography>
                                        ) : row.value.competition > 40 ? (
                                            <SoftTypography variant="caption" fontWeight="bold" color="info">
                                                Medium
                                            </SoftTypography>
                                        ) : row.value.competition > 20 ? (
                                            <SoftTypography variant="caption" fontWeight="bold" color="success">
                                                Low
                                            </SoftTypography>
                                        ) : (
                                            <SoftTypography variant="caption" fontWeight="bold" color="success">
                                                Very Low
                                            </SoftTypography>
                                        )
                                    }
                                </SoftTypography>
                            </SoftBox>
                            {row.value.competition > 80  ? (
                                <SoftProgress variant="gradient" color="error" value={row.value.competition} />
                            ) : row.value.competition > 60 ? (
                                <SoftProgress variant="gradient" color="warning" value={row.value.competition} />
                            ) : row.value.competition > 40 ? (
                                <SoftProgress variant="gradient" color="info" value={row.value.competition} />
                            ) : row.value.competition > 20 ? (
                                <SoftProgress variant="gradient" color="success" value={row.value.competition} />
                            ) : (
                                <SoftProgress variant="gradient" color="success" value={row.value.competition} />
                            )}
                        </SoftBox>
                    </SoftBox>
                ) }
            },

            {Header: 'Monthly Sales', accessor: 'monthlySales', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                            {row.value && row.value.toLocaleString()}
                        </SoftTypography>
                    </SoftBox>
                ) }
            },
            {Header: 'Total Results', accessor: 'totalResults', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'} fontWeight={'bold'}>
                            {row.value && row.value.toLocaleString()}
                        </SoftTypography>
                    </SoftBox>
                ) }
            },
            {Header: 'Seller Reviews', accessor: 'sellerReviews', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'}>
                            Min: <b>{row.value && row.value.min && row.value.min.toLocaleString()}</b>
                        </SoftTypography>
                        <SoftTypography fontSize={'16px'}>
                            Max: <b>{row.value && row.value.max && row.value.max.toLocaleString()}</b>
                        </SoftTypography>
                        <SoftTypography fontSize={'16px'}>
                            Avg: <b>{row.value && row.value.avg && Math.round(row.value.avg).toLocaleString()}</b>
                        </SoftTypography>
                    </SoftBox>
                ) }
            },
            {Header: 'Product Prices', accessor: 'productPrices', table: true, Cell: (row) => { return (
                    <SoftBox my={1}>
                        <SoftTypography fontSize={'16px'}>
                            Min: $ <b>{row.value && row.value.min}</b>
                        </SoftTypography>
                        <SoftTypography fontSize={'16px'}>
                            Max: $ <b>{row.value && row.value.max}</b>
                        </SoftTypography>
                        <SoftTypography fontSize={'16px'}>
                            Avg: $ <b>{row.value && row.value.avg && row.value.avg.toFixed(2)}</b>
                        </SoftTypography>
                    </SoftBox>
                ) }
            },

            {Header: 'Actions', accessor: 'actionItems', table: true, Cell: (row) => { return (
                    <ActionButtons
                        href={'/analytics/keywords?keyword=' + row.value.identifier}
                        type={'keyword'}
                        isLiked={row.value.liked}
                        identifier={row.value.identifier}
                        handleLikeChange={handleLikeChange}
                    />
                ) }
            },
        ];

        setMetaResults({
            totalResults: response.meta.totalResults
        })

        const result = {
            "rows": rows,
            "columns": columns
        };
        setResults(result);

        setLoading(false);
    }

    const handleLikeChange = (identifier) => {
    }

    useEffect(() => {
        apiCall(formData)
    }, []);

    const handleSorting = (sorting) => {
        if (formData.sorting === sorting.value) {
            return
        }

        const updatedFormData = {
            ...formData,
            sorting: sorting.value,
            sortEvent: sorting,
            page: 1,
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    }

    const handlePageChange = (newPage) => {

        newPage = newPage + 1;
        console.log(newPage);

        const updatedFormData = {
            ...formData,
            page: newPage
        };

        setFormData(updatedFormData);
        apiCall(updatedFormData);
    };

    return (
        <DashboardLayout>
            <Header title={'Liked Keywords'}/>
            <SoftBox py={3}>
                <Container>
                    {loading && (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <PuffLoader
                                color={'#17c1e8'}
                                loading={loading}
                                size={80}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />

                        </div>
                    )}

                    <div ref={tableRef}>
                        {!loading && results &&
                            <Table
                                title={'Liked Keywords'}
                                key={formData.page}
                                tableData={results}
                                meta={metaResults}
                                page={formData.page}
                                onPageChange={handlePageChange}
                                totalResults={metaResults.totalResults}
                            />}
                    </div>
                </Container>
            </SoftBox>
        </DashboardLayout>
    );
}

export default KeywordLikes;
