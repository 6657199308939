import {useState, useEffect, useMemo, useContext} from "react";

// react-router components
import {Routes, Route, Navigate, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import {useSoftUIController, setMiniSidenav} from "context";

// Images
import brand from "assets/images/logo-ct.png";

import {library} from '@fortawesome/fontawesome-svg-core';
import {faCheckSquare, faCoffee, faHeart} from '@fortawesome/free-solid-svg-icons';
import Illustration from "./landing/signup";
import VerifyEmail from "./landing/verify";
import SignIn from "./landing/signin";
import {axiosApi} from "./services/api";
import HomeV2Dashboard from "./layouts/home2";

const apiUrl = process.env.REACT_APP_API_URL;


library.add(faCheckSquare, faCoffee, faHeart);

export default function App() {
    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, direction, layout, openConfigurator, sidenavColor} = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentUserRoles, setCurrentUserRoles] = useState([]);
    const [routesByRoles, setRoutesByRoles] = useState([]);
    const {pathname} = useLocation();

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const location = useLocation();

    const routeElement = (routeComponent) => {
        let token = localStorage.getItem('token');
        let tokenExpiration = localStorage.getItem('tokenExpiration');

        if (tokenExpiration) {
            tokenExpiration = parseInt(tokenExpiration);
        }

        if (token && tokenExpiration > (Date.now() / 1000)) {
            return routeComponent;
        } else {
            return <Navigate to={'/sign-in'} state={{from: location}} replace />;
        }
    }

    const roleHierarchy = {
        "ROLE_USER":                ["ROLE_USER"],
        "ROLE_BASIC":               ["ROLE_USER", "ROLE_BASIC"],
        "ROLE_PLATINUM":            ["ROLE_USER", "ROLE_BASIC", "ROLE_PLATINUM"],
        "ROLE_ULTRA":               ["ROLE_USER", "ROLE_BASIC", "ROLE_PLATINUM", "ROLE_ULTRA"],
        "ROLE_AFFILIATE":           ["ROLE_USER", "ROLE_AFFILIATE"],
        "ROLE_VIP_PARTNER":         ["ROLE_USER", "ROLE_BASIC", "ROLE_PLATINUM", "ROLE_ULTRA", "ROLE_AFFILIATE"],
        "ROLE_ADMIN":               ["ROLE_USER", "ROLE_BASIC", "ROLE_PLATINUM", "ROLE_ULTRA", "ROLE_AFFILIATE", "ROLE_ADMIN"],
    }

    const hasRoleAccess = (role_required, currentUserRolesList = null) => {
        let rolesList = currentUserRoles
        if (currentUserRolesList) {
            rolesList = currentUserRolesList;
        }

        for (const currentUserRole of rolesList) {
            if (roleHierarchy[currentUserRole]?.includes(role_required)) {
                return true;
            }
        }

        return false;
    }

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse && hasRoleAccess(route.role)) {
                return getRoutes(route.collapse);
            }

            if (route.route && hasRoleAccess(route.role)) {
                return <Route
                    exact
                    path={route.route}
                    key={route.key}
                    element={
                        routeElement(route.component)
                    }
                />;
            }

            return null;
        });

    useEffect(() => {
        axiosApi.get(apiUrl + '/api/users/me').then(response => {
            setCurrentUserRoles(response.data.roles)

            let routesModified = routes.filter(route => {
                return hasRoleAccess(route.role, response.data.roles)
            });

            setRoutesByRoles(routesModified);
            setLoading(false);
        }).catch(error => {
            console.log(error);

            if (error.response.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('tokenExpiration');

                setCurrentUserRoles([]);
            }

            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const current_url = window.location.href;
    const is_auth_url = current_url.includes('/sign-in') || current_url.includes('/sign-up') || current_url.includes('verify-email');

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>



            {!is_auth_url && !loading && (
                <>
                    <Sidenav
                        color={sidenavColor}
                        brand={brand}
                        brandName="ETSELLIFY"
                        routes={routesByRoles}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    />
                </>
            )}

            {!loading && currentUserRoles && (
                <Routes>
                    {getRoutes(routesByRoles)}
                    <Route path="/verify-email" element={<VerifyEmail/>} key={'verify-email'}/>
                    <Route path="/sign-up" element={<Illustration/>} key={'sign-up'}/>
                    <Route path="/sign-in" element={<SignIn/>} key={'sign-in'}/>

                    <Route exact path="/" element={routeElement(<HomeV2Dashboard/>)} />
                    {/*<Route path="*" element={routeElement(<Navigate to="/"/>)} /> TODO:: NOT FOUND*/}
                    <Route path="/" element={routeElement(<HomeV2Dashboard/>)} />
                </Routes>
            )}



        </ThemeProvider>
    );
}
