// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components

// ReportsBarChart configurations
import MiniStatisticsCard from "../../../../examples/Cards/StatisticsCards/MiniStatisticsCard";
import BarReportChartNoDiagram from "./BarReportChartNoDiagram";

function ReportChart({ color, title, description, items, topLabel, topValue }) {
    const renderItems = items.map(({ icon, label, progress }) => (
        <Grid item xs={6} sm={4} key={label}>
            <BarReportChartNoDiagram
                color={color}
                icon={{ color: icon.color, component: icon.component }}
                label={label}
                progress={{
                    content: progress.content,
                    percentage: progress.percentage
            }}
            />
        </Grid>
    ));

    return (
        <Card sx={{ height: "100%" }}>
            <SoftBox padding="1rem">

                <SoftBox mb={4}>
                    <MiniStatisticsCard
                        title={{ text: topLabel }}
                        count={topValue}
                        icon={{ color: "info", component: "paid" }}
                    />
                </SoftBox>

                <SoftBox px={1}>
                    <SoftBox mb={2}>
                        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                            {title}
                        </SoftTypography>
                        <SoftTypography component="div" variant="button" color="text" fontWeight="regular">
                            {description}
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox py={1} px={0.5}>
                        <Grid container spacing={2}>
                            {renderItems}
                        </Grid>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

// Setting default values for the props of ReportsBarChart
ReportChart.defaultProps = {
    color: "dark",
    description: "",
    items: [],
};

// Typechecking props for the ReportChart
ReportChart.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    items: PropTypes.arrayOf(PropTypes.object),
    topLabel: PropTypes.string,
    topValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ReportChart;
