import {useEffect, useRef, useState} from "react";
import React from "react";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Container from "@mui/material/Container";
import SoftTypography from "../../../components/SoftTypography";
import {PuffLoader} from "react-spinners";
import Card from "@mui/material/Card";
import Form from "./form";
import MetaTableView from "../../../app/pages/KeywordMarketshare/Table/MetaTableView";
import {useLocation} from 'react-router-dom';
import DataTable from "./DataTable";
import {Star, StarBorder} from "@mui/icons-material";
import Header from "../../research/Header";
import {axiosApi} from "../../../services/api";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;


function KeywordRadarTool() {
    const tableRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [productResults, setProductResults] = useState(null);
    const [sellerResults, setSellerResults] = useState(null);

    const [keywordLikes, setKeywordLikes] = useState([]);
    const [sellerLikes, setSellerLikes] = useState([]);
    const [productLikes, setProductLikes] = useState([]);

    const location = useLocation();
    const keyword = location.state?.keyword;

    const [formData, setFormData] = useState({
        keyword: keyword || '',
    });
    const [metaResults, setMetaResults] = useState();

    const [sellersAndProducts, setSellersAndProducts] = useState(null);
    const [productsForChart, setProductsForChart] = useState(null);
    const [treeChartData, setTreeChartData] = useState(null);

    const fetchData = async (formDataUpdated = null) => {
        try {
            setLoading(true);
            let body = formData;

            if (formDataUpdated) {
                setFormData(formDataUpdated);
                body = formDataUpdated;
            }

            const rows = [];

            const response = await axiosApi.post(apiUrl + "/api/keywords-marketshare/search", body);
            const keywords_light = await axiosApi.get(apiUrl + "/api/keywords/find", {params: {keyword: body['keyword']}});
            const keywords_full = await axiosApi.get(apiUrl + "/api/keywords/find-full", {params: {keyword: body['keyword']}});

            const sellerLikesResponse = await axiosApi.get(apiUrl + "/api/likes/user/seller");
            const productLikesResponse = await axiosApi.get(apiUrl + "/api/likes/user/product");
            const keywordLikesResponse = await axiosApi.get(apiUrl + "/api/likes/user/keyword");
            const sellerLikesData = sellerLikesResponse.data.map((row) => row.seller.name);
            const productLikesData = productLikesResponse.data.map((row) => row.product.listingId);
            const keywordLikesData = keywordLikesResponse.data.map((row) => row.keyword.keyword);
            setKeywordLikes(keywordLikesData);
            setSellerLikes(sellerLikesData);
            setProductLikes(productLikesData);

            let productRows = [];

            let productsRevenue = 0;

            let listing_ids = [];
            let listing_id_sales = {};
            let listing_id_prices = {};

            let sellerNamesByListingId = {};

            keywords_full.data.products.forEach((product) => {
                // set sum of seller revenues and sales
                if (!sellerNamesByListingId[product.listing_id]) {
                    sellerNamesByListingId[product.listing_id] = product.seller.name;
                }
            });


            keywords_full.data.marketshare.products_market_share.forEach((row) => {
                const dataRowProducts = {
                    sales: row.sales,
                    reviews: row.reviews,
                    revenue: row.revenue,
                    price: row.price,
                    listing_id: row.listing_id,
                    title: {
                        'title': row.title,
                        'seller': sellerNamesByListingId[row.listing_id],
                    },
                    image_url: row.image_url,
                    actionItems: {
                        identifier: row.listing_id,
                        type: 'product',
                        href: '/analytics/products?listingId=' + row.listing_id,
                    }
                };
                productRows.push(dataRowProducts)

                productsRevenue += row.revenue;

                listing_ids.push(row.listing_id)
                listing_id_sales[row.listing_id] = row.sales;
                listing_id_prices[row.listing_id] = row.price;
            });

            // sort productRows by revenue
            const best_seller_product = productRows.sort((a, b) => b.sales - a.sales)[0];
            productRows = productRows.sort((a, b) => (a.revenue < b.revenue) ? 1 : -1)

            let sellers_and_products = [];

            let productsForChartList = [];

            let sellers = [];
            let seller_names = [];

            let sellersRevenue = {};
            let sellersSales = {};

            let added_products = [];

            keywords_full.data.products.forEach((product) => {
                if (!listing_ids.includes(product.listing_id)) {
                    return;
                }

                // set sum of seller revenues and sales
                if (!sellersRevenue[product.seller.name]) {
                    sellersRevenue[product.seller.name] = 0;
                    sellersSales[product.seller.name] = 0;
                }
                sellersRevenue[product.seller.name] += product.price * listing_id_sales[product.listing_id];
                sellersSales[product.seller.name] += listing_id_sales[product.listing_id];

                if (!seller_names.includes(product.seller.name)) {
                    seller_names.push(product.seller.name);
                    sellers.push({
                        name: product.seller.name,
                        star_seller: product.seller.star_seller,
                        ratings: product.seller.ratings,
                        reviews: product.seller.reviews,
                        sales: 0,
                        revenue: 0,
                        ranking_product_count: 0,
                        actionItems: {
                            identifier: product.seller.name,
                            type: 'seller',
                            href: '/analytics/sellers?name=' + product.seller.name,
                        }
                    })
                }

                // set sales and revenue into sellers array
                const sellerIndexForSales = sellers.findIndex(item => item.name === product.seller.name);
                sellers[sellerIndexForSales].sales = sellersSales[product.seller.name];
                sellers[sellerIndexForSales].revenue = sellersRevenue[product.seller.name];
                sellers[sellerIndexForSales].ranking_product_count += 1;

                if (!added_products.includes(product.listing_id)) {
                    added_products.push(product.listing_id);
                    productsForChartList.push({
                        seller: product.title,
                        products: [
                            {
                                title: product.title,
                                listing_id: product.listing_id,
                                monthlySales: listing_id_sales[product.listing_id],
                                monthlyRevenue: listing_id_prices[product.listing_id] * listing_id_sales[product.listing_id],
                            }
                        ]
                    });
                }

                // if listing_id is in listing_ids
                const sellerIndex = sellers_and_products.findIndex(item => item.seller === product.seller.name);
                if (sellerIndex === -1) {
                    sellers_and_products.push({
                        seller: product.seller.name,
                        products: [
                            {
                                title: product.title,
                                image_url: product.image_url,
                                listing_id: product.listing_id,
                                monthlySales: listing_id_sales[product.listing_id],
                                monthlyRevenue: listing_id_sales[product.listing_id] * listing_id_prices[product.listing_id]
                            }
                        ]
                    });
                } else {
                    sellers_and_products[sellerIndex].products.push({
                        title: product.title,
                        image_url: product.image_url,
                        listing_id: product.listing_id,
                        monthlySales: listing_id_sales[product.listing_id],
                        monthlyRevenue: listing_id_sales[product.listing_id] * listing_id_prices[product.listing_id]
                    });
                }
            })

            sellers_and_products = sellers_and_products.sort((a, b) => {
                const totalSalesA = a.products.reduce((sum, product) => sum + product.monthlyRevenue, 0);
                const totalSalesB = b.products.reduce((sum, product) => sum + product.monthlyRevenue, 0);
                return totalSalesB - totalSalesA;
            });

            productsForChartList = productsForChartList.sort((a, b) => {
                const totalSalesA = a.products.reduce((sum, product) => sum + product.monthlyRevenue, 0);
                const totalSalesB = b.products.reduce((sum, product) => sum + product.monthlyRevenue, 0);
                return totalSalesB - totalSalesA;
            });

            setProductsForChart(productsForChartList);

            // sort sellers by sales

            sellers = sellers.sort((a, b) => {
                return b.revenue - a.revenue;
            }  );

            const sellerColumns = [
                {Header: 'Seller', accessor: 'name', table: true, Cell: (row) => { return (
                        <div style={{width: 250}}>
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Ranking Product Count', accessor: 'ranking_product_count', table: true, Cell: (row) => { return (
                        <div>
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Monthly Sales', accessor: 'sales', table: true, Cell: (row) => { return (
                        <div>
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Monthly Revenue', accessor: 'revenue', table: true, Cell: (row) => { return (
                        <div>
                            ${row.value?.toLocaleString()}
                        </div>
                    ) }
                },
                {Header: 'Star Seller', accessor: 'star_seller', table: true, Cell: (row) => { return (
                        <div>
                            {row.value ? <Star /> : <StarBorder />}
                        </div>
                    ) }
                },
                {Header: 'Ratings', accessor: 'ratings', table: true, Cell: (row) => { return (
                        <div>
                            {row.value?.toFixed(2)}
                        </div>
                    ) }
                },
                {Header: 'Reviews', accessor: 'reviews', table: true, Cell: (row) => { return (
                        <div>
                            {row.value}
                        </div>
                    ) }
                },

                {Header: 'Actions', accessor: 'actionItems', table: true, actions: true},
            ];

            setSellerResults({
                'rows': sellers,
                'columns': sellerColumns,
            })


            // sum revenues and keep one product per seller
            let sellersCombined = []
            sellers_and_products.forEach(seller => {
                let sellerRevenue = 0;
                let sellerSales = 0;
                seller.products.forEach(product => {
                    sellerRevenue += product.monthlySales * listing_id_prices[product.listing_id];
                    sellerSales += product.monthlySales;
                })
                sellersCombined.push({
                    'seller': seller.seller,
                    'products': [{
                        'title': seller.name,
                        'name': seller.name,
                        'monthlyRevenue': sellerRevenue,
                        'monthlySales': sellerSales,
                    }],
                })
            })

            setSellersAndProducts(sellers_and_products);


            const productColumns = [
                {Header: 'Image', accessor: 'image_url', table: true, Cell: (row) => { return (
                        <img src={row.value} alt={row.value} width={100} />
                    ) }
                },
                {Header: 'Title', width: '270px', accessor: 'title', table: true, Cell: (row) => { return (
                        <div style={{width: '270px'}}>
                            {row.value['title']}
                        </div>
                    ) }
                },
                {Header: 'Monthly Sales', width: '120px', accessor: 'sales', table: true, Cell: (row) => { return (
                        <div>
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Monthly Reviews', width: '110px', accessor: 'reviews', table: true, Cell: (row) => { return (
                        <div>
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Monthly Revenue', width: '25px', accessor: 'revenue', table: true, Cell: (row) => { return (
                        <div>
                            ${row.value?.toLocaleString()}
                        </div>
                    ) }
                },
                {Header: 'Price', accessor: 'price', width: '15px', table: true, Cell: (row) => { return (
                        <div>
                            ${row.value?.toLocaleString()}
                        </div>
                    ) }
                },

                {Header: 'Actions', accessor: 'actionItems', table: true, actions: true},
            ];

            const productResult = {
                "rows": productRows,
                "columns": productColumns
            };
            setProductResults(productResult);

            let topic_keyword = null;
            let best_selling_keyword = null;

            let rows2 = [];

            keywords_full.data.marketshare.related_keywords.forEach((row) => {
                const dataRow = {
                    // product_images: row.product_images,
                    keyword: row.keyword,
                    // salesShare: row.sales_share_percentage,
                    // keywordSales: row.keyword_sales,
                    totalResults: row.total_results,
                    totalSales: row.total_sales,
                    totalMatchingKeywordSales: row.total_matching_keyword_sales,
                    firstPageMainKeywordProductCount: row.related_kw_products_count,
                    actionItems: {
                        identifier: row.keyword,
                        type: 'keyword',
                        href: '/analytics/keywords?keyword=' + row.keyword,
                    },
                };

                rows.push(dataRow)
                rows2.push(dataRow)
            });

            const avg_total_results = rows2.reduce((a, b) => a + b.totalResults, 0) / rows2.length;
            const avg_keyword_sales = rows2.reduce((a, b) => a + b.totalMatchingKeywordSales, 0) / rows2.length;
            const avg_total_sales = rows2.reduce((a, b) => a + b.totalSales, 0) / rows2.length;

            let highest_ratio_of_avg_results = null;
            let highest_ratio_of_avg_results_keyword = null;
            rows2.forEach((row) => {

                if (row.firstPageMainKeywordProductCount < 8) {
                    return;
                }

                // formula is highest_ration_of_avg_results * totalSales
                const ratio = row.totalResults / avg_total_results * row.totalSales * row.firstPageMainKeywordProductCount
                if (highest_ratio_of_avg_results === null || ratio > highest_ratio_of_avg_results) {
                    highest_ratio_of_avg_results = ratio;
                    highest_ratio_of_avg_results_keyword = row.keyword;
                }
            });

            best_selling_keyword = rows[0]['keyword'];
            topic_keyword = highest_ratio_of_avg_results_keyword

            rows2.sort((a, b) => b.firstPageMainKeywordProductCount - a.firstPageMainKeywordProductCount);

            let treeChart = [];

            rows2.forEach((row) => {
                treeChart.push({
                    x: row.keyword,
                    y: row.firstPageMainKeywordProductCount,
                })
            });

            setTreeChartData(treeChart.slice(0, 20));

            const columns = [
                {Header: 'Keyword', width: '250px', accessor: 'keyword', table: true, Cell: (row) => { return (
                        <div style={{width: '250px'}}>
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Products From Main Keyword', accessor: 'firstPageMainKeywordProductCount', table: true, Cell: (row) => { return (
                        <div >
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Monthly Sales', accessor: 'totalSales', table: true, Cell: (row) => { return (
                        <div >
                            {row.value && row.value.toLocaleString()}
                        </div>
                    ) }
                },

                {Header: 'Total Results', accessor: 'totalResults', table: true, Cell: (row) => { return (
                        <div >
                            {row.value && row.value.toLocaleString()}
                        </div>
                    ) }
                },

                {Header: 'Actions', accessor: 'actionItems', table: true, actions: true},
            ];

            const result = {
                "rows": rows,
                "columns": columns
            };
            setResults(result);

            const metaResult = {
                "totalSales": keywords_full.data.totalSales,
                "totalResults": keywords_full.data.totalResults,
                "keyword": keywords_full.data.keyword,
                "totalRelatedKeywordsProductCount": keywords_full.data.totalRelatedKeywordsProductCount,
                "relatedKeywordsCount": keywords_full.data.relatedKeywordsCount,
                "totalMatchingKeywordSales": response.data.totalMatchingKeywordSales,

                "productsWithSalesCount": response.data.productsWithSalesCount,
                "productsWithoutSalesCount": response.data.productsWithoutSalesCount,
                "productsWithSalesAndKeywordInTitleCount": response.data.productsWithSalesAndKeywordInTitleCount,
                "best_seller_product": best_seller_product,
                "topic_keyword": topic_keyword,
                "best_selling_keyword": best_selling_keyword,
                "competitionScore": keywords_light.data.competitionScore,
                "fullKeyword": keywords_full.data,
                "productsRevenue": productsRevenue,
                "avgTotalResults": avg_total_results,
                "avgKeywordSales": avg_keyword_sales,
                "avgTotalSales": avg_total_sales,
                "relatedKeywords": rows2,
            };
            setMetaResults(metaResult);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const keyword = queryParams.get('keyword');

        const updatedState = {
            ...formData,
            keyword: keyword
        }

        if (keyword) {
            fetchData(updatedState);
        }
    }, []); // Empty dependency array ensures that the effect runs only once


    const handleFormData = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetchData();
    };

  return (
    <DashboardLayout>
        <Header/>

        <Form formHandler={handleFormData} submitHandler={handleSubmit} formData={formData} />

        <SoftBox py={3}>
          <Container>
              {loading && (
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                      <PuffLoader
                          color={'#17c1e8'}
                          loading={loading}
                          size={60}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                      />

                  </div>
              )}

              <div ref={tableRef}>
                  {!loading && results && metaResults && (
                      <SoftBox mb={3} sx={{marginTop: "30px"}}>
                          <Card>
                              <SoftBox p={3} lineHeight={1}>
                                  <MetaTableView
                                      metaResults={metaResults}
                                      tabValue={tabValue}
                                      sellersAndProducts={sellersAndProducts}
                                      productsForChart={productsForChart}
                                      treeChartData={treeChartData}
                                      productResults={productResults}
                                      handleSetTabValue={handleSetTabValue}
                                  />
                              </SoftBox>
                              {tabValue === 0 && (
                                  <>
                                      <Card>
                                          <SoftBox p={3} lineHeight={1}>
                                              <SoftTypography variant="h5" fontWeight="medium">
                                                  Related Keywords
                                              </SoftTypography>
                                              <SoftTypography variant="button" fontWeight="regular" color="text">
                                                  Keywords that products also rank for
                                              </SoftTypography>
                                          </SoftBox>
                                          <DataTable table={results} likedItems={keywordLikes} />
                                      </Card>
                                  </>

                              )}

                              {tabValue === 1 && (
                                  <>
                                      <Card>
                                          <SoftBox p={3} lineHeight={1}>
                                              <SoftTypography variant="h5" fontWeight="medium">
                                                  Products
                                              </SoftTypography>
                                              <SoftTypography variant="button" fontWeight="regular" color="text">
                                                  Products with sales that rank for given keyword in the first page
                                              </SoftTypography>
                                          </SoftBox>
                                          <DataTable table={productResults} likedItems={productLikes} />
                                      </Card>
                                  </>
                              )}

                              {tabValue === 2 && (
                                  <>
                                      <Card>
                                          <SoftBox p={3} lineHeight={1}>
                                              <SoftTypography variant="h5" fontWeight="medium">
                                                  Sellers
                                              </SoftTypography>
                                              <SoftTypography variant="button" fontWeight="regular" color="text">
                                                  Sellers that rank for given keyword in the first page
                                              </SoftTypography>
                                          </SoftBox>
                                          <DataTable likedItems={sellerLikes} table={sellerResults} title={"Keyword 1st Page Sellers"} />
                                      </Card>
                                  </>
                              )}

                          </Card>
                      </SoftBox>
                  )}
              </div>
          </Container>
      </SoftBox>
    </DashboardLayout>
  );
}

export default KeywordRadarTool;
