/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Pricing page components
import FaqCollapse from "layouts/pages/pricing-page/components/FaqCollapse";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <SoftBox mt={16}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <SoftTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            Frequently Asked Questions
          </SoftTypography>
          <SoftBox mb={2}>
            {/*<SoftTypography variant="body2" align="center" color="text">*/}
            {/*  A lot of people don&apos;t appreciate the moment until it’s passed. I&apos;m not*/}
            {/*  trying my hardest, and I&apos;m not trying to do*/}
            {/*</SoftTypography>*/}
          </SoftBox>
        </Grid>
        <Grid item xs={12} md={10}>
          <FaqCollapse
            title="Can I cancel my subscription?"
            open={collapse === 1}
            onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
          >
            Yes, you can cancel your subscription anytime.
          </FaqCollapse>
          <FaqCollapse
            title="How can i make the payment?"
            open={collapse === 2}
            onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
          >
              In your selected plan card - click on the <strong>Buy now</strong> button and you will be redirected to the stripe payment page.
          </FaqCollapse>
          <FaqCollapse
            title="How much time does it take to receive the order?"
            open={collapse === 3}
            onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
          >
            You will receive order immediately after the payment.
          </FaqCollapse>

          <FaqCollapse
            title="Will I get an invoice for my subscription?"
            open={collapse === 5}
            onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
          >
            Yes, you will receive an invoice in your email after the payment. Also you can download invoice in your profile page.
          </FaqCollapse>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Faq;
