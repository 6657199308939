import PropTypes from "prop-types";
import {useRef, useState} from "react";

function Radar({ color, size, position }) {
    // const animationRef1 = useRef(null);
    // const animationRef2 = useRef(null);
    // const [radarMotionEnabled, setRadarMotionEnabled] = useState(false);
    //
    // const handleStartAnimation = () => {
    //     if (radarMotionEnabled) {
    //         animationRef1.current.endElement();
    //         // animationRef2.current.endElement();
    //         setRadarMotionEnabled(false)
    //     } else {
    //         animationRef1.current.beginElement();
    //         // animationRef2.current.beginElement();
    //         setRadarMotionEnabled(true)
    //     }
    //
    // };

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{
                position: position,
            }}
        >
            <defs>
                <path id="motionPath1" d="M256,476.444c-121.553,0-220.444-98.89-220.444-220.444S134.447,35.556,256,35.556
                    c121.555,0,220.444,98.89,220.444,220.444S377.554,476.444,256,476.444z M256,78.222c-98.027,0-177.778,79.751-177.778,177.778
                    S157.973,433.778,256,433.778S433.778,354.027,433.778,256S354.027,78.222,256,78.222z"/>

                <path id="motionPath2" d="M256.947,35.567v42.667C354.539,78.747,433.778,158.29,433.778,256s-79.239,177.253-176.831,177.766
                    v42.667C378.066,475.918,476.444,377.237,476.444,256S378.066,36.082,256.947,35.567z"/>
            </defs>

            <g>
                <path style={{ fill: "#BFE4F8" }} d="M251.258,348.322c-47.835-2.429-86.167-41.397-87.63-89.478h-42.704
          c1.488,71.612,58.964,129.7,130.334,132.174V348.322z"/>
                {/*FOLLOW THIS LINE*/}
                <path style={{ fill: "#BFE4F8" }} d="M256,476.444c-121.553,0-220.444-98.89-220.444-220.444S134.447,35.556,256,35.556
          c121.555,0,220.444,98.89,220.444,220.444S377.554,476.444,256,476.444z M256,78.222c-98.027,0-177.778,79.751-177.778,177.778
          S157.973,433.778,256,433.778S433.778,354.027,433.778,256S354.027,78.222,256,78.222z"/>
            </g>
            <g>
                {/*FOLLOW THIS LINE*/}
                <path style={{ fill: "#93C7EF" }} d="M256.947,35.567v42.667C354.539,78.747,433.778,158.29,433.778,256s-79.239,177.253-176.831,177.766
          v42.667C378.066,475.918,476.444,377.237,476.444,256S378.066,36.082,256.947,35.567z"/>
                <path style={{ fill: "#93C7EF" }} d="M348.444,256h42.667c0-74.183-60.099-134.586-134.164-135.1v42.679
          C307.484,164.092,348.444,205.343,348.444,256z"/>
            </g>
            <path style={{ fill: "#3C5D76" }} d="M256,256V120.889c-74.5,0-135.111,60.611-135.111,135.111H256"/>
            <path style={{ fill: "#1E2E3B" }} d="M256.947,256.947h135.111c0,74.5-60.611,135.111-135.111,135.111L256.947,256.947"/>
            <g>
                <rect x="234.667" style={{ fill: "#5A8BB0" }} width="42.667" height="512"/>
                <rect y="234.667" style={{ fill: "#5A8BB0" }} width="512" height="42.667"/>
            </g>

            <g>
                <circle style={{ fill: "#3C5D76" }} cx="114.731" cy="398.222" r="42.667"/>
                <circle style={{ fill: "#3C5D76" }} cx="399.175" cy="113.778" r="42.667"/>
            </g>
            <g>
                <path style={{ fill: "#1E2E3B" }} d="M399.169,156.444c23.563,0,42.667-19.103,42.667-42.667s-19.103-42.667-42.667-42.667"/>
                <path style={{ fill: "#1E2E3B" }} d="M114.725,440.889c23.563,0,42.667-19.103,42.667-42.667c0-23.563-19.103-42.667-42.667-42.667"/>
            </g>


            {/*<g>*/}
            {/*    <circle style={{ fill: "#ff0000" }} cx="-10" cy="-35" r="30.667">*/}
            {/*        <animateMotion*/}
            {/*            ref={animationRef1}*/}
            {/*            dur="20s"*/}
            {/*            begin="indefinite"*/}
            {/*            repeatCount="indefinite"*/}
            {/*            keyPoints="0;1;0"*/}
            {/*            keyTimes="0;0.5;1"*/}
            {/*            calcMode="spline"*/}
            {/*            keySplines="0 0 0.58 1; 0.42 0 0.58 1"*/}
            {/*        >*/}
            {/*            <mpath href="#motionPath1" />*/}
            {/*        </animateMotion>*/}
            {/*    </circle>*/}

            {/*    /!*<circle style={{ fill: "#3C5D76" }} cx="0" cy="0" r="42.667">*!/*/}
            {/*    /!*    <animateMotion*!/*/}
            {/*    /!*        ref={animationRef2}*!/*/}
            {/*    /!*        dur="8s"*!/*/}
            {/*    /!*        begin="indefinite"*!/*/}
            {/*    /!*        repeatCount="indefinite"*!/*/}
            {/*    /!*        keyPoints="0;1;0"*!/*/}
            {/*    /!*        keyTimes="0;0.5;1"*!/*/}
            {/*    /!*        calcMode="spline"*!/*/}
            {/*    /!*        keySplines="0 0 0.58 1; 0.42 0 0.58 1"*!/*/}
            {/*    /!*    >*!/*/}
            {/*    /!*        <mpath href="#motionPath2" />*!/*/}
            {/*    /!*    </animateMotion>*!/*/}
            {/*    /!*</circle>*!/*/}
            {/*</g>*/}

        </svg>
    );
}

Radar.defaultProps = {
    color: "#BFE4F8",
    size: "16px",
    position: "relative",
};

Radar.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    position: PropTypes.string,
};

export default Radar;
