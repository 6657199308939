// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import {useEffect, useMemo, useState} from "react";
import Grid from "@mui/material/Grid";
import EventCard from "./eventCard";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import DefaultLineChart from "../../../examples/Charts/LineCharts/DefaultLineChart";
import MiniStatisticsCard from "../../../examples/Cards/StatisticsCards/MiniStatisticsCard";
import SoftButton from "../../../components/SoftButton";


function MetaTableView({metaResults}) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, [metaResults]);

    return (
        <Card sx={{ height: "100%" }}>
                <Grid container spacing={3}>

                    {!loading && (
                        <>
                            <Grid item xs={12} lg={12} flexDirection={'row'}>
                                <SoftBox display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                    <Grid item xs={3} lg={3}>
                                        <SoftBox
                                            component="img"
                                            src={metaResults.product.imageUrl}
                                            alt={metaResults.product.title}
                                            shadow="lg"
                                            borderRadius="lg"
                                            width="100%"
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={9}>
                                        <SoftTypography p={3} style={{ textTransform: 'capitalize' }} color={'dark'} variant="h5" fontWeight="bold" textAlign={'center'}>
                                            {metaResults.product.title}
                                        </SoftTypography>

                                        <SoftBox p={3}>
                                            <SoftBox>
                                                <SoftTypography component="span" variant="body2" fontWeight="bold" color="text">
                                                    Price:&nbsp;
                                                </SoftTypography>
                                                <SoftTypography component="span" variant="body2" color="text">
                                                    {'$ ' + metaResults.product.price}
                                                </SoftTypography>
                                            </SoftBox>

                                            <SoftBox>
                                                <SoftTypography component="span" variant="body2" fontWeight="bold" color="text">
                                                    Listing ID:&nbsp;
                                                </SoftTypography>
                                                <SoftTypography component="span" variant="body2" color="text">
                                                    {metaResults.product.listingId}
                                                </SoftTypography>
                                            </SoftBox>

                                            <Divider/>

                                            <SoftButton
                                                component="a"
                                                href={'https://www.etsy.com/listing/' + metaResults.product.listingId}
                                                variant="outlined"
                                                color={'dark'}
                                                size="small"
                                                target="_blank"
                                            >
                                                View On Etsy
                                            </SoftButton>
                                        </SoftBox>
                                    </Grid>
                                </SoftBox>
                            </Grid>




                            <Grid item xs={12} lg={12} flexDirection={'row'}>
                                <SoftBox display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>

                                    <Grid item xs={3} md={3} lg={4}>
                                        <MiniStatisticsCard
                                            title={{ fontWeight: "medium", text: "Monthly Revenue" }}
                                            count={'$ ' + (metaResults.product.price * metaResults.product.monthlySales)?.toLocaleString()}
                                            icon={{ color: "dark", component: "local_atm" }}
                                            direction="left"
                                        />
                                    </Grid>

                                    <Grid item xs={3} md={3} lg={4}>
                                        <MiniStatisticsCard
                                            title={{ fontWeight: "medium", text: "Monthly Sales" }}
                                            count={metaResults.product.monthlySales?.toLocaleString()}
                                            // percentage={{ color: "success", text: "+55%" }}
                                            icon={{ color: "dark", component: "local_atm" }}
                                            direction="left"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <MiniStatisticsCard
                                            title={{ fontWeight: "medium", text: "Monthly Reviews" }}
                                            count={metaResults.product.monthlyReviews?.toLocaleString()}
                                            icon={{ color: "dark", component: "local_atm" }}
                                            direction="left"
                                        />
                                    </Grid>

                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} lg={6} flexDirection={'row'}>
                                <SoftBox mb={3}>
                                    <EventCard
                                        title="Seller Details"
                                        seller={metaResults.product.seller}
                                        action={{
                                            type: "internal",
                                            route: "/analytics/sellers?name=" + metaResults.product.seller.name,
                                            color: "info",
                                            label: "View Seller"
                                    }}
                                    />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} lg={6} flexDirection={'row'}>
                                <SoftBox mb={3}>
                                    <Card>
                                        <SoftBox p={2}>
                                            <SoftBox display="flex" alignItems="center">
                                                <Icon
                                                    sx={{
                                                        backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                                                            gradients["dark"]
                                                                ? linearGradient(gradients["dark"].main, gradients["dark"].state)
                                                                : linearGradient(gradients.dark.main, gradients.dark.state),
                                                        WebkitBackgroundClip: "text",
                                                        WebkitTextFillColor: "transparent",
                                                    }}
                                                >
                                                    account_circle
                                                </Icon>

                                                <SoftBox ml={1} lineHeight={0}>
                                                    <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                                        Ranking Keywords
                                                    </SoftTypography>
                                                </SoftBox>
                                            </SoftBox>

                                            <SoftBox>
                                                <SoftTypography component="span" variant="body2" fontWeight="bold" color="text">
                                                    Total Keyword Count:&nbsp;
                                                </SoftTypography>
                                                <SoftTypography component="span" variant="body2" color="text">
                                                    {metaResults.keywords.all.length}
                                                </SoftTypography>
                                                <br/>

                                                <SoftTypography component="span" variant="body2" fontWeight="bold" color="text">
                                                    Organic Keyword Count:&nbsp;
                                                </SoftTypography>
                                                <SoftTypography component="span" variant="body2" color="text">
                                                    {metaResults.keywords.organic.length}
                                                </SoftTypography>
                                                <br/>

                                                <SoftTypography component="span" variant="body2" fontWeight="bold" color="text">
                                                    Sponsored Keyword Count:&nbsp;
                                                </SoftTypography>
                                                <SoftTypography component="span" variant="body2" color="text">
                                                    {metaResults.keywords.sponsored.length}
                                                </SoftTypography>
                                            </SoftBox>
                                            <Divider />

                                        </SoftBox>
                                    </Card>
                                </SoftBox>
                            </Grid>

                            {metaResults.monthly_linechart && (
                                <Grid item xs={12} sm={12} lg={12}>
                                    <DefaultLineChart
                                        title="Monthly Revenue History"
                                        description={
                                            <SoftBox display="flex" justifyContent="space-between">
                                                {/*<SoftBox display="flex" ml={-1}>*/}
                                                {/*    <SoftBadgeDot color="info" size="sm" badgeContent={metaResults.product.title} />*/}
                                                {/*</SoftBox>*/}
                                                {/*<SoftBox mt={-5.25} mr={-1}>*/}
                                                {/*    <Tooltip title="See which ads perform better" placement="left" arrow>*/}
                                                {/*        <SoftButton*/}
                                                {/*            variant="outlined"*/}
                                                {/*            color="secondary"*/}
                                                {/*            size="small"*/}
                                                {/*            circular*/}
                                                {/*            iconOnly*/}
                                                {/*        >*/}
                                                {/*            <Icon>priority_high</Icon>*/}
                                                {/*        </SoftButton>*/}
                                                {/*    </Tooltip>*/}
                                                {/*</SoftBox>*/}
                                            </SoftBox>
                                        }
                                        chart={metaResults.monthly_linechart}
                                    />
                                </Grid>
                            )}
                        </>
                    )}

                </Grid>
        </Card>
    );
}

// Typechecking props for the DataTableBodyCell
MetaTableView.propTypes = {
    metaResults: PropTypes.object.isRequired
};


export default MetaTableView;
