import React from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from "prop-types";
import MetaTableView from "../../../../app/pages/KeywordMarketshare/Table/MetaTableView";

const ApexBarChart = ({
    seriesSectionData,
    attribute='monthlySales',
    thousands=false,
    dataLabels=true,
}) => {
    const seriesData = [];
    const productSections = {};
    const labels = [];
    let maxProductsPerSeller = 0;


    seriesSectionData.forEach((seriesSection) => {
        if (seriesSection.products.length > maxProductsPerSeller) {
            maxProductsPerSeller = seriesSection.products.length;
        }
    });



    const sellerNames = [];
    seriesSectionData.forEach((seriesSection) => {
        const sellerName = seriesSection.seller;
        labels.push(sellerName);

        seriesSection.products.forEach((product, sellerProductIndex) => {
            if (!productSections[sellerProductIndex]) {
                productSections[sellerProductIndex] = [];
            }
            productSections[sellerProductIndex].push(product[attribute]);
        });

        // add 0 to fit the maxProductsPerSeller
        for (let i = seriesSection.products.length; i < maxProductsPerSeller; i++) {
            if (!productSections[i]) {
                productSections[i] = [];
            }
            productSections[i].push(0);
        }
    });

    Object.keys(productSections).forEach((sellerProductIndex) => {
        seriesData.push({
            name: "Monthly Revenue",
            group: sellerProductIndex,
            data: productSections[sellerProductIndex],
        });
    });

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            // stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '85%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: dataLabels
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: labels,
            labels: {
                // rotate: -45,
                formatter: (val) => {
                    return val.length > 20 ? val.slice(0, 20) + '...' : val;
                }
            }
        },
        fill: {
            opacity: 1
        },
        colors: ['#80c7fd', '#008FFB', '#80f1cb', '#00E396'],
        yaxis: {
            labels: {
                // formatter: (val) => {
                //     return (val / 1000).toFixed(2) + 'K'
                // }
            }
        },
        legend: {
            display: null,
            // position: 'top',
            // horizontalAlign: 'left'
        },
    };

    if (thousands) {
        options.dataLabels.formatter = (val) => {
            return (val / 1000).toFixed(2) + 'K'
        };
        options.yaxis.labels.formatter = (val) => {
            return (val / 1000).toFixed(2) + 'K'
        };
    }



    return (
        <div id="chart">
            <ReactApexChart options={options} series={seriesData} type="bar" height={500} />
        </div>
    );
};


// Typechecking props for the DataTableBodyCell
ApexBarChart.propTypes = {
    seriesSectionData: PropTypes.object.isRequired,
    attribute: PropTypes.string,
    thousands: PropTypes.bool,
    dataLabels: PropTypes.bool,
};

export default ApexBarChart;
