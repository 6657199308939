import Card from "@mui/material/Card";
import SoftBox from "../../../components/SoftBox";
import PropTypes from "prop-types";
import DataTable from "./DataTable";


function Table({tableData, meta, onPageChange, page, sortHandler, formData}) {
    const handlePaginationClick = (pageNumber) => {
        onPageChange(pageNumber);
    };

    const handleSorting = (sort) => {
        sortHandler(sort);
    }

    return (
        <SoftBox mb={3} sx={{marginTop: "30px"}}>
            <Card>
                <DataTable
                    table={tableData}
                    totalResults={meta.totalResults}
                    onPageChange={handlePaginationClick}
                    pageIndex={page - 1}
                    sortHandler={handleSorting}
                    formData={formData}
                />
            </Card>
        </SoftBox>
    );
}

// Typechecking props for the Header
Table.propTypes = {
    tableData: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    meta: PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired,
    sortHandler: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
};

export default Table;