import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import email_logo from "assets/images/app/email.png";
import GoogleIcon from "../../components/Etsellify/Socials/GoogleSignIn/GoogleIcon";


function VerifyEmail() {
    let navigate = useNavigate();
    const [email, setEmail] = useState(null);

    useEffect(
        () => {
            // get email from url
            const urlParams = new URLSearchParams(window.location.search);
            const email = urlParams.get('email');
            setEmail(email);
        }
    )

    const url_for_email = 'https://mail.google.com/mail/u/1/#search/from%3Anoreply@alura-io.firebaseapp.com+in%3Aanywhere+newer_than%3A1h'

    return (
        <IllustrationLayout
            title="Verification Required"
            illustration={{
                image: email_logo,
                title: "Verify your email address",
                description:
                    "",
            }}
        >
            <SoftBox mb={2}>

                <SoftTypography variant="body2" color="textSecondary">
                    <strong>{email}</strong> We have sent you an email with a link to verify your account.
                </SoftTypography>
            </SoftBox>
            <SoftBox mb={2}>
                <SoftButton
                    fullWidth
                    variant="contained"
                    color="outline"
                    size="large"
                    target="_blank"
                    href={url_for_email}
                >
                    <GoogleIcon/> <span style={{marginLeft: '10px'}}>Open in Gmail</span>
                </SoftButton>
            </SoftBox>

        </IllustrationLayout>
    );
}

export default VerifyEmail;
