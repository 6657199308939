// @mui material components
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import PropTypes from "prop-types";

function Steps({ title, count, percentage}) {
  return (
    <Card>
      <SoftBox p={3}>
        <SoftTypography variant="body2" color="text" fontWeight="regular">
            {title}
        </SoftTypography>
        <SoftBox mt={2} mb={1} lineHeight={0}>
          <SoftTypography variant="h5" fontWeight="bold">
              {count}
          </SoftTypography>
        </SoftBox>
          {percentage && (
              <SoftBadge
                  variant="contained" color={percentage.color}
                  badgeContent={percentage.text}
                  container
              />
          )}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of Steps
Steps.defaultProps = {
    title: "",
    count: null,
}

// Typechecking props for the Steps
Steps.propTypes = {
    title: PropTypes.string,
    count: PropTypes.string,
    percentage: PropTypes.shape({
        text: PropTypes.string,
        color: PropTypes.oneOf(["success", "info", "warning", "error"]),
    }),
}

export default Steps;
