// api.js
import axios from 'axios';

export const axiosApi = axios.create();

axiosApi.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosApi.interceptors.response.use((response) => {
    return response;
}, (error) => {
    return Promise.reject(error);
});
