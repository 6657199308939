import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Images
import rocket from "assets/images/illustrations/rocket-white.png";
import Separator from "../../layouts/authentication/components/Separator";

import { useGoogleLogin} from '@react-oauth/google';
import GoogleIcon from "../../components/Etsellify/Socials/GoogleSignIn/GoogleIcon";
import axios from "axios";
import SoftAlert from "../../components/SoftAlert";
import {PuffLoader} from "react-spinners";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;

function Illustration() {
    const navigate = useNavigate();

    const [agreement, setAgreemnet] = useState(true);
    const [loading, setLoading] = useState(false);
    const [emailForm, setEmailForm] = useState({
        email: '',
        name: '',
        plainPassword: '',
    });

    const [googleError, setGoogleError] = useState(null);
    const [emailError, setEmailError] = useState(null);

    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            setGoogleError(null);
            setLoading(true);
            await axios.post(apiUrl + '/api/auth/callback', {
                'access_token': tokenResponse.access_token,
            }).then(res => {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('tokenExpiration', res.data.tokenExpiration);
                // redirect to home page
                window.location.replace("/")
                // navigate("/");
            }).catch(err => {
                if (err.response && err.response.data) {
                    setGoogleError(err.response.data)
                }
            }).finally(() => {
                setLoading(false);
            });
        },
    });

    const handleSetAgremment = () => setAgreemnet(!agreement);

    const handleEmailFormChange = (e) => {
        setEmailForm({
            ...emailForm,
            [e.target.name]: e.target.value,
        })
    }

    const submitEmailForm = (e) => {
        e.preventDefault();
        setEmailError(null);

        if (!agreement) {
            setEmailError({
                key: 'agreement',
                error: 'You must agree to our terms and conditions',
            })
            return;
        }

        setLoading(true);

        axios.post(apiUrl + '/api/auth/register/email', {
            ...emailForm,
        }).then(res => {
            // Navigate to page to verify email
            navigate("/verify-email?email=" + emailForm.email);
        }).catch(err => {
            setEmailError({
                key: err.response.data.key,
                error: err.response.data.error
            })
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <IllustrationLayout
            title="Sign Up to Etsellify"
            description="Powerful Tool For Powerful Results"
            illustration={{
                image: rocket,
                title: "Your journey starts here",
                description:
                    "It takes one insight to change your Etsy game. Start your journey with us and see how far you can go.",
            }}
        >
            {loading && (
                <PuffLoader
                    color={'#17c1e8'}
                    loading={loading}
                    // cssOverride={override}
                    size={60}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            )}

            {!loading && (
                <>
                    <SoftBox mb={2}>
                        <SoftButton onClick={() => googleLogin()} style={{width: '300px'}}>
                            <GoogleIcon/> <span style={{marginLeft: '10px'}}>Sign Up with Google</span>
                        </SoftButton>
                        {googleError && (
                            <SoftAlert color="error">
                                <SoftTypography variant="body2" color="white">
                                    {googleError}
                                </SoftTypography>
                            </SoftAlert>
                        )}

                    </SoftBox>
                    {/*<Separator/>*/}

                    {/*<SoftBox component="form" role="form">*/}
                    {/*    <SoftBox mb={2}>*/}
                    {/*        <SoftInput*/}
                    {/*            name={'name'}*/}
                    {/*            placeholder="Name"*/}
                    {/*            size="large"*/}
                    {/*            value={emailForm.name}*/}
                    {/*            onChange={handleEmailFormChange}*/}
                    {/*        />*/}
                    {/*        {emailError && emailError['key'] && emailError['key'] === 'name' && (*/}
                    {/*            <SoftAlert color="error">*/}
                    {/*                <SoftTypography variant="body2" color="white">*/}
                    {/*                    {emailError['error']}*/}
                    {/*                </SoftTypography>*/}
                    {/*            </SoftAlert>*/}
                    {/*        )}*/}
                    {/*    </SoftBox>*/}
                    {/*    <SoftBox mb={2}>*/}
                    {/*        <SoftInput*/}
                    {/*            name={'email'}*/}
                    {/*            type="email"*/}
                    {/*            placeholder="Email"*/}
                    {/*            size="large"*/}
                    {/*            value={emailForm.email}*/}
                    {/*            onChange={handleEmailFormChange}*/}
                    {/*        />*/}
                    {/*        {emailError && emailError['key'] && emailError['key'] === 'email' && (*/}
                    {/*            <SoftAlert color="error">*/}
                    {/*                <SoftTypography variant="body2" color="white">*/}
                    {/*                    {emailError['error']}*/}
                    {/*                </SoftTypography>*/}
                    {/*            </SoftAlert>*/}
                    {/*        )}*/}
                    {/*    </SoftBox>*/}
                    {/*    <SoftBox mb={2}>*/}
                    {/*        <SoftInput*/}
                    {/*            name={'plainPassword'}*/}
                    {/*            type="password"*/}
                    {/*            placeholder="Password"*/}
                    {/*            size="large"*/}
                    {/*            value={emailForm.plainPassword}*/}
                    {/*            onChange={handleEmailFormChange}*/}
                    {/*        />*/}
                    {/*        {emailError && emailError['key'] && emailError['key'] === 'plainPassword' && (*/}
                    {/*            <SoftAlert color="error">*/}
                    {/*                <SoftTypography variant="body2" color="white">*/}
                    {/*                    {emailError['error']}*/}
                    {/*                </SoftTypography>*/}
                    {/*            </SoftAlert>*/}
                    {/*        )}*/}
                    {/*    </SoftBox>*/}
                    {/*    <SoftBox display="flex" alignItems="center">*/}
                    {/*        <Checkbox checked={agreement} onChange={handleSetAgremment}/>*/}
                    {/*        <SoftTypography*/}
                    {/*            variant="button"*/}
                    {/*            fontWeight="regular"*/}
                    {/*            onClick={handleSetAgremment}*/}
                    {/*            sx={{cursor: "pointer", userSelect: "none"}}*/}
                    {/*        >*/}
                    {/*            &nbsp;&nbsp;I agree the&nbsp;*/}
                    {/*        </SoftTypography>*/}
                    {/*        <SoftTypography component="a" href="#" variant="button" fontWeight="bold" textGradient>*/}
                    {/*            Terms and Conditions*/}
                    {/*        </SoftTypography>*/}
                    {/*    </SoftBox>*/}
                    {/*    {emailError && emailError['key'] && emailError['key'] === 'agreement' && (*/}
                    {/*        <SoftAlert color="error">*/}
                    {/*            <SoftTypography variant="body2" color="white">*/}
                    {/*                {emailError['error']}*/}
                    {/*            </SoftTypography>*/}
                    {/*        </SoftAlert>*/}
                    {/*    )}*/}
                    {/*    <SoftBox mt={4} mb={1}>*/}
                    {/*        <SoftButton*/}
                    {/*            variant="gradient"*/}
                    {/*            color="info"*/}
                    {/*            size="large"*/}
                    {/*            onClick={(e) => submitEmailForm(e)}*/}

                    {/*            fullWidth>*/}
                    {/*            Sign Up With Email*/}
                    {/*        </SoftButton>*/}
                    {/*    </SoftBox>*/}

                    {/*</SoftBox>*/}

                    <SoftBox mt={3} textAlign="center">
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                            Already have an account?&nbsp;
                            <SoftTypography
                                component={Link}
                                to="/sign-in"
                                variant="button"
                                color="info"
                                fontWeight="bold"
                                textGradient
                            >
                                Sign in
                            </SoftTypography>
                        </SoftTypography>
                    </SoftBox>
                </>
            )}



        </IllustrationLayout>
    );
}

export default Illustration;
