import {useEffect, useRef, useState} from "react";

// import React
import React from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Container from "@mui/material/Container";
import {PuffLoader} from "react-spinners";
import Card from "@mui/material/Card";
import Form from "./form";
import {useLocation, useNavigate} from 'react-router-dom';
import MetaTableView from "./metaTable";
import SoftTypography from "../../../components/SoftTypography";
import Header from "../../research/Header";
import queryString from "query-string";
import {axiosApi} from "../../../services/api";
import DataTable from "../keywords/DataTable";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;

function ProductRadarTool() {
    const tableRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const [metaResults, setMetaResults] = useState(null);
    const [tabValue, setTabValue] = useState(0);

    const [keywordLikes, setKeywordLikes] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const listingId = location.state?.listingId;

    const [formData, setFormData] = useState({
        listingId: listingId || '',
    });

    const fetchData = async (formDataUpdated = null) => {
        try {
            setLoading(true);
            let body = formData;

            if (formDataUpdated) {
                setFormData(formDataUpdated);
                body = formDataUpdated;
            }

            const product_response = await axiosApi.get(apiUrl + "/api/products/find", {params: {listing_id: body['listingId']}});
            const product_keywords_response = await axiosApi.get(apiUrl + "/api/keywords/product-keywords", {params: {listing_id: body['listingId']}});

            const organicKeywords = product_keywords_response.data.organic;
            const sponsoredKeywords = product_keywords_response.data.sponsored;

            const keywordLikesResponse = await axiosApi.get(apiUrl + "/api/likes/user/keyword");
            const keywordLikesData = keywordLikesResponse.data.map((row) => row.keyword.keyword);
            setKeywordLikes(keywordLikesData);

            //merge organic and sponsored keywords
            //TODO later have separately organic and sponsored keywords

            const mergedKeywords = organicKeywords.concat(sponsoredKeywords);

            // sort mergedKeywords by position
            mergedKeywords.sort((a, b) => (a.totalResults > b.totalResults) ? -1 : 1);

            const product_history = await axiosApi.get(
                apiUrl + "/api/products/monthly",
                {params: {listing_id: body['listingId']}})
                .catch((error) => {
                    return null;
                });

            let defaultLineChartData = null;

            let revenueDiffInPercentage = {'text': null, 'color': null};
            let salesDiffInPercentage = {'text': null, 'color': null};
            let reviewsDiffInPercentage = {'text': null, 'color': null};
            let priceDiffInPercentage = {'text': null, 'color': null};

            if (product_history) {
                const monthly_linechart_labels = []
                const monthly_linechart_data = []
                product_history.data.products.forEach((row) => {
                    monthly_linechart_labels.push(row.month);
                    monthly_linechart_data.push(row.monthlySales * product_response.data.price);
                });

                const firstProduct = product_history.data.products[0];
                const secondProduct = product_history.data.products[1];


                if (firstProduct && secondProduct) {
                    revenueDiffInPercentage = (firstProduct.monthlyRevenue - secondProduct.monthlyRevenue) / secondProduct.monthlyRevenue * 100;
                    salesDiffInPercentage = (firstProduct.monthlySales - secondProduct.monthlySales) / secondProduct.monthlySales * 100;
                    reviewsDiffInPercentage = (firstProduct.monthlyReviews - secondProduct.monthlyReviews) / secondProduct.monthlyReviews * 100;
                    priceDiffInPercentage = (firstProduct.price - secondProduct.price) / secondProduct.price * 100;


                    revenueDiffInPercentage = {'text': revenueDiffInPercentage.toFixed(2) + '%', 'color': revenueDiffInPercentage > 0 ? 'success' : 'error'};
                    salesDiffInPercentage = {'text': salesDiffInPercentage.toFixed(2) + '%', 'color': salesDiffInPercentage > 0 ? 'success' : 'error'};
                    reviewsDiffInPercentage = {'text': reviewsDiffInPercentage.toFixed(2) + '%', 'color': reviewsDiffInPercentage > 0 ? 'success' : 'error'};
                    priceDiffInPercentage = {'text': priceDiffInPercentage.toFixed(2) + '%', 'color': priceDiffInPercentage > 0 ? 'success' : 'error'};

                    // if 0 keep color primary

                    if (revenueDiffInPercentage['text'] === '0.00%') {
                        revenueDiffInPercentage['color'] = 'outline';
                    }

                    if (salesDiffInPercentage['text'] === '0.00%') {
                        salesDiffInPercentage['color'] = 'outline';
                    }

                    if (reviewsDiffInPercentage['text'] === '0.00%') {
                        reviewsDiffInPercentage['color'] = 'outline';
                    }

                    if (priceDiffInPercentage['text'] === '0.00%') {
                        priceDiffInPercentage['color'] = 'outline';
                    }
                }


                // Sort In Reverse Order
                monthly_linechart_labels.reverse();
                monthly_linechart_data.reverse();

                defaultLineChartData = {
                    labels: monthly_linechart_labels,
                    datasets: [
                        {
                            label: "Monthly Revenue",
                            color: "info",
                            data: monthly_linechart_data,
                        },
                    ],
                };
            }



            const metaResult = {
                "product": product_response.data,
                "keywords": {
                    "organic": organicKeywords,
                    "sponsored": sponsoredKeywords,
                    "all": mergedKeywords
                },
                "monthly_linechart": defaultLineChartData,
                "changes": {
                    "revenue": revenueDiffInPercentage,
                    "sales": salesDiffInPercentage,
                    "reviews": reviewsDiffInPercentage,
                    "price": priceDiffInPercentage,
                }
            };

            const rows = [];
            mergedKeywords.forEach((row) => {
                const dataRow = {
                    keyword: row.keyword,
                    totalResults: row.totalResults,
                    position: row.position,
                    monthlySales: row.monthlySales ? row.monthlySales.total : 0,
                    actionItems: {
                        identifier: row.keyword,
                        type: 'keyword',
                        href: '/analytics/keywords?keyword=' + row.keyword,
                    },
                };
                rows.push(dataRow)
            });

            const columns = [
                {Header: 'Keyword', accessor: 'keyword', table: true, Cell: (row) => { return (
                        <div>
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Position', accessor: 'position', table: true, Cell: (row) => { return (
                        <div>
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Monthly Sales', accessor: 'monthlySales', table: true, Cell: (row) => { return (
                        <div>
                            {row.value && row.value.toLocaleString()}
                        </div>
                    ) }
                },
                {Header: 'Total Results', accessor: 'totalResults', table: true, Cell: (row) => { return (
                        <div>
                            {row.value && row.value.toLocaleString()}
                        </div>
                    ) }
                },
                {Header: 'Actions', accessor: 'actionItems', table: true, actions: true},

            ];

            const result = {
                "rows": rows,
                "columns": columns
            };

            setResults(result);
            setMetaResults(metaResult);

        } catch (error) {
            alert(error);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Call the fetch function when the component is mounted
        const queryParams = new URLSearchParams(window.location.search);
        const listingId = queryParams.get('listingId');

        const updatedState = {
            ...formData,
            listingId: listingId
        }

        if (listingId) {
            fetchData(updatedState);
        }
    }, []); // Empty dependency array ensures that the effect runs only once


    const handleFormData = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const stringifiedParams = queryString.stringify(formData);
        const newLocation = `${location.pathname}?${stringifiedParams}`;

        navigate(newLocation);

        fetchData();
    };

    return (
        <DashboardLayout>
            <Header/>
            <Form formHandler={handleFormData} submitHandler={handleSubmit} formData={formData} />

            <SoftBox py={3}>
                <Container>
                    {loading && (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <PuffLoader
                                color={'#17c1e8'}
                                loading={loading}
                                size={60}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />

                        </div>
                    )}

                    <div ref={tableRef}>
                        {!loading && metaResults && (
                            <>
                                <SoftBox mb={3} sx={{marginTop: "30px"}}>
                                    <Card>
                                        <SoftBox p={3} lineHeight={1}>
                                            <MetaTableView
                                                metaResults={metaResults}
                                            />
                                        </SoftBox>

                                    </Card>
                                </SoftBox>

                                <Card>
                                    <SoftBox p={3} lineHeight={1}>
                                        <SoftTypography variant="h5" fontWeight="medium">
                                            Product Keywords
                                        </SoftTypography>
                                        <SoftTypography variant="button" fontWeight="regular" color="text">
                                            Keywords that this product is ranking for.
                                        </SoftTypography>
                                    </SoftBox>
                                    <DataTable table={results} likedItems={keywordLikes} />
                                </Card>
                            </>

                        )}
                    </div>
                </Container>
            </SoftBox>
        </DashboardLayout>
    );
}

export default ProductRadarTool;
