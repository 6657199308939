import {useEffect, useState} from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import PropTypes from "prop-types";
import SoftButton from "../../../components/SoftButton";
import Icon from "@mui/material/Icon";
import waves from "../../../assets/images/shapes/waves-white.svg";


function ProductForm({formHandler, submitHandler, formData}) {
    const [competitionValue, setCompetitionValue] = useState(null);

    const handleSelectChange = (event) => {
        event = {target: {name: "competition", value: event.value}};
        formHandler(event);
    }

    useEffect(() => {
        if (formData.competition) {
            let compLabel = formData.competition? formData.competition.replace('-', ' ') : null;
            compLabel = compLabel.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

            setCompetitionValue({
                label: compLabel,
                value: formData.competition
            });
        }
    }, [formData.competition]);

    return (
        <Card
            id="basic-info"
            sx={{
                backdropFilter: `saturate(200%) blur(30px)`,
                backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                position: "relative",
                mt: -15,
                mx: 3,
                py: 2,
                px: 2,
                overflow: "visible"
            }}
        >

            <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
                <Grid item xs={10} lg={5}>
                    <SoftBox mb={1}>
                        <SoftTypography variant="h3" color="black" fontWeight="bold">
                            Products Research
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={2}>
                        <SoftTypography variant="body2" color="dark" fontWeight="regular">
                            Find products on Etsy that match your criteria
                        </SoftTypography>
                    </SoftBox>
                </Grid>
            </Grid>

            <SoftBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>

                    <Grid item xs={12} flexDirection={'row'}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={12} >
                                <SoftTypography fontSize={'23px'} fontWeight={"bold"} color={'dark'}>Product Title</SoftTypography>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <FormField name="title" placeholder="Search by title..." onChange={formHandler} value={formData.title} />
                                </Grid>
                            </Grid>

                            {/*<Grid item xs={12} sm={6} >*/}
                            {/*    <SoftTypography fontSize={'23px'} fontWeight={"bold"} color={'dark'}>Category</SoftTypography>*/}
                            {/*    <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'} pt={1}>*/}
                            {/*        <SoftSelect>*/}
                            {/*            <option value="" disabled selected>Select Category</option>*/}
                            {/*        </SoftSelect>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}

                        </Grid>
                    </Grid>

                    <Grid item xs={12} flexDirection={'row'}>
                        <Grid container spacing={3}>


                            <Grid item xs={12} sm={3} >
                                <SoftTypography fontSize={'23px'} fontWeight={"bold"} color={'dark'}>Sales</SoftTypography>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Monthly Sales</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <FormField name="monthlySalesFrom" placeholder={'Min'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.monthlySalesFrom} />
                                            <FormField name="monthlySalesTo" placeholder={'Max'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.monthlySalesTo} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Monthly Revenue</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <FormField name="monthlyRevenueFrom" placeholder={'Min'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.monthlyRevenueFrom} />
                                            <FormField name="monthlyRevenueTo" placeholder={'Max'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.monthlyRevenueTo} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid item xs={12} sm={1} >
                            </Grid>


                            <Grid item xs={12} sm={3} >
                                <SoftTypography fontSize={'23px'} fontWeight={"bold"} color={'dark'}>Seller Criteria</SoftTypography>



                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Seller Reviews</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <FormField name="sellerReviewsFrom" placeholder={'Min'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.sellerReviewsFrom} />
                                            <FormField name="sellerReviewsTo" placeholder={'Max'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.sellerReviewsTo} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>


                            </Grid>

                            <Grid item xs={12} sm={1} >
                            </Grid>

                            <Grid item xs={12} sm={3} >
                                <SoftTypography fontSize={'23px'} fontWeight={"bold"} color={'dark'}>Product Criteria</SoftTypography>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Price</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <FormField name="priceFrom" placeholder={'Min'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.priceFrom} />
                                            <FormField name="priceTo" placeholder={'Max'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.priceTo} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} flexDirection={'row'} marginBottom={'30px'}>
                                    <SoftTypography fontSize={'15px'} fontWeight={"bold"} color={'text'}>Monthly Reviews</SoftTypography>
                                    <Grid item xs={12} sm={12}>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <FormField name="monthlyReviewsFrom" placeholder={'Min'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.monthlyReviewsFrom} />
                                            <FormField name="monthlyReviewsTo" placeholder={'Max'}
                                                       inputProps={{ type: "number" }}
                                                       onChange={formHandler}
                                                       value={formData.monthlyReviewsTo} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>

                            </Grid>


                        </Grid>
                    </Grid>

                </Grid>

                <SoftBox mt={3}>
                    <SoftButton
                        variant="gradient"
                        color={'info'}
                        fullWidth
                        // size={'medium'}
                        onClick={submitHandler}
                    >
                        {'Submit'}&nbsp;
                        <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

// Typechecking props for the Header
ProductForm.propTypes = {
    formHandler: PropTypes.func.isRequired,
    submitHandler: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
};

export default ProductForm;
