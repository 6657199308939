import {useEffect, useRef, useState} from "react";
import React from "react";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Container from "@mui/material/Container";
import {PuffLoader} from "react-spinners";
import Card from "@mui/material/Card";
import Form from "./form";
import {useLocation, useNavigate} from 'react-router-dom';
import MetaTableView from "./metaTable";
import SoftTypography from "../../../components/SoftTypography";
import Header from "../../research/Header";
import queryString from "query-string";
import {axiosApi} from "../../../services/api";
import DataTable from "../keywords/DataTable";

const apiUrl = process.env.REACT_APP_API_URL;
const feUrl = process.env.FRONTEND_URL;


function SellerRadarTool() {
    const tableRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const [metaResults, setMetaResults] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [productLikes, setProductLikes] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const name = location.state?.name;

    const [formData, setFormData] = useState({
        name: name || '',
    });

    const fetchData = async (formDataUpdated = null) => {
        try {
            setLoading(true);
            let body = formData;

            if (formDataUpdated) {
                setFormData(formDataUpdated);
                body = formDataUpdated;
            }

            let productRows = [];

            const seller_response = await axiosApi.get(apiUrl + "/api/sellers/find", {params: {name: body['name']}});
            const seller_products_response = await axiosApi.get(apiUrl + "/api/products/seller", {params: {name: body['name']}});

            const productLikesResponse = await axiosApi.get(apiUrl + "/api/likes/user/product");
            const productLikesData = productLikesResponse.data.map((row) => row.product.listingId);
            setProductLikes(productLikesData);

            let sellerMonthlySales = 0;
            let sellerMonthlyRevenue = 0;
            let sellerMonthlyReviews = 0;

            seller_products_response.data.forEach((row) => {
                const dataRowProducts = {
                    sales: row.monthlySales,
                    reviews: row.monthlyReviews,
                    revenue: row.monthlyRevenue,
                    price: row.price,
                    listing_id: row.listingId,
                    title: row.title,
                    image_url: row.imageUrl,
                    actionItems: {
                        identifier: row.listingId,
                        type: 'product',
                        href: '/analytics/products?listingId=' + row.listingId,
                    }
                };

                productRows.push(dataRowProducts);
                sellerMonthlySales += row.monthlySales;
                sellerMonthlyRevenue += row.monthlyRevenue;
                sellerMonthlyReviews += row.monthlyReviews;
            });


            productRows = productRows.sort((a, b) => (a.revenue < b.revenue) ? 1 : -1);

            const productColumns = [
                {Header: 'Image', accessor: 'image_url', table: true, Cell: (row) => { return (
                        <img src={row.value} alt={row.value} width={120} />
                    ) }
                },
                {Header: 'Title', accessor: 'title', table: true, Cell: (row) => { return (
                        <div style={{width: '250px'}}>
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Monthly Sales', width: '120px', accessor: 'sales', table: true, Cell: (row) => { return (
                        <div>
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Monthly Reviews', width: '120px', accessor: 'reviews', table: true, Cell: (row) => { return (
                        <div>
                            {row.value}
                        </div>
                    ) }
                },
                {Header: 'Monthly Revenue', width: '30px', accessor: 'revenue', table: true, Cell: (row) => { return (
                        <div>
                            ${row.value?.toLocaleString()}
                        </div>
                    ) }
                },
                {Header: 'Price', accessor: 'price', width: '30px', table: true, Cell: (row) => { return (
                        <div>
                            ${row.value?.toLocaleString()}
                        </div>
                    ) }
                },

                {Header: 'Actions', accessor: 'actionItems', table: true, actions: true},
            ];

            setResults({
                'rows': productRows,
                'columns': productColumns,
            });

            const sellers_history = await axiosApi.get(
                apiUrl + "/api/sellers/monthly",
                {params: {name: body['name']}})
                .catch((error) => {
                    return null;
                });

            let defaultLineChartData = null;

            if (sellers_history) {
                const monthly_linechart_labels = []
                const monthly_linechart_data = []
                Object.values(sellers_history.data).forEach((row) => {
                    monthly_linechart_labels.push(row.month);
                    monthly_linechart_data.push(row.sales);
                });

                // Sort In Reverse Order
                monthly_linechart_labels.reverse();
                monthly_linechart_data.reverse();

                defaultLineChartData = {
                    labels: monthly_linechart_labels,
                    datasets: [
                        {
                            label: "Monthly Sales",
                            color: "info",
                            data: monthly_linechart_data,
                        },
                    ],
                };
            }

            let categoriesListData = []
            seller_response.data.categories.forEach((category) => {
                categoriesListData.push({
                    'color': 'dark',
                    'icon': 'devices_other',
                    'name': category,
                    'description': (
                        <>
                        </>
                    ),
                    'route': "/",
                });
            });

            const seller_keywords = await axiosApi.get(
                apiUrl + "/api/keywords/seller-keywords",
                {params: {name: body['name']}})
                .catch((error) => {
                    return null;
                });

            let keywordListData = [];

            let totalKeywordCount = 0;
            let totalOrganicKeywords = 0;
            let totalSponsoredKeywords = 0;

            let allKeywords = [];

            if (seller_keywords) {
               seller_keywords.data.keywords.forEach((keyword) => {
                    keywordListData.push({
                        'color': 'dark',
                        'icon': 'devices_other',
                        'name': keyword.keyword,
                        'description': (
                            <>
                                {keyword.totalResults} Total Results,{" "}
                                <SoftTypography variant="caption" color="text" fontWeight="medium">
                                    {keyword.occurance} Seller Products Ranking for this Keyword
                                </SoftTypography>
                            </>
                        ),
                        'route': "#",
                    });

                    allKeywords.push({'keyword': keyword.keyword, "totalResults": keyword.totalResults});

               });

                totalOrganicKeywords = seller_keywords.data.organicKeywordCount;
                totalSponsoredKeywords = seller_keywords.data.sponsoredKeywordCount;
                totalKeywordCount = seller_keywords.data.organicKeywordCount + seller_keywords.data.sponsoredKeywordCount;
            }

            // sort allKeywords by totalResults
            allKeywords.sort((a, b) => (a.totalResults > b.totalResults) ? -1 : 1)

            // get top 10 keywords
            allKeywords = allKeywords.slice(0, 15);

            let keywordTree = []

            allKeywords.forEach((keyword) => {
                keywordTree.push({'x': keyword.keyword, 'y': keyword.totalResults});
            });

            const sellerMetaResults = {
                'monthlySales': sellerMonthlySales,
                'monthlyRevenue': sellerMonthlyRevenue,
                'monthlyReviews': sellerMonthlyReviews,
                'name': seller_response.data.name,
                // 'imageUrl': seller_response.data.imageUrl,
                "totalRevenue": seller_response.data.totalRevenue,
                'totalSales': seller_response.data.totalSales,
                'totalReviews': seller_response.data.allReviews,
                'ratings': seller_response.data.ratings,
                'starSeller': seller_response.data.starSeller,
                'joined': seller_response.data.joinedSince,
                'admirers': seller_response.data.admirers,
                'categories': seller_response.data.categories,
                'headline': seller_response.data.headline,
                'location': seller_response.data.location,
                "monthly_linechart": defaultLineChartData,
                "categoriesListData": categoriesListData,
                "keywordListData": keywordListData,
                "totalKeywordCount": totalKeywordCount,
                "organicKeywordCount": totalOrganicKeywords,
                "sponsoredKeywordCount": totalSponsoredKeywords,
                'keywordTree': keywordTree,
            }

            setMetaResults(sellerMetaResults);

        } catch (error) {
            alert(error);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const name = queryParams.get('name');

        const updatedState = {
            ...formData,
            name: name
        }

        if (name) {
            fetchData(updatedState);
        }
    }, []); // Empty dependency array ensures that the effect runs only once


    const handleFormData = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const stringifiedParams = queryString.stringify(formData);
        const newLocation = `${location.pathname}?${stringifiedParams}`;

        navigate(newLocation);
        fetchData();
    };

    return (
        <DashboardLayout>
            <Header/>

            <Form formHandler={handleFormData} submitHandler={handleSubmit} formData={formData} />

            <SoftBox py={3}>
                <Container>
                    {loading && (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <PuffLoader
                                color={'#17c1e8'}
                                loading={loading}
                                size={60}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />

                        </div>
                    )}

                    <div ref={tableRef}>
                        {!loading && results && metaResults &&(
                            <>
                                <SoftBox mb={3} sx={{marginTop: "30px"}}>
                                    <Card>
                                        <SoftBox p={3} lineHeight={1}>
                                            <MetaTableView
                                                metaResults={metaResults}
                                            />
                                        </SoftBox>
                                    </Card>
                                </SoftBox>

                                <Card>
                                    <SoftBox p={3} lineHeight={1}>
                                        <SoftTypography variant="h5" fontWeight="medium">
                                            Seller Products
                                        </SoftTypography>
                                        <SoftTypography variant="button" fontWeight="regular" color="text">
                                            Products listed by this seller
                                        </SoftTypography>
                                    </SoftBox>
                                    <DataTable table={results} likedItems={productLikes} />
                                </Card>
                            </>
                        )}
                    </div>
                </Container>
            </SoftBox>
        </DashboardLayout>
    );
}

export default SellerRadarTool;
